import React from "react";
import Product from "../Components/Product";
import Token from "../Config/Token";
// import { addToDb } from "../utilities/fakedb";

const Wishlist = ({
  cart,
  setCart,
  wishlistProducts,
  cartShow,
  setCartShow,
}) => {
  const { token } = Token();

  const handleAddToCart = (selectedProduct) => {
    let newCart = [];

    const exists = cart?.find((product) => product?.id === selectedProduct?.id);

    if (!exists) {
      selectedProduct.quantity = 1;
      newCart = [...cart, selectedProduct];
    } else {
      const rest = cart?.filter((product) => product?.id !== selectedProduct?.id);
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, exists];
    }

    setCart(newCart);
    localStorage.setItem('all_cart_data', JSON.stringify(newCart));
    // addToDb(selectedProduct.id);
  };
  
  return (
    <>
      <div className="user-dashboard-main">
        <h4 className="dashboard-main-title">Wishlist</h4>
        <div className="wishlist-products-inner">
          {wishlistProducts.map((product) => (
            <Product
              key={product.id}
              product={product}
              handleAddToCart={handleAddToCart}
              cart={cart}
              setCart={setCart}
              cartShow={cartShow}
              setCartShow={setCartShow}
            ></Product>
          ))}
        </div>
      </div>
    </>
  );
};

export default Wishlist;

import React from "react";
// import { Link } from 'react-router-dom'
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
// import Img1 from '../Assets/img/order-details-img/img-1.svg';
// import Img2 from '../Assets/img/order-details-img/img-2.svg';
// import Img3 from '../Assets/img/order-details-img/img-3.svg';
// import Img4 from '../Assets/img/order-details-img/img-4.svg';
const OrderDetailsItem = ({ OrderDetailsItems }) => {
  //   {
  //     id: "orderdetails1",
  //     img: Img1,
  //     title: "Green Apple",
  //     weight: "1kg",
  //     quantity: "2",
  //     price: "360.00 BDT",
  //   },
  //   {
  //     id: "orderdetails2",
  //     img: Img2,
  //     title: "China Ginger",
  //     weight: "1kg",
  //     quantity: "2",
  //     price: "360.00 BDT",
  //   },
  //   {
  //     id: "orderdetails3",
  //     img: Img3,
  //     title: "Bombai lichu",
  //     weight: "1kg",
  //     quantity: "1",
  //     price: "400.00 BDT",
  //   },
  //   {
  //     id: "orderdetails4",
  //     img: Img4,
  //     title: "Diamond potato",
  //     weight: "1kg",
  //     quantity: "5",
  //     price: "400.00 BDT",
  //   },

  // ];

  return (
    <>
      <div className="order-history-table">
        <Table className="order-history-table-main">
          <thead className="dash-table-head">
            <tr>
              <th>Items</th>
              <th>Weight</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          {OrderDetailsItems?.map((OrderDetailsSingle) => (
            <tbody key={OrderDetailsSingle?.id}>
              <tr>
                <td>
                  <Link to={`/product/details/${OrderDetailsSingle?.product_id}`}>
                    <img
                      src={
                        process.env.React_APP_IMAGES_URL +
                        OrderDetailsSingle?.product_image
                      }
                      alt="#"
                    />
                    {OrderDetailsSingle?.product_name}
                  </Link>
                </td>
                <td>
                  ৳{OrderDetailsSingle?.unit_price}/
                  {OrderDetailsSingle?.unit_name}
                </td>
                <td>{OrderDetailsSingle?.qty}</td>
                <td>৳{OrderDetailsSingle?.total_price}</td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
    </>
  );
};

export default OrderDetailsItem;

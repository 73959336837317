import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import useProducts from "../hooks/useProducts";
// import { addToDb } from "../utilities/fakedb";
import Product from "./Product";

const PopularItems = ({ cart, setCart, cartShow, setCartShow }) => {
  const { products, setProducts, nextPageURL, prevPageURL, getNextProducts } =
    useProducts([]);
  const [mapSliceEnd, setMapSliceEnd] = useState(50);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  // const [categoryProducts, setCategoryProducts] =
  //   useCategory("popular_products");

  const handleAddToCart = (selectedProduct) => {
    let newCart = [];

    const exists = cart?.find((product) => product.id === selectedProduct.id);

    if (!exists) {
      selectedProduct.quantity = 1;
      newCart = [...cart, selectedProduct];
    } else {
      const rest = cart?.filter((product) => product.id !== selectedProduct.id);
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, exists];
    }

    setCart(newCart);
    localStorage.setItem('all_cart_data', JSON.stringify(newCart));
    // addToDb(selectedProduct.id);
  };

  // Load More
  const [noOfElement, setnoOfElement] = useState(20);
  const loadMore = () => {
    setMapSliceEnd(mapSliceEnd + 20);
  };
  // const slice = categoryProducts.slice(0, noOfElement);
  useEffect(() => {
    if (products?.length > 50) {
      setLoadMoreBtn(true);
    } else {
      setLoadMoreBtn(false);
    }
  }, [mapSliceEnd, products]);
  return (
    <>
      {!!products?.length ? (
        <>
          <div className="products-inner">
            {products?.map((product) => (
              <Product
                key={product.id}
                product={product}
                handleAddToCart={handleAddToCart}
                cart={cart}
                setCart={setCart}
                cartShow={cartShow}
                setCartShow={setCartShow}
              ></Product>
            ))}
          </div>
          <div className="row">
            <div className="col-12">
              {nextPageURL !== null && (
                <div className="products-load-more-btn">
                  <button
                    className="theme-btn"
                    onClick={() => getNextProducts()}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <SkeletonTheme baseColor="" highlightColor="">
          {" "}
          <div className="products-inner">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((singleSkelton, index) => (
              <div className="popular-products-wrapper" key={index}>
                <Link to="#">
                  {" "}
                  <div
                    style={{
                      width: "100px",
                      borderRadius: "25px",
                    }}
                  >
                    <Skeleton />
                  </div>
                  <div className="p-products-cont-top">
                    <div className="p-products-cont-top-title">
                      <h6 className="p-products-cont-title">
                        <Skeleton />
                      </h6>
                      <div className="p-products-price ">
                        <del>
                          {" "}
                          <Skeleton />
                        </del>
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                    <ul className="p-products-review-list">
                      <Skeleton />
                    </ul>
                  </div>
                </Link>
                <div className="popular-products-content">
                  <div
                    style={{
                      width: "100px",
                      height: "20px",
                      borderRadius: "25px",
                      textAlign: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Skeleton />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SkeletonTheme>
      )}
    </>
  );
};

export default PopularItems;

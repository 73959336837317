import React, { useEffect, useState } from "react";
import SSL from "../Assets/img/pay-with-options/ssl.png";
import CashOnDelivery from "../Assets/img/payment-history-icon/cash-on-icon.svg";

const PaymentHistroyCard = ({ orderHistory, size, sidebar }) => {
  const [textLength, setTextLength] = useState(60);
  const [toggleTextLength, setToggleTextLength] = useState(80);
  
  useEffect(() => {
    if (size[0] <= 767) {
      setTextLength(40);
      setToggleTextLength(40);
    } else if (768 <= size[0] && size[0] <= 991) {
      setTextLength(25);
      setToggleTextLength(70);
    } else if (992 <= size[0] && size[0] <= 1199) {
      setTextLength(35);
      setToggleTextLength(70);
    } else if (1200 <= size[0] && size[0] <= 1560) {
      setTextLength(60);
      setToggleTextLength(64);
    } else if (1560 <= size[0]) {
      setTextLength(125);
      setToggleTextLength(125);
    }
  }, [size[0]]);
  let orderProducts = [];
  orderHistory?.ordered_items?.map((word) => {
    orderProducts?.push(word?.product_name + "-" + word?.qty);
  });

  let orderProductsText = orderProducts?.join(", ");
  let button;
  if (orderHistory?.order_status_text === "Paid") {
    button = <span className="paid-status">Order {orderHistory?.order_status_text}</span>;
  } else if (orderHistory?.order_status_text === "Pending") {
    button = (
      <span className="pending-status">Order {orderHistory?.order_status_text}</span>
    );
  } else {
    button = <span className="cancel-status">Order {orderHistory?.order_status_text}</span>;
  }
  let img;
  if (orderHistory?.payment_method_text === "COD") {
    img = CashOnDelivery;
  } else if (orderHistory?.PaymentMethod === "CashOnDelivery") {
    img = CashOnDelivery;
  } else{
    img = SSL;
  }

  return (
    <>
      <div className="payment-method-wrapper">
        <div className="payment-method-single">
          <p className="p-method-single-title">Id & status</p>
          <h6 className="p-method-single-text">#{orderHistory?.id}</h6>
          {button}
        </div>
        <div className="payment-method-single">
          <p className="p-method-single-title">Product detais</p>
          <h6 className="p-method-single-text">
            {orderProductsText?.length >
            (sidebar ? textLength : toggleTextLength)
              ? orderProductsText?.substring(
                  0,
                  sidebar ? textLength : toggleTextLength
                ) + "..."
              : orderProductsText}
          </h6>
        </div>
        <div className="payment-method-single">
          <p className="p-method-single-title">Total amount</p>
          <h6 className="p-method-single-text">{orderHistory?.total} BDT</h6>
        </div>
        <div className="payment-method-single">
          <p className="p-method-single-title">Payment method</p>
          <img src={img} alt="#" />
        </div>
      </div>
    </>
  );
};

export default PaymentHistroyCard;

import React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import UserProfileSidebar from "../Components/UserProfileSidebar";
import UserProfileSidebarResponsive from "../Components/UserProfileSidebarResponsive";

const Dashboard = ({personalInfo}) => {
  const location = useLocation();
  let { orderId } = useParams();
  
  return (
    <>
      <div className="container">
        <div className="user-dashboard-area">
          <div className="p-category-top-menu mt-12">
            {orderId === undefined ? (
              <ul className="p-category-top-menu-list m-0">
                <li>
                  <Link to="/">Home</Link>
                  <i className="fi-rr-angle-small-right"></i>
                </li>
                <li className="active">
                  <Link
                    to={location?.pathname}
                    state={{ title: location?.state?.title }}
                  >
                    {location?.state?.title}
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="p-category-top-menu-list m-0">
                <li>
                  <Link to="/">Home</Link>
                  <i className="fi-rr-angle-small-right"></i>
                </li>
                <li>
                  <Link
                    to="/user/purchasehistory"
                    state={{ title: "Puchase history" }}
                  >
                    Puchase history
                  </Link>
                  <i className="fi-rr-angle-small-right"></i>
                </li>
                <li className="active">
                  <Link to={`purchasehistory/${orderId}`}>{orderId}</Link>
                </li>
              </ul>
            )}
          </div>
          <div className="dashboard-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-xl-3 col-12">
                  <UserProfileSidebar personalInfo={personalInfo} />
                </div>
                <div className="col-lg-12 col-xl-9 col-12">
                  <Outlet />

                  <UserProfileSidebarResponsive />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

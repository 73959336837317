import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const SidebarItem = ({
  item,
  activeItem,
  setActiveItem,
  sidebar,
  setSidebar,
  index,
}) => {
  const divRef = useRef(null);
  const [open, setOpen] = useState(false);

  //console.log(item.icon)
  // console.log(<img src={item.icon} className="sidebarIMG" alt="" />)

  // useEffect(() => {
  //     console.log(activeItem)
  //     if(activeItem === item.title){
  //         console.log("Helow sidebar")
  //         setOpen(!open)
  //     }
  //   }, []);

  useEffect(() => {
    if (activeItem === item?.name) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeItem]);

  if (
    item?.children !== undefined &&
    item?.children?.length !== 0 &&
    item?.stage !== 3
  ) {
    return (
      <>
        <div
          className={open ? "sidebar-item open" : "sidebar-item"}
          onClick={() => {
            // console.log("children", item?.name);
            setActiveItem(item?.name);
          }}
        >
          <div
            className={
              activeItem === item?.name ? "sidebar-title open" : "sidebar-title"
            }
          >
            <Link to="#">
              <span onClick={() => setOpen(!open)}>
                {item?.icon && (
                  <img
                    src={process.env.React_APP_IMAGES_URL + item?.icon}
                    className="sidebarIMG"
                    alt=""
                  />
                )}
                {item?.name}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="toggle-btn"
                  onClick={() => setOpen(!open)}
                />
              </span>
            </Link>
          </div>
          <div className="sidebar-content">
            {item?.children !== undefined &&
              item?.children?.map((child, index) => (
                <SidebarItem
                  key={index}
                  item={child}
                  setActiveItem={setActiveItem}
                />
              ))}
          </div>
        </div>
        {/* <Accordion>
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              <Link to="#">
                <span onClick={() => setOpen(!open)}>
                  {item.icon && (
                    <img src={item.icon} className="sidebarIMG" alt="" />
                  )}
                  {item.title}
                 
                </span>
              </Link>
            </Accordion.Header>
            <Accordion.Body>
              {item.childrens.map((child, index) => (
                <SidebarItem key={index} item={child} />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
      </>
    );
  } else {
    return (
      <>
        <Link
          to={item?.slug}
          className="sidebar-item plain g-3"
          onClick={() => {
            // console.log(item?.name);
            // setSelectCategoryStage(item?.stage);
            // setSelectCategoryId(item?.id);
          }}
        >
          {item?.icon && (
            <img
              src={process.env.React_APP_IMAGES_URL + item?.icon}
              className="sidebarIMG"
              alt=""
            />
          )}
          {item?.name}
        </Link>
      </>
    );
  }
};

export default SidebarItem;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import profileImg from "../Assets/img/profile-img.svg";
import ReviewImg2 from "../Assets/img/review-images/img-2.png";
import ReviewIcon from "../Assets/img/review-images/review-icon.svg";
import ModalGallerySlider from "../Components/ModalGallerySlider";
import Token from "../Config/Token";
// import { addToDb } from "../utilities/fakedb";

const ProductSingle = ({ cart, setCart, cartShow, setCartShow, getWishList, wishlistProducts }) => {
    const initialShippingValue = {
        rating: "",
        review: "",
    };
    let navigate = useNavigate();
    const { token } = Token();
    const { productId } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [productDetails, setProductDetails] = useState([]);
    const [wishlistBtn, setWishlistBtn] = useState(false);
    const [activeWishProductId, setActiveWishProductId] = useState(0);
    const [errors, setErrors] = useState(initialShippingValue);
    const [reviews, setReviews] = useState([]);
    const [ratingValue, setRatingValue] = useState(0);
    const [opinionText, setOpinionText] = useState("");
    const [themeBtn, setThemeBtn] = useState(true);

    useEffect(() => {
        handleShowThemeBtn(productDetails?.id);
    }, [productDetails, cart?.length]);
    const handleShowThemeBtn = (id) => {
        const exists = cart?.find((cartProduct) => cartProduct.id === id);
        // if(exists.quantity === 0){
        //   removeFromDb(id)
        // }

        if (exists) {
            setThemeBtn(false);

            const cartQuantity = exists.quantity;
            setQuantity(cartQuantity);
        } else {
            setThemeBtn(true);
        }
    };

    const showThemeBtn = (id) => {
        handleShowThemeBtn(id);
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };

    const incrementToCart = (selectedProduct) => {
        let newCart = [];

        const exists = cart?.find((product) => product?.id === selectedProduct?.id);

        if (exists) {
            const rest = cart?.filter((product) => product?.id !== selectedProduct?.id);
            exists.quantity = exists.quantity + 1;
            newCart = [...rest, exists];
        }

        setCart(newCart);
        localStorage.setItem("all_cart_data", JSON.stringify(newCart));
    };

    const handleAddToCart = (selectedProduct) => {
        let newCart = [];

        const exists = cart?.find((product) => product?.id === selectedProduct?.id);

        if (!exists) {
            selectedProduct.quantity = quantity;
            newCart = [...cart, selectedProduct];
        } else {
            const rest = cart?.filter((product) => product?.id !== selectedProduct?.id);
            exists.quantity = exists.quantity - 1;
            newCart = [...rest, exists];
        }

        setCart(newCart);
        localStorage.setItem("all_cart_data", JSON.stringify(newCart));
        // addToDb(selectedProduct.id);

        // customer data layer when customer click add to cart
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "BDT",
                items: [
                    {
                        item_name: selectedProduct?.name,
                        item_id: selectedProduct?.id,
                        price: selectedProduct?.price,
                        item_brand: selectedProduct?.brand?.name,
                        item_category: selectedProduct?.category?.name,
                        item_variant: selectedProduct?.unit_name,
                        quantity: 1,
                    },
                ],
            },
        });
    };

    const getProductsDetails = async () => {
        axios
            .get(`${process.env.React_APP_API_URL}/product/details/${productId}`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
                },
            })
            .then((res) => setProductDetails(res?.data?.data))
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                // toast.error(errorMessage);
            });
    };

    useEffect(() => {
        getProductsDetails();
    }, [productId]);

    const increment = (incProduct) => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        incrementToCart(incProduct);
    };

    const decrement = (decProduct) => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            incrementToCart(decProduct);
        }
    };

    const handleRating = (rate) => {
        setRatingValue(rate);
    };

    // // Get WishList
    // const getWishList = async () => {
    //   await axios
    //     .get(`${process.env.React_APP_API_URL}/get/my/wishlist`, {
    //       headers: {
    //         "Content-type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     .then((res) => {
    //       const success = res?.data?.success;
    //       const message = res?.data?.message;
    //       const allWishlistData = res?.data?.data?.data;

    //       if (success) {
    //         // toast.success(message);
    //         let wishBtn = false;
    //         allWishlistData?.map((each) => {
    //           if (each.product_id === productId) {
    //             wishBtn = true;
    //           }
    //           setWishlistBtn(wishBtn);
    //         });
    //       } else {
    //         toast.error(message);
    //       }
    //     })
    //     .catch((err) => {
    //       const errorMessage = err?.response?.data?.message;
    //       // toast.error(errorMessage);
    //     });
    // };
    useEffect(() => {
        let wishBtn = false;
        let wishProductId;
        wishlistProducts?.map((each) => {
            if (each.product_id == productId) {
                wishBtn = true;
                wishProductId = each.id;
            }
            setWishlistBtn(wishBtn);
            setActiveWishProductId(wishProductId);
        });
    }, [wishlistProducts.length]);

    // Add WishList

    const addToWishlist = async (wishlistId) => {
        let formData = new FormData();
        formData.append("product_id", wishlistId);
        await axios
            .post(`${process.env.React_APP_API_URL}/add/to/wishlist`, formData, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const success = res?.data?.success;
                const message = res?.data?.message;

                if (success) {
                    getWishList();
                    toast.success(message);
                    setWishlistBtn(true);
                } else {
                    toast.error(message);
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                toast.error(errorMessage);
                if (errorMessage === "Unauthenticated.") {
                    navigate("/login");
                }
            });
    };

    // Delete WishList
    const deleteWishList = async (wishlistId) => {
        let formData = new FormData();
        formData.append("wishlist_id", wishlistId);
        await axios
            .post(`${process.env.React_APP_API_URL}/delete/my/wishlist`, formData, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const success = res?.data?.success;
                const message = res?.data?.message;

                if (success) {
                    getWishList();
                    toast.success(message);
                    setWishlistBtn(false);
                } else {
                    toast.error(message);
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                // toast.error(errorMessage);
            });
    };

    const submit = async (e) => {
        e.preventDefault();

        if (!ratingValue) {
            setErrors({ ...errors, rating: "Rating Value is required" });
        } else if (!opinionText) {
            setErrors({ ...errors, review: "Opinion Text is required" });
        } else {
            let route = "";
            // editID ? (route = "update_address") : (route = "add_address");
            // add_address(route);
            setErrors(initialShippingValue);
            setOpinionText("");
            setRatingValue(0);
            updateOrderInfo();
        }
    };

    const updateOrderInfo = async () => {
        // onSubmit();
        // navigate("/checkout/paymentinfo");
        let formData = new FormData();
        formData.append("product_id", productId);
        formData.append("rating", ratingValue);
        formData.append("review", opinionText);

        await axios
            .post(`${process.env.React_APP_API_URL}/product/review/submit`, formData, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const success = res?.data?.success;
                const message = res?.data?.message;
                if (success) {
                    toast.success(message);
                    getProductsDetails();
                } else {
                    // toast.error(message);
                    toast.error("Request failed");
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                toast.error(errorMessage);
                if (errorMessage === "Unauthenticated.") {
                    navigate("/login");
                }
            });
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: "long", day: "numeric", year: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return formattedDate;
    }

    // customer data layer when customer visit this page
    useEffect(() => {
        if (productDetails?.id) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: "BDT",
                    items: [
                        {
                            item_name: productDetails?.name,
                            item_id: productDetails?.id,
                            price: productDetails?.price,
                            item_brand: productDetails?.brand?.name,
                            item_category: productDetails?.category?.name,
                            item_variant: productDetails?.unit_name,
                            quantity: 1,
                        },
                    ],
                },
            });
        }
    }, [productDetails]);
    return (
        <>
            <div className="container">
                <div className="p-category-top-menu mt-12">
                    <ul className="p-category-top-menu-list m-0">
                        <li>
                            <Link to="/">Home</Link>
                            <i className="fi-rr-angle-small-right"></i>
                        </li>
                        <li className="active">
                            <Link to="/ProductSingle">Product Single</Link>
                        </li>
                    </ul>
                </div>
                <div className="product-signle-main">
                    <div className="p-product-details-modal">
                        <div className="p-details-modal-main">
                            <div className="p-details-modal-main-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="p-details-modal-gallery">
                                            <ModalGallerySlider subImgs={productDetails?.multiple_images} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="p-details-modal-g-details">
                                            <h3 className="p-details-modal-d-title">{productDetails?.name}</h3>
                                            <span className="p-details-modal-d-weight">
                                                <b>Unit:</b> 1{productDetails?.unit_name}
                                            </span>
                                            <span className="p-details-modal-d-weight ms-2">
                                                <b>Stock:</b> {productDetails?.stock} {productDetails?.unit_name}
                                            </span>
                                            <div className="p-details-modal-price">
                                                {productDetails?.discount_price > 0 ? (
                                                    <del>৳ {productDetails?.price} Taka</del>
                                                ) : (
                                                    ""
                                                )}
                                                <span>
                                                    ৳{" "}
                                                    {productDetails?.discount_price > 0
                                                        ? productDetails?.discount_price
                                                        : productDetails?.price}{" "}
                                                    Taka
                                                </span>
                                                <p className="p-details-modal-price-disnt">
                                                    {productDetails?.discount_price > 0 && productDetails?.price > 0
                                                        ? `${Math.round(
                                                              ((productDetails?.price -
                                                                  productDetails?.discount_price) /
                                                                  productDetails?.price) *
                                                                  100
                                                          )}% off`
                                                        : ""}
                                                </p>
                                            </div>

                                            <div className="p-details-modal-buttons">
                                                <div className="p-details-modal-qty-btn">
                                                    <button
                                                        className="qty-count qty-count--minus"
                                                        data-action="minus"
                                                        type="button"
                                                        onClick={() => decrement(productDetails)}
                                                    >
                                                        <i className="fi-rr-minus-small"></i>
                                                    </button>
                                                    <input
                                                        className="product-qty"
                                                        type="number"
                                                        name="product-qty"
                                                        min="0"
                                                        max="10"
                                                        value={quantity}
                                                        disabled
                                                    />
                                                    <button
                                                        className="qty-count qty-count--add"
                                                        data-action="add"
                                                        type="button"
                                                        onClick={() => increment(productDetails)}
                                                    >
                                                        <i className="fi-rr-plus-small"></i>
                                                    </button>
                                                </div>
                                                {productDetails?.stock === 0 ? (
                                                    <div className="product-single-stock-out">
                                                        <span className="product-stock-out-btn">Out of stock</span>
                                                    </div>
                                                ) : themeBtn ? (
                                                    <div
                                                        className="add-cart-btn"
                                                        onClick={() => {
                                                            showThemeBtn(productDetails?.id);
                                                            handleAddToCart(productDetails);
                                                        }}
                                                    >
                                                        <button className="theme-btn">
                                                            <i className="fi-rr-plus"></i>Add to cart
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="add-cart-btn">
                                                        <button className="theme-btn">Added</button>
                                                    </div>
                                                )}
                                                {productDetails?.stock > 0 && (
                                                    <div
                                                        className="buy-cart-btn"
                                                        onClick={() => {
                                                            handleAddToCart(productDetails);
                                                            setCartShow(!cartShow);
                                                        }}
                                                    >
                                                        <button className="theme-btn">
                                                            <i className="fi-rr-shopping-cart"></i>Buy now
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="add-wishlist">
                                                    {wishlistBtn ? (
                                                        <button
                                                            onClick={() => deleteWishList(activeWishProductId)}
                                                            className="theme-btn"
                                                        >
                                                            Remove wishlist
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => addToWishlist(productDetails?.id)}
                                                            className="theme-btn"
                                                        >
                                                            <i className="fi-rr-heart"></i>Add to wishlist
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-details-modal-bottom">
                                <div className="p-details-tab-area">
                                    <div className="container">
                                        <div className="">
                                            <Tabs
                                                defaultActiveKey="description"
                                                id="uncontrolled-tab-example"
                                                className="mb-3 p-details-tab-menu"
                                            >
                                                <Tab eventKey="description" title="Description">
                                                    <p>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: productDetails?.description,
                                                            }}
                                                        ></div>
                                                    </p>
                                                </Tab>
                                                <Tab eventKey="Product Video" title="Product Video">
                                                    <div className="row">
                                                        <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-12">
                                                            <div className="product-details-tab-main-inner">
                                                                <div className="product-video">
                                                                    {productDetails?.video_url === null ? (
                                                                        <div>
                                                                            <h3>No Video</h3>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="product-video-overview">
                                                                            {" "}
                                                                            <div className="product-video-thumb-img">
                                                                                <img
                                                                                    src={
                                                                                        process.env
                                                                                            .React_APP_IMAGES_URL +
                                                                                        productDetails?.image
                                                                                    }
                                                                                    alt="#"
                                                                                />
                                                                            </div>
                                                                            <div className="video-thumb-icon">
                                                                                <a
                                                                                    className="video video-popup mfp-iframe"
                                                                                    href={productDetails?.video_url}
                                                                                    target="blank"
                                                                                >
                                                                                    <i className="fi-sr-play"></i>
                                                                                </a>{" "}
                                                                                <div className="waves-block">
                                                                                    <div className="waves wave-1"></div>
                                                                                    <div className="waves wave-2"></div>
                                                                                    <div className="waves wave-3"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="reviews" title="Reviews">
                                                    <div className="row">
                                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-12">
                                                            <div className="product-details-tab-main-inner">
                                                                <div className="product-review">
                                                                    {" "}
                                                                    <div className="product-review-form">
                                                                        <div className="product-review-form-head display-flex-center">
                                                                            <div className="product-review-form-head-icon">
                                                                                <img src={ReviewIcon} alt="#" />
                                                                            </div>
                                                                            <div className="product-review-form-head-content">
                                                                                <h4 className="product-review-form-head-title">
                                                                                    Rate and review
                                                                                </h4>
                                                                                <p className="product-review-form-head-text m-0">
                                                                                    Feel free to share your opinion
                                                                                    about this product.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <form
                                                                            className="product-review-form-main"
                                                                            onSubmit={submit}
                                                                        >
                                                                            <div className="form-group-single your-rattings ">
                                                                                <label>Your rating</label>

                                                                                <div className="d-flex">
                                                                                    <div>
                                                                                        <Rating
                                                                                            className="your-ratings"
                                                                                            fillIcon={
                                                                                                <i className="fi-ss-star rating-icon"></i>
                                                                                            }
                                                                                            emptyIcon={
                                                                                                <i className="fi fi-rs-star rating-icon"></i>
                                                                                            }
                                                                                            initialValue={ratingValue}
                                                                                            onClick={handleRating}
                                                                                            // showTooltip
                                                                                        />
                                                                                    </div>
                                                                                    <div className="ms-2">
                                                                                        <span>({ratingValue})</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group-single your-opinion ">
                                                                                <label>Your opinion</label>
                                                                                <textarea
                                                                                    value={opinionText}
                                                                                    name="your opinion"
                                                                                    onChange={(e) =>
                                                                                        setOpinionText(e.target.value)
                                                                                    }
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="product-review-form-button">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="theme-btn"
                                                                                >
                                                                                    Submit review
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="user-review-area">
                                                                        {productDetails?.reviews?.map((review) => (
                                                                            <div
                                                                                className="user-review-single"
                                                                                key={review?.id}
                                                                            >
                                                                                <div className="row g-0">
                                                                                    <div className="col-lg-3 col-md-3 col-12">
                                                                                        <div className="user-review-info display-flex-center">
                                                                                            <div className="user-review-info-img">
                                                                                                <img
                                                                                                    src={
                                                                                                        review?.user_image ===
                                                                                                        null
                                                                                                            ? profileImg
                                                                                                            : process
                                                                                                                  .env
                                                                                                                  .React_APP_IMAGES_URL +
                                                                                                              review?.user_image
                                                                                                    }
                                                                                                    alt="#"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="user-review-info-data">
                                                                                                <h6 className="user-review-name">
                                                                                                    {review?.user_name}
                                                                                                </h6>
                                                                                                <p className="user-review-date m-0">
                                                                                                    {formatDate(
                                                                                                        review?.created_at
                                                                                                    )}
                                                                                                </p>
                                                                                                <ul className="user-review-count-list">
                                                                                                    <Rating
                                                                                                        className="your-ratings"
                                                                                                        fillIcon={
                                                                                                            <i className="fi-ss-star rating-icon"></i>
                                                                                                        }
                                                                                                        emptyIcon={
                                                                                                            <i className="fi fi-rs-star rating-icon"></i>
                                                                                                        }
                                                                                                        initialValue={
                                                                                                            review?.rating
                                                                                                        }
                                                                                                        readonly={true}
                                                                                                        // showTooltip
                                                                                                    />
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-9 col-md-9 col-12">
                                                                                        {" "}
                                                                                        <div className="ueser-review-info-text">
                                                                                            <div className="ueser-review-text-icon">
                                                                                                <i className="fi fi-rr-quote-right"></i>
                                                                                            </div>
                                                                                            <p className="ueser-review-text m-0">
                                                                                                {review?.review}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* Single Review Reply */}
                                                                                {review?.reply?.length > 0 &&
                                                                                    review?.reply?.map(
                                                                                        (singleReview) => (
                                                                                            <div
                                                                                                className="row g-0"
                                                                                                key={singleReview?.id}
                                                                                            >
                                                                                                <div className="col-lg-9 offset-lg-3 col-md-9 offset-md-3 col-12">
                                                                                                    <div className="user-review-reply-single">
                                                                                                        <div className="user-review-reply-info display-flex-center">
                                                                                                            <div className="user-review-reply-img">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        ReviewImg2
                                                                                                                    }
                                                                                                                    alt="#"
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="user-review-reply-data">
                                                                                                                <h6 className="user-review-reply-company">
                                                                                                                    sodainagar.com
                                                                                                                </h6>
                                                                                                                <p className="user-review-reply-date ">
                                                                                                                    2
                                                                                                                    weeks
                                                                                                                    ago
                                                                                                                </p>
                                                                                                                <span className="user-review-reply-tag">
                                                                                                                    {" "}
                                                                                                                    <i className="fi fi-sr-reply-all"></i>
                                                                                                                    Replied
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p className="ueser-review-reply-text m-0">
                                                                                                            Thank you so
                                                                                                            much for
                                                                                                            your
                                                                                                            valuable
                                                                                                            feedback. We
                                                                                                            always aim
                                                                                                            to provide
                                                                                                            our
                                                                                                            customers
                                                                                                            with the
                                                                                                            best
                                                                                                            service. We
                                                                                                            look forward
                                                                                                            to seeing
                                                                                                            you!
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {/* <div className="row">
                                    <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-12">
                                      <div className="pagination-area">
                                        <ul className="pagination-list">
                                          <li className="active">
                                            <a href="#">1</a>
                                          </li>
                                          <li>
                                            <a href="#">2</a>
                                          </li>
                                          <li>
                                            <a href="#">3</a>
                                          </li>
                                          <li>
                                            <a href="#">4</a>
                                          </li>{" "}
                                          <li>
                                            <a href="#">5</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ProductSliderMagnific /> */}
            </div>
        </>
    );
};

export default ProductSingle;

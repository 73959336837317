
const MultipleQty = ({increment, decrement, quantity, cart}) => {
  // const [quantity, setQuantity] = useState(0);
  // const increment = () => {
  //   const newQuantity = quantity + 1;
  //   setQuantity(newQuantity);
  // };
  // const decrement = () => {
  //   if (quantity > 0) {
  //     const newQuantity = quantity - 1;
  //     setQuantity(newQuantity);
  //   }
  // };

  return (
    <>
      <div className="p-details-modal-qty-btn">
        <button
          className="qty-count qty-count--minus"
          data-action="minus"
          type="button"
          onClick={()=>decrement(cart)}
        >
          <i className="fi-rr-minus-small"></i>
        </button>
        <input
          className="product-qty"
          type="number"
          name="product-qty"
          min="0"
          max="10"
          value={quantity}
          disabled
        />
        <button
          className="qty-count qty-count--add"
          data-action="add"
          type="button"
          onClick={()=>increment(cart)}
        >
          <i className="fi-rr-plus-small"></i>
        </button>
      </div>
    </>
  );
};

export default MultipleQty;
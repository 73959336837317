import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LockIcon from "../Assets/img/login-with-icon/lock2-icon.svg";
import UserContext from "../Context/UserContext";

const SetNewPassword = () => {
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  let navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPwdType, setConfirmPwdType] = useState("password");
  const emailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email"),
    OTP: Yup.string().required("OTP is mendatory"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at 8 char long"),

    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const phoneFormSchema = Yup.object().shape({
    phone: Yup.string(),
    OTP: Yup.string().required("OTP is mendatory"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at 8 char long"),

    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formOptions = {
    resolver: yupResolver(
      signUpInfo?.type !== "phone" ? emailFormSchema : phoneFormSchema
    ),
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);
  const setNewPasswordForEmail = async (data) => {
    let formData = new FormData();
    formData.append("email", signUpInfo?.email);
    formData.append("code", data?.OTP);
    formData.append("password", data?.password);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/forget/password/email/submit`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/loginwithemail");
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage);
      });
  };
  const setNewPasswordForPhone = async (data) => {
    let formData = new FormData();
    formData.append("phone", signUpInfo?.phone);
    formData.append("code", data?.OTP);
    formData.append("password", data?.password);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/forget/password/sms/submit`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/login");
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage);
      });
  };
  const onSubmit = (data) => {
    if (signUpInfo?.type !== "phone") {
      setNewPasswordForEmail(data);
    } else {
      setNewPasswordForPhone(data);
    }
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPwd = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
      return;
    }
    setConfirmPwdType("password");
  };

  return (
    <>
      <div className="reset-password-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="reset-password-card">
                <div className="reset-password-top">
                  <img src={LockIcon} alt="#" />
                  <h4 className="reset-password-top-title">
                    Set a New Password
                  </h4>
                  <p className="reset-password-top-text">
                    Please, set a new password to login your account.
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="login-form-email"
                >
                  {signUpInfo?.type !== "phone" ? (
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Email address</p>
                      <div className="pass-input-main">
                        <input
                          type="email"
                          value={signUpInfo?.email}
                          placeholder="Enter Email address"
                          className="input input-bordered w-full max-w-xs"
                          disabled={true}
                          {...register("email")}
                        />
                      </div>

                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors?.email?.message}
                        </span>
                      </label>
                    </div>
                  ) : (
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Phone Number</p>
                      <div className="pass-input-main">
                        <input
                          type="text"
                          value={signUpInfo?.phone}
                          placeholder="Enter Phone Number"
                          className="input input-bordered w-full max-w-xs"
                          disabled={true}
                          {...register("phone")}
                        />
                      </div>

                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors?.phone?.message}
                        </span>
                      </label>
                    </div>
                  )}
                  <div className="form-control w-full max-w-xs">
                    <p className="form-title-label">OTP</p>
                    <div className="pass-input-main">
                      <input
                        type="text"
                        placeholder="OTP"
                        className="input input-bordered w-full max-w-xs"
                        {...register("OTP")}
                      />
                    </div>

                    <label className="label">
                      <span className="label-text-alt text-red-500">
                        {errors?.OTP?.message}
                      </span>
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs">
                    <p className="form-title-label">Set password</p>
                    <div className="pass-input-main">
                      <input
                        type={passwordType}
                        placeholder="Password"
                        className="input input-bordered w-full max-w-xs"
                        {...register("password")}
                      />
                      {passwordType === "password" ? (
                        <i
                          className="fi-sr-eye-crossed"
                          onClick={togglePassword}
                        ></i>
                      ) : (
                        <i className="fi-sr-eye" onClick={togglePassword}></i>
                      )}
                    </div>

                    <label className="label">
                      <span className="label-text-alt text-red-500">
                        {errors?.password?.message}
                      </span>
                    </label>
                  </div>
                  <div className="form-control w-full max-w-xs">
                    <p className="form-title-label">Confirm password</p>
                    <div className="pass-input-main">
                      <input
                        type={confirmPwdType}
                        placeholder="Password"
                        className="input input-bordered w-full max-w-xs"
                        {...register("confirmPwd")}
                      />
                      {confirmPwdType === "password" ? (
                        <i
                          className="fi-sr-eye-crossed"
                          onClick={toggleConfirmPwd}
                        ></i>
                      ) : (
                        <i className="fi-sr-eye" onClick={toggleConfirmPwd}></i>
                      )}
                    </div>

                    <label className="label">
                      <span className="label-text-alt text-red-500">
                        {errors?.confirmPwd?.message}
                      </span>
                    </label>
                  </div>
                  <input
                    className="btn btn-accent w-full max-w-xs text-white m-0"
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetNewPassword;

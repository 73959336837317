import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Token = () => {
  let navigate = useNavigate();

  const [token, setToken] = useState("");
  const [user, setCurrentUser] = useState({});
  
  //get token string
  function getToken() {
    let user = localStorage.getItem("sodai_current_user") || null;
    user = JSON.parse(user);
    const access_token = user?.token;

    return access_token;
  }
  //get token string
  function getUserFromLocal() {
    let user = localStorage.getItem("sodai_current_user") || null;
    user = JSON.parse(user);
    return user;
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };
  //get user string
  function setUser(user) {
    return localStorage.setItem("sodai_current_user", JSON.stringify(user));
  }
  function clearUser() {
    localStorage?.removeItem("sodai_current_user");
    localStorage?.removeItem("sodai_current_user_cartlist");
    localStorage?.removeItem("sodai_current_user_wishlist");
    navigate("/")
    window?.location?.reload();
  }
  useEffect(() => {
    setToken(getToken());
    setCurrentUser(getUserFromLocal());
  }, []);
  return {token, config, user, setUser, setCurrentUser, clearUser };
};

export default Token;

import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import CheckoutShoppingTab from "../Components/CheckoutShoppingTab";

const CheckOut = () => {
  const location = useLocation();
  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link
                to="/checkout/shoppinginfo"
                onClick={(event) => event.preventDefault()}
              >
                Checkout
              </Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link
                to={location?.pathname}
                state={{
                  checkoutTitle: location?.state?.checkoutTitle,
                }}
                onClick={(event) => event.preventDefault()}
              >
                {location?.state?.checkoutTitle
                  ? location?.state?.checkoutTitle
                  : "Shipping info"}
              </Link>
            </li>
          </ul>
        </div>
        <div className="checkout-page-area" style={{ overflow: "hidden" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <CheckoutShoppingTab />
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default CheckOut;

import React from 'react'

const DashboardProductItems = ({totalOrderProducts,cart,wishlistProducts}) => {
  return (
    <>
      <div className='dashboard-product-items'>
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-12'>
            <div className='dashboard-product-wrapper bg-color-1'>
              <h4 className='dashboard-product-title'>{cart?.length} Products</h4>
              <p className='dashboard-product-text'>in your cart</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-12'>
            <div className='dashboard-product-wrapper bg-color-2'>
              <h4 className='dashboard-product-title'>{wishlistProducts?.length} Products</h4>
              <p className='dashboard-product-text'>in your wishlist</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-12'>
            <div className='dashboard-product-wrapper bg-color-3'>
              <h4 className='dashboard-product-title'>{totalOrderProducts} Products</h4>
              <p className='dashboard-product-text'>you orderd</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardProductItems
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Mastercard from "../Assets/img/payment-currency/mastercard.svg";
import Visa from "../Assets/img/payment-currency/visa.svg";
import AddNewAddressModal from "../Components/AddNewAddressModal";
import AddNewCardInfo from "../Components/AddNewCardInfo";
import ImageUploader from "../Components/ImageUploader";
import Token from "../Config/Token";

const ManageProfile = ({ personalInfo }) => {
  let navigate = useNavigate();
  const { user, token, clearUser } = Token();
  const initalState = {
    name: "",
    email: "",
    phone: "",
  };
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [editID, setEditID] = useState(null);
  const [profileUpdateInfo, setProfileUpdateInfo] = useState(initalState);

  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPwdType, setConfirmPwdType] = useState("password");
  const [allAddress, setAllAddress] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [addressModalShow, setAddressModalShow] = useState(false);
  const [cardModalShow, setCardModalShow] = useState(false);
  useEffect(() => {
    const userInfo = {
      name: personalInfo?.name,
      email: personalInfo?.email,
      phone: personalInfo?.phone,
    };
    setProfileUpdateInfo(userInfo);
  }, [personalInfo]);
  const formSchemaChangePassword = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Old Password is mendatory")
      .min(8, "Old Password must be at 8 char long"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at 8 char long"),
    confirmPwd: Yup.string()
      .required("Confirm Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formOptions = {
    resolver: yupResolver(formSchemaChangePassword),
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);
  const toggleOldPassword = () => {
    if (oldPasswordType === "password") {
      setOldPasswordType("text");
      return;
    }
    setOldPasswordType("password");
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPwd = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
      return;
    }
    setConfirmPwdType("password");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setAddressModalShow(true);
  const updateProfile = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("name", profileUpdateInfo?.name);
    formData.append("phone", profileUpdateInfo?.phone);
    formData.append("email", profileUpdateInfo?.email);
    formData.append("image", selectedImage);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/user/profile/update/web`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;

        if (success) {
          toast.success(message);
          window.location.reload();
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const changePassword = async (data) => {
    let formData = new FormData();
    formData.append("current_password", data?.oldPassword);
    formData.append("new_password", data?.password);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/user/change/password/web`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;

        if (success) {
          toast.success(message);
          clearUser();
          window.location.reload();
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const getAddress = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/all/address`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setAllAddress(res?.data?.date))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const getCards = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/my/cards`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setAllCards(res?.data?.date))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  useEffect(() => {
    token && getAddress();
    token && getCards();
  }, [token]);

  const deleteAddress = async (addressId) => {
    let formData = new FormData();
    formData.append("address_id", addressId);
    await axios
      .post(`${process.env.React_APP_API_URL}/delete/my/address`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        if (success) {
          toast.success(message);
          getAddress();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const deleteCard = async (cardId) => {
    let formData = new FormData();
    formData.append("card_id", cardId);
    await axios
      .post(`${process.env.React_APP_API_URL}/delete/my/card`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        if (success) {
          toast.success(message);
          getCards();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  // console.log(personalInfo, profileUpdateInfo, selectedImage);
  return (
    <>
      <div className="container">
        <div className="user-dashboard-main">
          <h4 className="dashboard-main-title">Manage profile</h4>
          <div className="manage-profile-main">
            <div className="row">
              <div className="col-lg-12 col-xl-5 col-12">
                <div className="manage-profile-single">
                  <h6 className="manage-profile-title">Personal info</h6>
                  <ImageUploader
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                  <div className="manage-profile-form">
                    <form onSubmit={updateProfile}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Full name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter full name"
                              value={profileUpdateInfo?.name}
                              onChange={(e) => {
                                setProfileUpdateInfo({
                                  ...profileUpdateInfo,
                                  name: e.target.value,
                                });
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Mobile number</label>
                            <input
                              type="text"
                              name="number"
                              placeholder="Enter mobile name"
                              value={profileUpdateInfo?.phone}
                              onChange={(e) => {
                                setProfileUpdateInfo({
                                  ...profileUpdateInfo,
                                  phone: e.target.value,
                                });
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Email address</label>
                            <input
                              type="email"
                              name="email"
                              placeholder="Enter Email"
                              value={profileUpdateInfo?.email}
                              onChange={(e) => {
                                setProfileUpdateInfo({
                                  ...profileUpdateInfo,
                                  email: e.target.value,
                                });
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="manage-profile-button">
                            <button className="theme-btn" type="submit">
                              Update profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-5 offset-xl-2 col-12">
                <div className="manage-profile-single">
                  <h6 className="manage-profile-title">Change password</h6>
                  <div className="manage-profile-form">
                    <form
                      onSubmit={handleSubmit(changePassword)}
                      className="login-form-email"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Old password</label>
                            <div className="pass-input-main">
                              <input
                                type={oldPasswordType}
                                placeholder="Old password"
                                className="input input-bordered w-full max-w-xs"
                                name="password"
                                {...register("oldPassword")}
                              />
                              {oldPasswordType === "password" ? (
                                <i
                                  className="fi-sr-eye-crossed"
                                  onClick={toggleOldPassword}
                                ></i>
                              ) : (
                                <i
                                  className="fi-sr-eye"
                                  onClick={toggleOldPassword}
                                ></i>
                              )}
                            </div>
                            {errors?.oldPassword?.message && (
                              <span className="label-text-alt text-red-500 ms-3">
                                {errors?.oldPassword?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>New password</label>
                            <div className="pass-input-main">
                              <input
                                type={passwordType}
                                placeholder="Password"
                                className="input input-bordered w-full max-w-xs"
                                name="password"
                                {...register("password")}
                              />
                              {passwordType === "password" ? (
                                <i
                                  className="fi-sr-eye-crossed"
                                  onClick={togglePassword}
                                ></i>
                              ) : (
                                <i
                                  className="fi-sr-eye"
                                  onClick={togglePassword}
                                ></i>
                              )}
                            </div>
                            {errors?.password?.message && (
                              <span className="label-text-alt text-red-500 ms-3">
                                {errors?.password?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Confirm password</label>
                            <div className="pass-input-main">
                              <input
                                type={confirmPwdType}
                                placeholder="Confirm password"
                                className="input input-bordered w-full max-w-xs"
                                name="password"
                                {...register("confirmPwd")}
                              />
                              {confirmPwdType === "password" ? (
                                <i
                                  className="fi-sr-eye-crossed"
                                  onClick={toggleConfirmPwd}
                                ></i>
                              ) : (
                                <i
                                  className="fi-sr-eye"
                                  onClick={toggleConfirmPwd}
                                ></i>
                              )}
                            </div>
                            {errors?.confirmPwd?.message && (
                              <span className="label-text-alt text-red-500 ms-3">
                                {errors?.confirmPwd?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="manage-profile-button">
                            <button className="theme-btn" type="submit">
                              Update password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="manage-profile-address">
              <div className="row">
                <div className="col-lg-12 col-xl-5 col-12">
                  <h6 className="manage-profile-title">Address</h6>
                  <div className="m-profile-address-inner">
                    {allAddress?.map((singleAddress) => (
                      <div
                        className="m-profile-address-single"
                        key={singleAddress?.id}
                      >
                        <div className="m-profile-address-single-top">
                          <p>{singleAddress?.address_type}</p>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <i className="fi-rr-menu-dots"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setEditID(singleAddress?.id);
                                  handleShow();
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => deleteAddress(singleAddress?.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <ul className="m-profile-address-list">
                          <li>
                            <b>Name:</b> {singleAddress?.name}
                          </li>
                          <li>
                            <b>Address:</b> {singleAddress?.address}
                          </li>
                          <li>
                            <b>Postal Code:</b> {singleAddress?.post_code}
                          </li>
                          <li>
                            <b>City:</b> {singleAddress?.city}
                          </li>
                          <li>
                            <b> Country:</b> {singleAddress?.country}
                          </li>
                          <li>
                            <b> Phone:</b> {singleAddress?.phone}
                          </li>
                        </ul>
                      </div>
                    ))}
                    <AddNewAddressModal
                      getAddress={getAddress}
                      editID={editID}
                      setEditID={setEditID}
                      Address={allAddress}
                      addressModalShow={addressModalShow}
                      setAddressModalShow={setAddressModalShow}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-5 offset-xl-2 col-12">
                  <h6 className="manage-profile-title">Saved Card</h6>
                  <div className="card-info-widget">
                    {allCards?.map((singleCard) => (
                      <div
                        className="card-info-widget-inner"
                        key={singleCard?.id}
                      >
                        <div className="card-info-main">
                          <div className="card-info-img">
                            <img
                              src={
                                singleCard?.type === "Visa" ? Visa : Mastercard
                              }
                              alt="#"
                            />
                          </div>
                          <div className="card-info-main-content">
                            <span className="card-info-number">
                              {singleCard?.card_no.slice(0, 4)}******
                              {singleCard?.card_no.slice(
                                singleCard?.card_no?.length - 4,
                                singleCard?.card_no?.length
                              )}
                              <b>
                                {singleCard?.default === "1" ? "(default)" : ""}
                              </b>
                            </span>
                            <p className="card-info-expired">
                              Expired:<b>{singleCard?.expiry_date}</b>
                            </p>
                          </div>
                        </div>
                        <div className="card-info-edit">
                          <button
                            onClick={() => {
                              setEditID(singleCard?.id);
                              setCardModalShow(true);
                            }}
                            className="card-info-edit-btn btn btn-link "
                          >
                            Edit
                          </button>
                          <button
                            className="card-info-delete-btn btn btn-link"
                            onClick={() => deleteCard(singleCard?.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="card-info-bottom">
                      <AddNewCardInfo
                        getCards={getCards}
                        editID={editID}
                        setEditID={setEditID}
                        Cards={allCards}
                        setCardModalShow={setCardModalShow}
                        cardModalShow={cardModalShow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProfile;

import { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../Context/UserContext";
// import Categories1 from "../Assets/img/shop-categories/item-1.jpg";
import Categories2 from "../Assets/img/shop-categories/item-2.jpg";
// import Categories3 from "../Assets/img/shop-categories/item-3.jpg";
// import Categories4 from "../Assets/img/shop-categories/item-4.jpg";
// import Categories5 from "../Assets/img/shop-categories/item-5.jpg";
// import Categories6 from "../Assets/img/shop-categories/item-6.jpg";
const Categories = () => {
  const { sideBarData } = useContext(UserContext);
  return (
    <>
      <div className="s-categories-main-inner">
        {sideBarData?.map(
          (categorieItem) =>
            categorieItem?.featured === 1 && (
              <div key={categorieItem?.id}>
                <Link
                  to={`/${categorieItem?.slug}`}
                  className="s-categories-wrapper"
                >
                  <div className="s-categories-wrapper-img">
                    <img
                      src={
                        categorieItem?.banner_image === null
                          ? Categories2
                          : process.env.React_APP_IMAGES_URL +
                            categorieItem?.banner_image
                      }
                      alt="#"
                    />
                    <div className="s-categories-btn">
                      <div href="#" className="theme-btn">
                        {categorieItem?.name}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default Categories;

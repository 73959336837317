import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Token from "../Config/Token";
import UserContext from "../Context/UserContext";

const UpdateInformation = ({ cart }) => {
  let navigate = useNavigate();
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  const { setUser } = Token();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPwdType, setConfirmPwdType] = useState("password");

  const formSchemaWithEmail = Yup.object().shape({
    fullName: Yup.string().max(20).required("Full Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    address: Yup.string().max(30).required("Address is required"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(6, "Password must be at 6 char long"),
    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formSchemaWithPhone = Yup.object().shape({
    fullName: Yup.string().max(20).required("Full Name is required"),
    address: Yup.string().max(30).required("Address is required"),
    phone: Yup.string().max(30).required("Phone is required"),
    password: Yup.string()
      .required("Password is mendatory")
      .min(8, "Password must be at 8 char long"),
    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formOptions = {
    resolver: yupResolver(
      signUpInfo?.type === "email" ? formSchemaWithPhone : formSchemaWithEmail
    ),
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("name", data?.fullName);
    formData.append(
      "email",
      signUpInfo?.type === "email" ? signUpInfo?.email : data?.email
    );
    formData.append("address", data?.address);
    formData.append(
      "phone",
      signUpInfo?.type === "phone" ? signUpInfo?.phone : "+88" + data?.phone
    );
    formData.append("password", data?.password);
    // console.log("data");
    await axios
      .post(`${process.env.React_APP_API_URL}/user/register`, formData, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const user = res?.data?.data;
        if (success) {
          setUser(user);
          navigate("/");
          window.location.reload();
          toast.success(message);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPwd = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
      return;
    }
    setConfirmPwdType("password");
  };

  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
        </div>
        <div className="reset-password-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="reset-password-card">
                  <div
                    className="reset-password-top"
                    style={{ marginBottom: "15px" }}
                  >
                    <h4 className="reset-password-top-title">Update Info</h4>
                    <p className="reset-password-top-text">
                      Please update your profile information
                    </p>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="login-form-email"
                  >
                    <div
                      className="form-control w-full max-w-xs"
                      style={{ marginBottom: "5px" }}
                    >
                      <p className="form-title-label">Full name</p>
                      <input
                        type="name"
                        {...register("fullName")}
                        placeholder="Enter full name"
                        className="input input-bordered w-full max-w-xs"
                      />
                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.fullName?.message}
                        </span>
                      </label>
                    </div>
                    {signUpInfo?.email ? (
                      <div
                        className="form-control w-full max-w-xs"
                        style={{ marginBottom: "5px" }}
                      >
                        <p className="form-title-label">Phone Number</p>
                        <input
                          type="text"
                          placeholder="Enter Phone Number"
                          className="input input-bordered w-full max-w-xs"
                          {...register("phone")}
                        />
                        <label className="label">
                          <span className="label-text-alt text-red-500">
                            {errors.phone?.message}
                          </span>
                        </label>
                      </div>
                    ) : (
                      <div
                        className="form-control w-full max-w-xs"
                        style={{ marginBottom: "5px" }}
                      >
                        <p className="form-title-label">Email/Email ID</p>
                        <input
                          type="email"
                          placeholder="Enter email"
                          className="input input-bordered w-full max-w-xs"
                          {...register("email")}
                        />
                        <label className="label">
                          <span className="label-text-alt text-red-500">
                            {errors.email?.message}
                          </span>
                        </label>
                      </div>
                    )}
                    <div
                      className="form-control w-full max-w-xs"
                      style={{ marginBottom: "5px" }}
                    >
                      <p className="form-title-label">Set location</p>
                      <div className="pass-input-main">
                        <input
                          type="name"
                          placeholder="Address here"
                          {...register("address")}
                          className="input input-bordered w-full max-w-xs"
                        />
                        <i className="fi-sr-marker"></i>
                      </div>
                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.address?.message}
                        </span>
                      </label>
                    </div>
                    <div
                      className="form-control w-full max-w-xs"
                      style={{ lineHeight: "0" }}
                    >
                      <p className="form-title-label">Set password</p>
                      <div className="pass-input-main">
                        <input
                          type={passwordType}
                          placeholder="Password"
                          className="input input-bordered w-full max-w-xs"
                          {...register("password")}
                        />
                        {passwordType === "password" ? (
                          <i
                            className="fi-sr-eye-crossed"
                            onClick={togglePassword}
                          ></i>
                        ) : (
                          <i className="fi-sr-eye" onClick={togglePassword}></i>
                        )}
                      </div>

                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.password?.message}
                        </span>
                      </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Confirm password</p>
                      <div className="pass-input-main">
                        <input
                          type={confirmPwdType}
                          placeholder="Password"
                          className="input input-bordered w-full max-w-xs"
                          {...register("confirmPwd")}
                        />
                        {confirmPwdType === "password" ? (
                          <i
                            className="fi-sr-eye-crossed"
                            onClick={toggleConfirmPwd}
                          ></i>
                        ) : (
                          <i
                            className="fi-sr-eye"
                            onClick={toggleConfirmPwd}
                          ></i>
                        )}
                      </div>

                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.confirmPwd?.message}
                        </span>
                      </label>
                    </div>
                    <input
                      className="btn btn-accent w-full max-w-xs text-white m-0"
                      style={{ width: "118px" }}
                      type="submit"
                      value="Save info"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateInformation;

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import UserContext from "../Context/UserContext";

const SignUpWithEmail = () => {
  let navigate = useNavigate();
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")

      .required("Email is required"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);
  const applyEmail = (e) => {
    const randomCode = 100000 + Math.floor(Math.random() * 900000);
    setSingUpInfo({
      ...signUpInfo,
      type: "email",
      email: e.target.value,
      code: randomCode,
    });
  };
  const onSubmit = async (data) => {
    // console.log(emailInfo);
    let formData = new FormData();
    formData.append("email", data?.email);
    formData.append("code", signUpInfo?.code);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/send/verification/email`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/emailverification");
        }else{
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        // toast.error(errorMessage);
      });

    // navigate("/emailverification");
  };

  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
        </div>
        <div className="reset-password-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="reset-password-card">
                  <div className="reset-password-top border-add">
                    <h4 className="reset-password-top-title">Sign up</h4>
                    <p className="reset-password-top-text">
                      Please sign up to access your account.
                    </p>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="login-form-email"
                  >
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Email or id</p>
                      <input
                        type="email"
                        placeholder="Enter email"
                        className="input input-bordered w-full max-w-xs"
                        {...register("email")}
                        onChange={applyEmail}
                      />
                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.email?.message}
                        </span>
                      </label>
                    </div>

                    <input
                      className="btn btn-accent w-full max-w-xs text-white m-0"
                      type="submit"
                      value="Next"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpWithEmail;

import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

const ProductCategoryTop = ({ selectCategory, categoryLength }) => {
  // const words = categoryName.split("_");
  // let categoryText = words
  //   .map((word) => {
  //     return word[0].toUpperCase() + word.substring(1);
  //   })
  //   .join(" ");

  return (
    <>
      <div>
        <div className="product-category-top">
          <div className="p-category-top-menu">
            <ul className="p-category-top-menu-list">
              <li>
                <Link to="/">Home</Link>
                <i className="fi-rr-angle-small-right"></i>
              </li>
              {selectCategory?.category_name !== undefined && (
                <li>
                  <Link to={`/` + selectCategory?.category_slug}>
                    {selectCategory?.category_name}
                  </Link>
                  <i className="fi-rr-angle-small-right"></i>
                </li>
              )}
              {selectCategory?.subcategory_name !== undefined && (
                <li>
                  <Link to={`/` + selectCategory?.subcategory_slug}>
                    {selectCategory?.subcategory_name}
                  </Link>
                  <i className="fi-rr-angle-small-right"></i>
                </li>
              )}

              <li>
                <span>{selectCategory?.name}</span>
              </li>
            </ul>
            <h1 className="p-item-found-title">{selectCategory?.name}</h1>
            <p className="p-item-found-text">({categoryLength} items found)</p>
          </div>
          <div className="product-category-select">
            <label>Sort by:</label>
            <Form.Select aria-label="Default select example">
              <option>Acceding</option>
              <option value="1">Descending</option>
              <option value="2">New products</option>
              <option value="3">Price high to low</option>
              <option value="4">Price low to high</option>
            </Form.Select>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCategoryTop;

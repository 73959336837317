import axios from "axios";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
// import ClientImg1 from "../Assets/img/testimonial-img/img-1.jpg";
// import ClientImg2 from "../Assets/img/testimonial-img/img-2.jpg";
// import ClientImg3 from "../Assets/img/testimonial-img/img-3.jpg";
// import ClientImg4 from "../Assets/img/testimonial-img/img-4.jpg";

const TestimonialSlider = () => {
    const [clientReviews, setClientReviews] = useState([]);
    const getClientReviews = async () => {
        axios
            .get(`${process.env.React_APP_API_URL}/get/all/testimonials`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
                },
            })
            .then((res) => {
                const success = res?.data?.success;
                const data = res?.data?.data;
                if (success) {
                    setClientReviews(data);
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                // toast.error(errorMessage);
            });
    };

    useEffect(() => {
        getClientReviews();
    }, []);

    return (
        <>
            <div className="testimonial-slider-inner">
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation={true}
                    spaceBetween={30}
                    slidesPerView={4}
                    //   onSlideChange={() => console.log("slide change")}
                    //   onSwiper={(swiper) => console.log(swiper)}
                    loop={clientReviews?.length > 3 ? true : false}
                    autoplay={true}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            width: 320,
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                        // when window width is >= 1440px
                        1440: {
                            width: 1440,
                            slidesPerView: 4,
                        },
                    }}
                >
                    {clientReviews.map((SingleSlider) => (
                        <SwiperSlide key={SingleSlider?.id}>
                            <div className="testimonial-single">
                                <div className="testimonial-single-top">
                                    <i className="fi fi-sr-quote-right"></i>
                                    <p className="testimonial-content-text">{SingleSlider?.description}</p>
                                    <ul className="testimonial-rattings-list">
                                        <Rating
                                            className="your-ratings"
                                            fillIcon={<i className="fi-ss-star rating-icon"></i>}
                                            emptyIcon={<i className="fi fi-rs-star rating-icon"></i>}
                                            initialValue={SingleSlider?.rating}
                                            readonly={true}
                                            // showTooltip
                                        />
                                    </ul>
                                </div>
                                <div className="testimonial-single-bottom">
                                    <div className="testimonial-img">
                                        <img
                                            src={process.env.React_APP_IMAGES_URL + SingleSlider?.customer_image}
                                            alt="#"
                                        />
                                    </div>
                                    <div className="testimonial-content-info">
                                        <h5 className="testimonial-info-name">{SingleSlider?.customer_name}</h5>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
};

export default TestimonialSlider;

import React from "react";
import { NavLink } from "react-router-dom";
import Icon1 from "../Assets/img/checkout-shopping-tab-icon/icon-1.svg";
import Icon2 from "../Assets/img/checkout-shopping-tab-icon/icon-2.svg";
import Icon3 from "../Assets/img/checkout-shopping-tab-icon/icon-3.svg";

const CheckoutShoppingTab = () => {
  return (
    <>
      <div className="checkout-shopping-tab">
        <NavLink
          to="/checkout/shoppinginfo"
          state={{
            checkoutTitle: "Shipping info",
            checkoutPath: "/checkout/shoppinginfo",
          }}
          className="checkout-s-tab-wrapper"
          onClick={event => event.preventDefault()}
        >
          <div className="checkout-s-tab-wrapper-icon">
            <img src={Icon1} alt="#" />
            <p className="checkout-s-tab-wrapper-title">1. Shipping info</p>
          </div>
          <i className="fi-rr-angle-right"></i>
        </NavLink>
        <NavLink
          to="/checkout/paymentinfo"
          state={{
            checkoutTitle: "Payment info",
            checkoutPath: "/checkout/paymentinfo",
          }}
          className="checkout-s-tab-wrapper"
          onClick={event => event.preventDefault()}
        >
          <div className="checkout-s-tab-wrapper-icon">
            <img src={Icon2} alt="#" />
            <p className="checkout-s-tab-wrapper-title">2. Payment info</p>
          </div>
          <i className="fi-rr-angle-right"></i>
        </NavLink>
        <NavLink
          to="/checkout/orderconfirmations"
          state={{
            checkoutTitle: "Confirmation",
            checkoutPath: "/checkout/orderconfirmations",
          }}
          className="checkout-s-tab-wrapper"
          onClick={event => event.preventDefault()}
        >
          <div className="checkout-s-tab-wrapper-icon">
            <img src={Icon3} alt="#" />
            <p className="checkout-s-tab-wrapper-title">3. Confirmation</p>
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default CheckoutShoppingTab;

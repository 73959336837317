import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Token from "../Config/Token";
const initialCardValue = {
  card_name: "",
  card_no: "",
  expiry_date: "",
  code: "",
  default: "",
  type: "",
};

const AddNewCardInfo = ({
  editID,
  setEditID,
  Cards,
  cardId,
  getCards,
  setCardModalShow,
  cardModalShow,
}) => {
  const { user, token } = Token();

  const handleClose = () => setCardModalShow(false);
  const handleShow = () => setCardModalShow(true);
  const [cardInfo, setCardInfo] = useState(initialCardValue);
  const [defaultBtn, setDefaultBtn] = useState(false);
  useEffect(() => {
    if (defaultBtn === true) {
      setCardInfo({
        ...cardInfo,
        default: 1,
      });
    } else {
      setCardInfo({
        ...cardInfo,
        default: 0,
      });
    }
  }, [defaultBtn]);
  const submit = async (e) => {
    e.preventDefault();
    if (!cardInfo?.card_name) {
      toast.error("Card name is required");
    } else if (!cardInfo?.card_no) {
      toast.error("Card no is required");
    } else if (!cardInfo?.expiry_date) {
      toast.error("Expiry date is required");
    } else if (!cardInfo?.code) {
      toast.error("Code is required");
    } else if (!cardInfo?.type) {
      toast.error("Type is required");
    } else {
      let route = "";
      editID ? (route = "update_card") : (route = "add_card");
      add_card(route);
    }
  };

  const add_card = async (route) => {
    // console.log(addressInfo)
    let formData = new FormData();
    // formData.append('user_id', user?.id);
    editID && formData.append("card_id", editID);
    formData.append("card_name", cardInfo?.card_name);
    formData.append("card_no", cardInfo?.card_no);
    formData.append("expiry_date", cardInfo?.expiry_date);
    formData.append("code", cardInfo?.code);
    formData.append("default", cardInfo?.default);
    formData.append("type", cardInfo?.type);
    if (route === "add_card") {
      await axios
        .post(`${process.env.React_APP_API_URL}/add/new/card`, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const success = res?.data?.success;
          const message = res?.data?.message;
          if (success) {
            toast.success(message);
            handleClose();
            setCardInfo(initialCardValue);
            getCards();
          }

          // if (adressId) {
          //   toast.success("address updated successfully!");
          //   window.location.reload();
          // } else {
          //   editID
          //     ? toast.success("address updated successfully!")
          //     : toast.success("new address added successfully!");
          // }
        })
        .catch((err) => console.log(err));
    } else if (route === "update_card") {
      await axios
        .post(`${process.env.React_APP_API_URL}/update/my/card`, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const success = res?.data?.success;
          const message = res?.data?.message;
          if (success) {
            toast.success(message);
            setEditID(null);
            handleClose();
            setCardInfo(initialCardValue);
            getCards();
          }

          // if (adressId) {
          //   toast.success("address updated successfully!");
          //   window.location.reload();
          // } else {
          //   editID
          //     ? toast.success("address updated successfully!")
          //     : toast.success("new address added successfully!");
          // }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (editID) {
      // console.log(editID)
      // console.log(Address)
      const edit = Cards.filter((each) => {
        return each?.id === editID;
      });
      console.log(edit);
      if (!!edit?.length) {
        setCardInfo({
          card_name: edit[0]?.card_name,
          card_no: edit[0]?.card_no,
          expiry_date: edit[0]?.expiry_date,
          code: edit[0]?.code,
          default: edit[0]?.default,
          type: edit[0]?.type,
        });
      }
    } else {
      setCardInfo(initialCardValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editID]);
  
  return (
    <>
      <div className="add-new-address-modal-main edit-modal-option card-info-edit-main">
        <Button className="add-new-card-btn theme-btn" onClick={handleShow}>
          <i className="fi-rr-plus"></i>Add New Card
        </Button>
        <Modal
          show={cardModalShow}
          onHide={handleClose}
          className="add-new-address-modal-form edit-modal-option-form"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-new-modal-main">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Card name
                      </label>
                      <input
                        placeholder="Enter full name"
                        type="text"
                        className="form-control"
                        value={cardInfo?.card_name}
                        onChange={(e) => {
                          setCardInfo({
                            ...cardInfo,
                            card_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Card number
                      </label>
                      <div className="form-input-wrapper-icon">
                        <input
                          placeholder="0000  0000  0000  0000"
                          type="text"
                          className="form-control"
                          value={cardInfo?.card_no}
                          onChange={(e) => {
                            setCardInfo({
                              ...cardInfo,
                              card_no: e.target.value,
                            });
                          }}
                        />
                        <i className="fi-sr-credit-card"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Expiry date
                      </label>
                      <input
                        placeholder="01/2025"
                        type="text"
                        className="form-control"
                        value={cardInfo?.expiry_date}
                        onChange={(e) => {
                          setCardInfo({
                            ...cardInfo,
                            expiry_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        CVC/CVV code
                      </label>
                      <input
                        placeholder="e.x. 3500"
                        type="text"
                        className="form-control"
                        value={cardInfo?.code}
                        onChange={(e) => {
                          setCardInfo({
                            ...cardInfo,
                            code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    {" "}
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Card Type
                      </label>
                      <select
                        aria-label="Default select example"
                        className="form-select"
                        value={cardInfo?.type}
                        onChange={(e) => {
                          setCardInfo({
                            ...cardInfo,
                            type: e.target.value,
                          });
                        }}
                      >
                        <option>Card Type</option>
                        <option value="1">Visa Card</option>
                        <option value="2">Master Card</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-check card-info-cehck">
                  <input
                    type="checkbox"
                    id="default-checkbox"
                    className="form-check-input"
                    onClick={()=>setDefaultBtn(!defaultBtn)}
                    checked={cardInfo?.default == 1 ? true : false}
                  />
                  <label
                    title=""
                    for="default-checkbox"
                    className="form-check-label"
                  >
                    Make as default card?
                  </label>
                </div>
                <button className="theme-btn" type="submit">
                {editID ? "Update Card" : "Save Card"} 
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddNewCardInfo;

import axios from "axios";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LockIcon from "../Assets/img/login-with-icon/lock-icon.svg";
import UserContext from "../Context/UserContext";

const ResetPassword = () => {
  let navigate = useNavigate();
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  console.log(signUpInfo);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const sendOtpInPhone = async (phoneNumber) => {
    let formData = new FormData();
    formData.append("phone", phoneNumber);
    await axios
      .post(`${process.env.React_APP_API_URL}/forget/password/sms`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/setnewpassword");
          setSingUpInfo({ ...signUpInfo, phone: phoneNumber });
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage);
      });
  };
  const sendOtpInEmail = async (emailId) => {
    let formData = new FormData();
    formData.append("email", emailId);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/forget/password/email`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/setnewpassword");
          setSingUpInfo({ ...signUpInfo, email: emailId });
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage);
      });
  };
  const onSubmit = (data) => {
    if (signUpInfo?.type !== "phone") {
      sendOtpInEmail(data?.email);
    } else {
      const phone = "+88" + data?.phone;
      sendOtpInPhone(phone);
    }
  };

  return (
    <>
      <div className="container">
        <div className="pages">
          <div className="p-category-top-menu mt-12">
            <ul className="p-category-top-menu-list m-0">
              <li>
                <Link to="/">Home</Link>
                <i className="fi-rr-angle-small-right"></i>
              </li>
              <li>
                <Link to="/loginwithemail">Login</Link>
                <i className="fi-rr-angle-small-right"></i>
              </li>
              <li className="active">
                <Link to="/login">Reset password</Link>
              </li>
            </ul>
          </div>
          <div className="reset-password-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  <div className="reset-password-card">
                    <div className="reset-password-top">
                      <img src={LockIcon} alt="#" />
                      <h4 className="reset-password-top-title">
                        Reset Password
                      </h4>
                      <p className="reset-password-top-text">
                        Type your email address to reset your password
                      </p>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="login-form-email"
                    >
                      {signUpInfo?.type !== "phone" ? (
                        <div className="form-control w-full max-w-xs">
                          <p className="form-title-label">Email address</p>
                          <input
                            type="email"
                            placeholder="Enter email"
                            className="input input-bordered w-full max-w-xs"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Email is Required",
                              },
                              pattern: {
                                value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                message: "Provide a valid Email",
                              },
                            })}
                          />
                          <label className="label">
                            {errors.email?.type === "required" && (
                              <span className="label-text-alt text-red-500">
                                {errors.email.message}
                              </span>
                            )}
                            {errors.email?.type === "pattern" && (
                              <span className="label-text-alt text-red-500">
                                {errors.email.message}
                              </span>
                            )}
                          </label>
                        </div>
                      ) : (
                        <div className="form-control w-full max-w-xs">
                          <p className="form-title-label">Phone Number</p>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            className="input input-bordered w-full max-w-xs"
                            {...register("phone", {
                              required: {
                                value: true,
                                message: "Phone Number is Required",
                              },
                            })}
                          />
                          <label className="label">
                            {errors.phone?.type === "required" && (
                              <span className="label-text-alt text-red-500">
                                {errors.phone.message}
                              </span>
                            )}
                          </label>
                        </div>
                      )}
                      <input
                        className="btn btn-accent w-full max-w-xs text-white"
                        type="submit"
                        value="Next"
                      />
                    </form>
                    <p className="remember-login-text">
                      Remembering credentials?{" "}
                      <Link to="/loginwithemail">Please login</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

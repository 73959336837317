import React from "react";

const ImageUploader = ({ selectedImage, setSelectedImage }) => {
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  return (
    <>
      <div className="imageupload-box">
        <form action="#" method="post">
          <div className="imageupload-btn">
            <input
              accept="image/*"
              type="file"
              placeholder="Choose file"
              onChange={imageChange}
              required
            />
          </div>
        </form>
        {selectedImage && (
          <div>
            <img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
            <button className="img-close-btn" onClick={removeSelectedImage}>
              <i className="fi-rr-cross-small"></i>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUploader;

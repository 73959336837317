import { Route, Routes } from "react-router-dom";
import Hero from "../Components/Hero";
import ScrollToTop from "../Components/ScrollToTop ";
import useProducts from "../hooks/useProducts";
import About from "./About";
import Category from "./Category";
import CheckOut from "./CheckOut";
import CreateTicket from "./CreateTicket";
import Dashboard from "./Dashboard";
import EmailSuccess from "./EmailSuccess";
import EmailVerification from "./EmailVerification";
import Home from "./Home";
import LogIn from "./LogIn";
import LogInWithEmail from "./LogInWithEmail";
import ManageProfile from "./ManageProfile";
import OrderConfirmations from "./OrderConfirmations";
import OrderTracking from "./OrderTracking";
import PaymentDeclined from "./PaymentDeclined";
import PaymentHistroy from "./PaymentHistroy";
import PaymentInfo from "./PaymentInfo";
import PhoneVerification from "./PhoneVerification";
import ProductSingle from "./ProductSingle";
import PurchaseHistory from "./PurchaseHistory";
import ResetPassword from "./ResetPassword";
import SearchResult from "./SearchResult";
import SetNewPassword from "./SetNewPassword";
import SetPasswordSuccess from "./SetPasswordSuccess";
import ShoppingInfo from "./ShoppingInfo";
import SignUp from "./SignUp";
import SignUpWithEmail from "./SignUpWithEmail";
import SupportTicket from "./SupportTicket";
import ThankYou from "./ThankYou";
import UpdateInformation from "./UpdateInformation";
import UserDashboard from "./UserDashboard";
import ViewOrderDetails from "./ViewOrderDetails";

import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Token from "../Config/Token";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import RequireAuth from "./RequireAuth";
import ReturnRefundPolicy from "./ReturnRefundPolicy";
import ShippingPolicy from "./ShippingPolicy";
import TermsConditions from "./TermsConditions";
import Wishlist from "./Wishlist";
import AccountDelete from "./AccountDelete";

const RoutePage = ({
  sidebar,
  setSidebar,
  cart,
  carts,
  setCarts,
  setCart,
  orderInfo,
  size,
  cartShow,
  setCartShow,
  generalInfo,
}) => {
  const { products, setProducts } = useProducts([]);
  const { token } = Token();

  const [loadOrders, setLoadOrders] = useState(true);
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allOrderProducts, setAllOrderProducts] = useState([]);
  const [tempCart, setTempCart] = useState([]);

  const getCartFromLocal = () => {
    let storedCart = localStorage.getItem("all_cart_data");
    storedCart = JSON.parse(storedCart);
    storedCart !== null && setTempCart(storedCart);
  };
  useEffect(() => {
    getCartFromLocal();
  }, []);

  useEffect(() => {
    setCart(tempCart);
  }, [tempCart?.length]);

  // Get WishList
  const getWishList = async () => {
    await axios
      .get(`${process.env.React_APP_API_URL}/get/my/wishlist`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const allWishlistData = res?.data?.data?.data;

        if (success) {
          toast.success(message);
          setWishlistProducts(allWishlistData);
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  //get all orders
  const getAllOrder = async () => {
    await axios
      .get(`${process.env.React_APP_API_URL}/get/my/orders`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const allOrdersData = res?.data?.date?.data;
        let products = [];

        if (success) {
          toast.success(message);
          setAllOrders(allOrdersData);
          allOrdersData?.map((each) => {
            each?.ordered_items?.map((eachOrder) => {
              // console.log(eachOrder);
              products?.push(eachOrder);
            });
          });
        } else {
          toast.error(message);
        }
        setAllOrderProducts(products);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const [personalInfo, setPersonalInfo] = useState([]);
  const getPersonalInfo = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/user/profile/info`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setPersonalInfo(res?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  useEffect(() => {
    token && getWishList();
    token && getAllOrder();
    token && getPersonalInfo();
  }, [token, loadOrders]);

  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              sidebar={sidebar}
              setSidebar={setSidebar}
              generalInfo={generalInfo}
            />
          }
        >
          <Route
            index
            element={
              <Hero
                sidebar={sidebar}
                cart={cart}
                setCart={setCart}
                cartShow={cartShow}
                setCartShow={setCartShow}
              />
            }
          />
          <Route path="about" element={<About generalInfo={generalInfo} />} />

          <Route
            path="/:slugName"
            element={<Category cart={cart} setCart={setCart} />}
          />
          <Route
            path="/search/:searchText"
            element={<SearchResult cart={cart} setCart={setCart} />}
          />
          <Route
            path="/login"
            element={<LogIn cart={cart} setCart={setCart} />}
          />
          <Route
            path="/loginwithemail"
            element={<LogInWithEmail cart={cart} />}
          />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/emailsuccess" element={<EmailSuccess />} />
          <Route path="/setnewpassword" element={<SetNewPassword />} />
          <Route path="/setpasswordsuccess" element={<SetPasswordSuccess />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signupwithemail" element={<SignUpWithEmail />} />
          <Route path="/phoneverification" element={<PhoneVerification />} />
          <Route path="/emailverification" element={<EmailVerification />} />
          <Route
            path="/updateinformation"
            element={<UpdateInformation cart={cart} />}
          />
          <Route
            path="/account/delete"
            element={
              <RequireAuth>
                <AccountDelete />
              </RequireAuth>
            }
          />
          <Route
            path="checkout/"
            element={
              <RequireAuth>
                <CheckOut />
              </RequireAuth>
            }
          >
            <Route
              path="paymentinfo"
              element={
                <RequireAuth>
                  <PaymentInfo
                    cart={cart}
                    setCart={setCart}
                    orderInfo={orderInfo}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="orderconfirmations"
              element={
                <RequireAuth>
                  <OrderConfirmations
                    loadOrders={loadOrders}
                    setLoadOrders={setLoadOrders}
                  />
                </RequireAuth>
              }
            />

            <Route
              path="shoppinginfo"
              element={
                <RequireAuth>
                  <ShoppingInfo personalInfo={personalInfo} />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="checkout/orderconfirmations/ThankYou"
            element={<ThankYou />}
          />

          <Route path="checkout/OrderTracking" element={<OrderTracking />} />

          <Route
            path="user"
            element={
              <RequireAuth>
                <Dashboard personalInfo={personalInfo} />
              </RequireAuth>
            }
          >
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <UserDashboard
                    cart={cart}
                    setCart={setCart}
                    products={products}
                    sidebar={sidebar}
                    size={size}
                    wishlistProducts={wishlistProducts}
                    allOrdersData={allOrders}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="purchasehistory"
              element={
                <RequireAuth>
                  <PurchaseHistory
                    sidebar={sidebar}
                    size={size}
                    allOrders={allOrders}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="purchasehistory/:orderId"
              element={
                <RequireAuth>
                  <ViewOrderDetails allOrders={allOrders} />
                </RequireAuth>
              }
            />
            <Route
              path="wishlist"
              element={
                <RequireAuth>
                  <Wishlist
                    cart={cart}
                    setCart={setCart}
                    cartShow={cartShow}
                    setCartShow={setCartShow}
                    wishlistProducts={wishlistProducts}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="paymenthistroy"
              element={
                <RequireAuth>
                  <PaymentHistroy
                    sidebar={sidebar}
                    size={size}
                    allOrders={allOrders}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="supportticket"
              element={
                <RequireAuth>
                  <SupportTicket personalInfo={personalInfo} size={size} />
                </RequireAuth>
              }
            />
            <Route
              path="manageprofile"
              element={
                <RequireAuth>
                  <ManageProfile personalInfo={personalInfo} />
                </RequireAuth>
              }
            />
            <Route
              path="createticket"
              element={
                <RequireAuth>
                  <CreateTicket />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="product/details/:productId"
            element={
              <ProductSingle
                cart={cart}
                setCart={setCart}
                cartShow={cartShow}
                setCartShow={setCartShow}
                getWishList={getWishList}
                wishlistProducts={wishlistProducts}
              />
            }
          />
          <Route path="paymentdeclined" element={<PaymentDeclined />} />
          <Route
            path="contactus"
            element={<ContactUs generalInfo={generalInfo} />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="return&refund-policy" element={<ReturnRefundPolicy />} />
          <Route path="terms&conditions" element={<TermsConditions />} />
          <Route path="shipping-policy" element={<ShippingPolicy />} />
        </Route>
      </Routes>
    </div>
  );
};

export default RoutePage;

/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import useProducts from "../hooks/useProducts";
import Categories from "./Categories";
import PopularItems from "./PopularItems";
import TestimonialSlider from "./TestimonialSlider";

import axios from "axios";

const Hero = ({ sidebar, cart, setCart, cartShow, setCartShow }) => {
  const {products, setProducts} = useProducts([]);
  const [sliders, setSliders] = useState([]);
  const [banners, setBanners] = useState([]);

  let popularItems = [];
  for (const product of products) {
    if (product?.promo === true) {
      popularItems?.push(product);
    }
  }

  const navigate = useNavigate();
  const navigateToCategory = (categoryName) => {
    navigate(`/${categoryName}`);
  };

  const getSliderDetails = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/all/sliders`, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => setSliders(res?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const getBannerDetails = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/all/banners`, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => setBanners(res?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  useEffect(() => {
    getSliderDetails();
    getBannerDetails();
  }, []);

  return (
    <>
      {/* Hero Area */}
      <section className="sodainagar-hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="hero-slider-main">
                {/* Hero Slider */}
                <div className="b-model-bottom-widget-img-slider">
                  <Swiper
                    modules={[Autoplay, Pagination]}
                    // spaceBetween={24}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={true}
                  >
                    {sliders?.map((SingleSlider,index) => (
                      <SwiperSlide key={index}>
                        <a
                          href={
                            SingleSlider?.link === null
                              ? "/"
                              : SingleSlider?.link
                          }
                          className="hero-single-slider "
                          target="blank"
                          style={{
                            backgroundImage: `url(${
                              process.env.React_APP_IMAGES_URL +
                              SingleSlider.image
                            })`,
                          }}
                        ></a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              {/* Hero Banner */}

              <div className="hero-right-banner">
                {banners?.map((banner,index) => (
                  <div key={index} className="hero-single-banner">
                    <Link
                      to={banner?.link === null ? "/" : banner?.link}
                      className="hero-single-banner-img"
                    >
                      <img
                        src={process.env.React_APP_IMAGES_URL + banner?.image}
                        alt="#"
                      />
                    </Link>
                  </div>
                ))}
                {/* <div className="hero-single-banner">
                  <Link to="#" className="hero-single-banner-img">
                    <img src={HeroSidebarImg2} alt="#" />
                  </Link>
                </div> */}
              </div>
              {/* End Hero Banner */}
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Area */}

      <div className="sodainagar-shop-categories">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="section-head">
                <h3 className="section-title text-center">
                  Shop by Categories
                </h3>
              </div>
            </div>
          </div>
          <Categories />
        </div>
      </div>
      <div className="popular-products-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {" "}
              <div className="section-head style-2">
                <h3 className="section-title">All Products</h3>
              </div>
              <PopularItems
                cart={cart}
                setCart={setCart}
                cartShow={cartShow}
                setCartShow={setCartShow}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-area bg-color2 section-padding">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3  col-12">
              <div className="section-head">
                <h3 className="section-title text-center">
                  We Hear From Our Clients
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-12">
            <TestimonialSlider />
          </div>
        </div>
      </div>
      {/* <div className="blog-area">
        <div className="row g-0">
          <div className="col-lg-6 col-md-10 col-12">
            <div className="section-head">
              <h3 className="section-title">News/Blogs</h3>
              <p className="section-head-text">
                Integer morbi egestas in tortor pellentesque commodo. Semper est
                et fermentum quisque odio dis. Felis consectetur tincidunt
                viverra viverra pulvinar diam.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-card-widget-inner">
          <BlogCard />
        </div>
      </div> */}
      {/* 
      <CookieNotice /> */}
    </>
  );
};

export default Hero;

import axios from "axios";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RichTextEditor from "../Components/RichTextEditor";
import Token from "../Config/Token";
const CreateTicket = () => {
  const { user, token, clearUser } = Token();
  let navigate = useNavigate();
  const initialCreateTicketValue = {
    subject: "",
    message: "",
    attachment: "",
  };
  const [selectedImage, setSelectedImage] = useState();
  const [createTicketInfo, setCreateTicketInfo] = useState(
    initialCreateTicketValue
  );
  const [content, setContent] = useState("");
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };
  const updateProfile = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("subject", createTicketInfo?.subject);
    formData.append("message", content);
    formData.append("attachment", selectedImage);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/submit/support/ticket`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;

        if (success) {
          toast.success(message);
          navigate("/user/supportticket");
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  // console.log(content);
  return (
    <>
      <div className="container">
        <div className="user-dashboard-main">
          <h4 className="dashboard-main-title">Create ticket</h4>
          <div className="create-ticket-main">
            <p className="create-ticket-main-top-text">
              Describe the issue you faced.
            </p>
            <div className="create-ticket-main-inner">
              {/* <h5 className="c-ticket-inner-title">Ticket id #1607319</h5> */}
              <div className="create-ticket-from">
                <form onSubmit={updateProfile}>
                  <div className="form-group">
                    <label>Ticket title</label>
                    <input
                      type="text"
                      name="ticket-title"
                      placeholder="Ticket title here"
                      value={createTicketInfo?.subject}
                      onChange={(e) => {
                        setCreateTicketInfo({
                          ...createTicketInfo,
                          subject: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="form-group m-0">
                    <span>Ticket Details</span>
                    <RichTextEditor content={content} setContent={setContent} />
                  </div>
                  <div className="a-comments-attach-file">
                    <div className="box">
                      {selectedImage && (
                        <div className="create-ticket-img">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Thumb"
                          />
                          <button
                            className="create-ticket-img-close-btn"
                            onClick={removeSelectedImage}
                          >
                            <i className="fi-rr-cross-small"></i>
                          </button>
                        </div>
                      )}
                      <input
                        type="file"
                        name="file-1[]"
                        id="file-1"
                        onChange={imageChange}
                        className="inputfile inputfile-1"
                      />

                      <label for="file-1">
                        <i className="fi-rr-clip"></i>
                        <span>Attach file</span>
                      </label>
                    </div>
                    <p>Supported files: .jpg, .jpeg .png</p>
                  </div>
                  <button className="theme-btn" type="submit">
                    <i className="fi-rr-plus"></i>Create ticket
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;

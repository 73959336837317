import { Link } from "react-router-dom";
import CartItem from "./CartItem";

const CartSidebarItems = ({
  carts,
  setCart,
  setCartShow,
  incrementHandle,
  setIncrementHandle,
}) => {
  return (
    <>
      {carts?.length === 0 ? (
        <div>
          <div className="empty-cart-widget-area">
            <div className="container">
              <div className="row">
                <div className="">
                  <div className="thankyou-widget">
                    {/* <img src={ThankYouIcon} alt="#" /> */}
                    <div className="thankyou-content">
                      <h4 className="thankyou-content-title">
                        You Cart is Empty!
                      </h4>
                      {/* <p className="thankyou-content-text">
                          Seems like you don't have wishes here.make a wish
                        </p> */}
                      <div className="thankyou-content-btn">
                        <Link
                          to="/"
                          onClick={() => {
                            setCartShow(false);
                          }}
                          className="theme-btn"
                        >
                          Start shopping
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        carts.map((cart) => (
          <CartItem
            key={cart?.id}
            cart={cart}
            carts={carts}
            setCart={setCart}
            incrementHandle={incrementHandle}
            setIncrementHandle={setIncrementHandle}
          />
        ))
      )}
    </>
  );
};

export default CartSidebarItems;

import { Editor } from "@tinymce/tinymce-react";
import React from "react";

const RichTextEditor = ({content, setContent}) => {
  // constructor(props) {
  //   super(props);
  //   this.state = { content: "" };

  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }
  
  const handleChange = (event) => {
    setContent(event);
  };

  const handleSubmit = (event) => {
    alert("Text was submitted: " + this.state.content);
    event.preventDefault();
  };

  return (
    <div onSubmit={handleSubmit}>
      <Editor
        apiKey="my-api-key"
        value={content}
        init={{
          height: 500,
          menubar: false,
        }}
        onEditorChange={(e)=>handleChange(e)}
      />
    </div>
  );
};

export default RichTextEditor;

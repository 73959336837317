import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Product from "../Components/Product";
import ProductCategoryTop from "../Components/ProductCategoryTop";
import UserContext from "../Context/UserContext";
import useCategory from "../hooks/useCategory";
// import { addToDb } from "../utilities/fakedb";

const Category = ({ cart, setCart }) => {
  const { slugName } = useParams();
  const { sideBarData } = useContext(UserContext);
  const [selectCategoryId, setSelectCategoryId] = useState(0);
  const [selectCategory, setSelectCategory] = useState({});

  // const [cart, setCart] = useCart([]);
  const getSelectCategoryStage = (sideBarItems) => {
    for (const singleSideBar of sideBarItems) {
      if (slugName === singleSideBar?.slug) {
        setSelectCategory(singleSideBar);
      } else {
        singleSideBar?.children !== undefined &&
          getSelectCategoryStage(singleSideBar?.children);
      }
    }
  };
  
  useEffect(() => {
    getSelectCategoryStage(sideBarData);
  }, [slugName, sideBarData]);
  const {
    categoryProducts,
    setCategoryProducts,
    nextPageURL,
    getNextProducts,
  } = useCategory(slugName, selectCategory?.id, selectCategory?.stage);

  const handleAddToCart = (selectedProduct) => {
    let newCart = [];

    const exists = cart?.find((product) => product?.id === selectedProduct?.id);
    const rest = cart?.find((product) => product?.id !== selectedProduct?.id);

    if (!exists) {
      selectedProduct.quantity = 1;
      newCart = [...cart, selectedProduct];
    } else {
      const rest = cart?.filter(
        (product) => product?.id !== selectedProduct?.id
      );
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, exists];
    }

    setCart(newCart);
    localStorage.setItem("all_cart_data", JSON.stringify(newCart));
    // addToDb(selectedProduct.id);
  };

  return (
    <div className="container">
      <div className="pages">
        <ProductCategoryTop
          selectCategory={selectCategory}
          categoryLength={categoryProducts.length}
        />
        <div className="products-inner">
          {categoryProducts.map((product) => (
            <Product
              key={product.id}
              product={product}
              handleAddToCart={handleAddToCart}
              cart={cart}
              setCart={setCart}
            ></Product>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            {nextPageURL !== null && (
              <div className="products-load-more-btn">
                <button className="theme-btn" onClick={() => getNextProducts()}>
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;

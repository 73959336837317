import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import OrderHistoryTableBody from "../Components/OrderHistoryTableBody";

const PurchaseHistory = ({ sidebar, size, allOrders }) => {
  
  const [startMap, setStartMap] = useState(0);
  const [endMap, setEndMap] = useState(10);
  const [handleLoad, setHandleLoad] = useState(false);
  const [handlePrevious, setHandlePrevious] = useState(false);
 
  useEffect(() => {
    if (allOrders?.length > endMap) {
      setHandleLoad(true);
    }
    if (startMap === 0) {
      setStartMap(0);
      setEndMap(10);
      setHandlePrevious(false);
    }
  }, [allOrders, startMap, endMap]);
  // console.log(allOrders)
  const handleLoadBtn = () => {
    if (
      startMap + 10 <= allOrders?.length &&
      allOrders?.length <= endMap + 10
    ) {
      setStartMap(startMap + 10);
      setEndMap(allOrders?.length);
      setHandleLoad(false);
      setHandlePrevious(true);
    } else if (allOrders?.length > endMap) {
      setStartMap(startMap + 10);
      setEndMap(endMap + 10);
      setHandleLoad(true);
      setHandlePrevious(true);
    }
  };
  const handlePreviousBtn = () => {
    if (allOrders?.length === endMap) {
      setStartMap(startMap - 10);
      setEndMap(endMap - (endMap - startMap));
    } else {
      setStartMap(startMap - 10);
      setEndMap(endMap - 10);
      setHandleLoad(true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="user-dashboard-main pd-bottom">
          <h4 className="dashboard-main-title">Purchase history</h4>
          <Table className="order-history-table-main">
            <thead className="dash-table-head">
              <tr>
                <th>Date</th>
                <th>Products list</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {allOrders?.slice(startMap, endMap)?.map((orderHistory) => (
              <OrderHistoryTableBody
                key={orderHistory?.id}
                orderHistory={orderHistory}
                size={size}
                sidebar={sidebar}
              />
            ))}
          </Table>
          <div className="payment-method-bottom-btn">
            {handlePrevious ? (
              <Button
                variant="link"
                onClick={handlePreviousBtn}
                className="Previous-more-btn"
              >
                Previous
              </Button>
            ) : (
              ""
            )}

            {handleLoad ? (
              <Button
                variant="link"
                onClick={handleLoadBtn}
                className="load-more-btn"
              >
                Load more
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseHistory;

import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CartSidebar from "./Components/CartSidebar";
import Header from "./Components/Header";
import ScrollToTop from "./Components/ScrollToTop ";
import ScrollTop from "./Components/ScrollTop";
import RoutePage from "./Pages/RoutePage";
import useProducts from "./hooks/useProducts";
import { getStoredCart } from "./utilities/fakedb";
//Context
import axios from "axios";
import UserContext from "./Context/UserContext";

function App() {
    const initialSignUpInfoValue = {
        phone: "",
        email: "",
        code: "",
        type: "",
    };

    const location = useLocation();
    const [sidebar, setSidebar] = useState(true);
    const [cart, setCart] = useState([]);
    const [carts, setCarts] = useState([]);
    const [cartShow, setCartShow] = useState(false);
    const { products, setProducts } = useProducts([]);
    const [orderInfo, setOrderInfo] = useState([]);
    const [size, setSize] = useState([0, 0]);
    const [checkoutOrderInfo, setCheckoutOrderInfo] = useState(null);
    const [shoppingOrderInfo, setShoppingOrderInfo] = useState({});
    const [paymentOrderInfo, setPaymentOrderInfo] = useState({});
    const [orderId, setOrderId] = useState(0);
    const [signUpInfo, setSingUpInfo] = useState(initialSignUpInfoValue);
    const [generalInfo, setGeneralInfo] = useState({});
    const [sideBarData, setSideBarData] = useState([]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    const getGeneraDetails = async () => {
        axios
            .get(`${process.env.React_APP_API_URL}/general/info`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
                },
            })
            .then((res) => setGeneralInfo(res?.data?.data))
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                // toast.error(errorMessage);
            });
    };
    const getSideBarData = async () => {
        axios
            .get(`${process.env.React_APP_API_URL}/get/category/tree`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
                },
            })
            .then((response) => {
                setSideBarData(response?.data?.data);
            });
    };
    useEffect(() => {
        getGeneraDetails();
        getSideBarData();
    }, []);
    // useEffect(() => {
    //   const storedCart = getStoredCart();
    //   const saveCart = [];
    //   const keys = Object.keys(storedCart);

    //   let cartProducts = [];
    //   for (const key of keys) {
    //     for (const product of products) {
    //       if (product.id === key) {
    //         cartProducts.push(product);
    //       }
    //     }
    //   }
    //   // const keys = Object.keys(storedCart);
    //   // console.log(keys);
    //   // fetch("http://localhost:5000/productByKeys",{
    //   //     method: "POST",
    //   //     headers: {
    //   //         'content-type': 'application/json'
    //   //     },
    //   //     body: JSON.stringify(keys)
    //   // })
    //   //   .then((res) => res.json())
    //   //   .then((products) => {
    //   //     for (const id in storedCart) {
    //   //       const addedProduct = products.find((product) => product._id === id);
    //   //       if (addedProduct) {
    //   //         const quantity = storedCart[id];
    //   //         addedProduct.quantity = quantity;
    //   //         saveCart?.push(addedProduct);
    //   //       }
    //   //     }
    //   //     setCart(saveCart);
    //   //   });
    //   for (const id in storedCart) {
    //     const addedProduct = cartProducts.find((product) => product.id === id);
    //     if (addedProduct) {
    //       const quantity = storedCart[id];
    //       addedProduct.quantity = quantity;
    //       saveCart?.push(addedProduct);
    //     }
    //   }

    //   console.log("🚀 ~ file: App.js:122 ~ useEffect ~ saveCart:", saveCart)
    //   setCart(saveCart);
    // }, [products]);
    useEffect(() => {
        setSidebar(true);
    }, [location?.pathname]);
    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 200) {
                setSidebar(true);
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    const value = {
        checkoutOrderInfo,
        setCheckoutOrderInfo,
        shoppingOrderInfo,
        setShoppingOrderInfo,
        paymentOrderInfo,
        setPaymentOrderInfo,
        orderId,
        setOrderId,
        signUpInfo,
        setSingUpInfo,
        sideBarData,
        setSideBarData,
    };
    return (
        <>
            <ScrollToTop />
            <ScrollTop />
            <UserContext.Provider value={value}>
                <Header
                    cart={cart}
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                    cartShow={cartShow}
                    setCartShow={setCartShow}
                    generalInfo={generalInfo}
                />

                <RoutePage
                    cart={cart}
                    setCart={setCart}
                    carts={carts}
                    setCarts={setCarts}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                    orderInfo={orderInfo}
                    size={size}
                    cartShow={cartShow}
                    setCartShow={setCartShow}
                    generalInfo={generalInfo}
                />

                <CartSidebar
                    cart={cart}
                    setCart={setCart}
                    setOrderInfo={setOrderInfo}
                    cartShow={cartShow}
                    setCartShow={setCartShow}
                />
            </UserContext.Provider>
            <ToastContainer />
        </>
    );
}

export default App;

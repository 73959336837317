import axios from "axios";
import { useEffect, useState } from "react";

const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [nextPageURL, setNextPageURL] = useState(null);
  const [prevPageURL, setPrevPageURL] = useState(null);

  const getProducts = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/all/products`, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const data = res?.data?.data?.data;
        if (success) {
          setProducts(data);
          setNextPageURL(res?.data?.data?.next_page_url);
          setPrevPageURL(res?.data?.data?.prev_page_url);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);
  const getNextProducts = async () => {
    axios
      .get(nextPageURL, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const data = res?.data?.data?.data;
        if (success) {
          const allProducts = [...products, ...data];
          setProducts(allProducts);
          setNextPageURL(res?.data?.data?.next_page_url);
          setPrevPageURL(res?.data?.data?.prev_page_url);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  return { products, setProducts, nextPageURL, prevPageURL, getNextProducts };
};

export default useProducts;

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
// import { addToDb, removeFromDbCart } from "../utilities/fakedb";
import ProductDetailsModal from "./ProductDetailsModal";
import { toast } from "react-toastify";

const Product = ({ product, handleAddToCart, cart, setCart, categoryProducts, cartShow, setCartShow }) => {
    const location = useLocation();
    const { id, name, image, price, discount_price, unit_name, stock, average_rating, weight, promo } = product;
    const [themeBtn, setThemeBtn] = useState(true);
    const [modalProduct, setModalProduct] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        handleShowThemeBtn(id);
    }, [product, cart?.length]);

    const handleShowThemeBtn = (id) => {
        const exists = cart?.find((cartProduct) => cartProduct.id === id);
        // if(exists.quantity === 0){
        //   removeFromDb(id)
        // }

        if (exists) {
            setThemeBtn(false);

            const cartQuantity = exists.quantity;
            setQuantity(cartQuantity);
        } else {
            setThemeBtn(true);
        }
    };
    const showThemeBtn = (id) => {
        handleShowThemeBtn(id);
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };
    // useEffect(() => {
    //   console.log("cart lentgh changed" + cart?.length);
    //   handleShowThemeBtn(id);
    // }, [cart?.length]);

    const [quantity, setQuantity] = useState(0);
    let newCart = [];

    const increment = (product) => {
        const newQuantity = quantity + 1;
        const exists = cart?.find((product) => product.id === id);
        if (exists) {
            const rest = cart?.filter((product) => product.id !== id);
            exists.quantity = exists.quantity + 1;
            newCart = [...rest, exists];
            setCart(newCart);
            localStorage.setItem("all_cart_data", JSON.stringify(newCart));
            // addToDb(id);
        }
        //handleAddToCart(product)
        setQuantity(newQuantity);
    };
    const decrement = () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1;
            const exists = cart?.find((product) => product?.id === id);
            if (exists) {
                const rest = cart?.filter((product) => product?.id !== id);
                exists.quantity = exists?.quantity - 1;
                console.log(rest);
                newCart = [...rest, exists];
                setCart(newCart);
                localStorage.setItem("all_cart_data", JSON.stringify(newCart));
                // removeFromDbCart(id);
            }
            setQuantity(newQuantity);
        }
        if (quantity === 1) {
            setThemeBtn(!themeBtn);
        }
    };
    const change = (url) => {
        window.history.pushState("new", "title", url);
    };
    const handleModal = () => {
        setShow(!show);
        setModalProduct(product);
        const productUrl = `/product/` + id;
        change(productUrl);
    };
    // console.log(product);

    const handleCartShow = () => {
        toast("🛒 Product added to cart", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };
    return (
        <>
            <div className="popular-products-wrapper">
                <Link to={`/product/details/${product?.product_name ? product?.product_id : id}`} className="w-100">
                    {" "}
                    <div className="p-products-wrapper-img">
                        <img src={process.env.React_APP_IMAGES_URL + image} alt="#" />
                        <span className="p-product-weight">{unit_name}</span>
                    </div>
                    <div className="p-products-cont-top">
                        <div className="p-products-cont-top-title">
                            <h6 className="p-products-cont-title">
                                {product?.product_name ? product?.product_name : name}
                            </h6>
                            <div className="p-products-price ">
                                {discount_price <= 0 ? (
                                    ""
                                ) : (
                                    <del>{product?.product_price ? product?.product_price : price} Taka</del>
                                )}
                                <span>{discount_price > 0 ? discount_price : price} Taka</span>
                            </div>
                        </div>
                        <ul className="p-products-review-list">
                            <Rating
                                className="your-ratings"
                                fillIcon={<i className="fi-ss-star rating-icon"></i>}
                                emptyIcon={<i className="fi fi-rs-star rating-icon"></i>}
                                initialValue={average_rating}
                                readonly={true}
                                // showTooltip
                            />
                            <li>{average_rating === null ? 0 : average_rating} (ratings)</li>
                        </ul>
                    </div>
                </Link>
                <div className="popular-products-content">
                    {stock === 0 ? (
                        <div className="product-stock-out">
                            <span className="product-stock-out-btn">Out of stock</span>
                        </div>
                    ) : (
                        <div className="p-products-cont-btn">
                            <div className="p-products-cont-add-cart">
                                {themeBtn ? (
                                    <button
                                        onClick={() => {
                                            showThemeBtn(id);
                                            handleAddToCart(product);
                                            handleCartShow();
                                        }}
                                        className={themeBtn ? "pp-quantity theme-btn" : "pp-quantity theme-btn "}
                                    >
                                        <div>
                                            <i className="fi-rr-shopping-bag-add"></i>Add to cart
                                        </div>
                                    </button>
                                ) : (
                                    <button className={themeBtn ? "pp-quantity theme-btn" : "pp-quantity theme-btn "}>
                                        <div>
                                            <i className="fi fi-br-check"></i>Added
                                        </div>
                                    </button>
                                )}
                            </div>
                            <div className="p-products-buy-now-btn">
                                <button
                                    onClick={() => {
                                        handleAddToCart(product);
                                        handleCartShow();
                                    }}
                                    className="theme-btn"
                                >
                                    <i className="fi-rr-shopping-bag"></i>Buy now
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ProductDetailsModal
                modalProduct={modalProduct}
                handleModal={handleModal}
                show={show}
                change={change}
                location={location}
                quantity={quantity}
                increment={increment}
                decrement={decrement}
                cart={cart}
                setCart={setCart}
            />
        </>
    );
};

export default Product;

import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import SideBar from "../Components/SideBar";

const Home = ({ sidebar, setSidebar, generalInfo }) => {
    return (
        <>
            <div className="sodainagar-main compo">
                <SideBar sidebar={sidebar} setSidebar={setSidebar} />
                <div
                    id="sodainagar-main-inner"
                    className={sidebar ? "sodainagar-main-inner" : "sodainagar-main-inner toggle"}
                >
                    <Outlet />
                    <Footer generalInfo={generalInfo} />
                </div>
            </div>
        </>
    );
};

export default Home;

import React, { useEffect, useState } from 'react'
import Img from '../Assets/img/shop-categories/item-1.jpg'
import SwiperCore, { Virtual, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const ModalGallerySlider = ({ subImgs }) => {
	const [selectedImage, setSelectedImage] = useState('')
	useEffect(() => {
		const firstImg = subImgs && subImgs[0]?.image
		setSelectedImage(firstImg)
	}, [subImgs])
	const product = {
		imageUrl: process.env.React_APP_IMAGES_URL + selectedImage,
		zoomFactor: 3 // Adjust this value to control the zoom level
	}

	const [zoomedImageStyle, setZoomedImageStyle] = useState({
		backgroundImage: process.env.React_APP_IMAGES_URL + selectedImage,
		backgroundSize: '',
		backgroundPosition: '',
		display: 'block'
	})

	const handleMouseMove = event => {
		const productImage = event.target
		const zoomedImage = productImage.nextSibling

		const offsetX = event.nativeEvent.offsetX
		const offsetY = event.nativeEvent.offsetY

		const zoomedWidth = productImage.offsetWidth * product.zoomFactor
		const zoomedHeight = productImage.offsetHeight * product.zoomFactor

		const bgPosX = -offsetX * (zoomedWidth / productImage.offsetWidth - 1)
		const bgPosY = -offsetY * (zoomedHeight / productImage.offsetHeight - 1)

		setZoomedImageStyle({
			backgroundImage: `url(${product.imageUrl})`,
			backgroundSize: `${zoomedWidth}px ${zoomedHeight}px`,
			backgroundPosition: `${bgPosX}px ${bgPosY}px`,
			display: 'block'
		})
	}

	const resetZoom = () => {
		setZoomedImageStyle({
			display: 'none'
		})
	}

	useEffect(() => {
		resetZoom()
	}, [])
	return (
		<>
			<div id='content_wrapper'>
				<section>
					<div className='container'>
						<div className='row'>
							<div className='col-md-3'>
								<div className='product-image-container'>
									<img src={product.imageUrl} alt='Product' onMouseEnter={handleMouseMove} onMouseMove={handleMouseMove} onMouseLeave={resetZoom} />
									<div className='zoomed-image' style={zoomedImageStyle} />
								</div>
							</div>
						</div>
						<div className='modal-gallery-slider'>
							<Swiper
								slidesPerView={6}
								spaceBetween={10}
								pagination={{
									clickable: true
								}}
								breakpoints={{
									'@0.00': {
										slidesPerView: 1,
										spaceBetween: 10
									},
									'@0.75': {
										slidesPerView: 2,
										spaceBetween: 20
									},
									'@1.00': {
										slidesPerView: 3,
										spaceBetween: 40
									},
									'@1.50': {
										slidesPerView: 4,
										spaceBetween: 50
									}
								}}
								modules={[Pagination]}
								//   navigation={true}
								className='mySwiper'>
								{subImgs?.map((subImg, index) => (
									<SwiperSlide key={index} onClick={() => setSelectedImage(subImg?.image)}>
										<img src={process.env.React_APP_IMAGES_URL + subImg?.image} alt='#' />
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default ModalGallerySlider

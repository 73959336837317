import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Product from "../Components/Product";
// import { addToDb } from "../utilities/fakedb";

const SearchResult = ({ cart, setCart }) => {
  const { searchText } = useParams();
  const [searchProducts, setSearchProducts] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${process.env.React_APP_API_URL}/search/products?search_keyword=${searchText}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => setSearchProducts(res?.data?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  }, [searchText]);
  const words = searchText.split(" ");
  let searchTitle = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  const handleAddToCart = (selectedProduct) => {
    let newCart = [];

    const exists = cart?.find((product) => product?.id === selectedProduct?.id);
    const rest = cart?.find((product) => product?.id !== selectedProduct?.id);

    if (!exists) {
      selectedProduct.quantity = 1;
      newCart = [...cart, selectedProduct];
    } else {
      const rest = cart?.filter((product) => product?.id !== selectedProduct?.id);
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, exists];
    }

    setCart(newCart);
    localStorage.setItem('all_cart_data', JSON.stringify(newCart));
    // addToDb(selectedProduct.id);
  };

  return (
    <>
      <div className="container">
        <div className="product-category-top">
          <div className="p-category-top-menu">
            <ul className="p-category-top-menu-list">
              <li>
                <Link to="/">Home</Link>
                <i className="fi-rr-angle-small-right"></i>
              </li>
              <li>
                <Link to="/">All categories</Link>
                <i className="fi-rr-angle-small-right"></i>
              </li>
              <li>
                <span>Search Products</span>
              </li>
            </ul>
            <h1 className="p-item-found-title">
              Search result: “{searchTitle}”
            </h1>
            <p className="p-item-found-text">
              ({searchProducts.length} items found)
            </p>
          </div>
          <div className="product-category-select">
            <label>Sort by:</label>
            <Form.Select aria-label="Default select example">
              <option>Acceding</option>
              <option value="1">Descending</option>
              <option value="2">New products</option>
              <option value="3">Price high to low</option>
              <option value="4">Price low to high</option>
            </Form.Select>
          </div>
        </div>
        <div className="products-inner">
          {searchProducts.map((product) => (
            <Product
              key={product.id}
              product={product}
              handleAddToCart={handleAddToCart}
              cart={cart}
              setCart={setCart}
            ></Product>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchResult;

import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BreadcrumbsBg from "../Assets/img/contactus-breadcrumbs-bg.png";

const initalState = {
  name: "",
  email: "",
  phone: "",
  company_name: "",
  message: "",
  // status: "",
};

const ContactUs = ({ generalInfo }) => {
  const [comments, setComents] = useState(initalState);

  const submit = async (e) => {
    e.preventDefault();

    if (!comments?.name) {
      toast.error("Name is required!");
    } else if (!comments?.email) {
      toast.error("Email is required!");
    } else if (!comments?.phone) {
      toast.error("Phone is required!");
    } else if (!comments?.company_name) {
      toast.error("Comapany is required!");
    } else if (!comments?.message) {
      toast.error("Message is required!");
    } else {
      let formData = new FormData();
      formData.append("name", comments?.name);
      formData.append("email", comments?.email);
      formData.append("phone", comments?.phone);
      formData.append("company_name", comments?.company_name);
      formData.append("message", comments?.message);

      await axios
        .post(
          `${process.env.React_APP_API_URL}/submit/contact/us/request`,
          formData,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          const error = res?.data?.error;
          const success = res?.data?.success;
          const message = res?.data?.message;

          if (error) {
            toast.error(message);
          } else {
            // console.log(data);
            toast.success(message);
            setComents(initalState);
          }
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          // toast.error(errorMessage);
        });
    }
  };

  return (
    <>
      <section
        className="sodainagar-breadcrumbs"
        style={{ backgroundImage: `url(${BreadcrumbsBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
              <div className="sodainagar-breadcrumbs-content">
                <h3 className="sodainagar-bread-cont-title">Contact Us</h3>
                <ul className="sodainagar-bread-menu">
                  <li>
                    <Link to="/">Home</Link>
                    <i className="fi fi-br-angle-right"></i>
                  </li>
                  <li className="active">
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-us-info-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="contact-us-info-box">
                <div className="contact-us-info-main">
                  <h4 className="contact-us-info-title">Get in touch</h4>
                  <p className="contact-us-info-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: generalInfo?.short_description,
                      }}
                    ></div>
                  </p>
                  <div className="contact-us-info-single">
                    <div className="contact-us-info-single-icon">
                      <i className="fi fi-rs-marker"></i>
                    </div>
                    <div className="contact-us-info-single-content">
                      {" "}
                      <h6 className="contact-us-info-single-title">
                        Office adress
                      </h6>
                      <p className="contact-us-info-single-text">
                        {generalInfo?.address?.length && generalInfo?.address}
                      </p>
                    </div>
                  </div>
                  <div className="contact-us-info-single">
                    <div className="contact-us-info-single-icon">
                      <i className="fi fi-rr-phone-call"></i>
                    </div>
                    <div className="contact-us-info-single-content">
                      {" "}
                      <h6 className="contact-us-info-single-title">
                        Phone number
                      </h6>
                      <ul className="contact-us-info-list">
                        <li>
                          <a
                            // href={`tel:${
                            //   generalInfo?.contact?.length &&
                            //   generalInfo?.contact
                            // }`}
                          >
                            {generalInfo?.contact?.length &&
                              generalInfo?.contact}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="contact-us-info-single">
                    <div className="contact-us-info-single-icon">
                      <i className="fi fi-rr-envelope-open"></i>
                    </div>
                    <div className="contact-us-info-single-content">
                      <h6 className="contact-us-info-single-title">
                        Email address
                      </h6>
                      <ul className="contact-us-info-list">
                        <li>
                          <a
                            href={`mailto:${
                              generalInfo?.email?.length && generalInfo?.email
                            }`}
                          >
                            {generalInfo?.email?.length && generalInfo?.email}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="contact-us-info-map">
                <div className="gmap_canvas">
                  <iframe
                    title="map"
                    width="434"
                    height="500"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58652.60185263579!2d69.63381478835316!3d23.250814410717105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e209000b6f17:0x7077f358af0774a6!2sBhuj, Gujarat!5e0!3m2!1sen!2sin!4v1614852897708!5m2!1sen!2sin"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-us-form-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-xl-6 offset-xl-3  col-12">
              <div className="contact-us-form-content">
                <div className="contact-us-form-cont-top">
                  <span className="contact-form-cont-sm-title">
                    Customer review!
                  </span>
                  <h4 className="contact-form-cont-big-title">
                    Have any compliment?
                  </h4>
                  <p className="contact-form-cont-text">
                    It is a long established fact that a reader will be
                    distracted by the of a page when looking at its layout.
                    content here making it look like readable English.
                  </p>
                </div>
                <div className="contact-form-main">
                  <form onSubmit={submit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Full name"
                            value={comments?.name}
                            onChange={(e) => {
                              setComents({ ...comments, name: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className=" col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={comments?.email}
                            onChange={(e) => {
                              setComents({
                                ...comments,
                                email: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone-number"
                            placeholder="Phone number"
                            value={comments?.phone}
                            onChange={(e) => {
                              setComents({
                                ...comments,
                                phone: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone-number"
                            placeholder="Company name"
                            value={comments?.company_name}
                            onChange={(e) => {
                              setComents({
                                ...comments,
                                company_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            placeholder="Your message"
                            value={comments?.message}
                            onChange={(e) => {
                              setComents({
                                ...comments,
                                message: e.target.value,
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="contact-form-button">
                          <button
                            type="submit"
                            className="theme-btn secondary-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;

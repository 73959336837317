import React from "react";
import { Link } from "react-router-dom";
import DeclinedIcon from "../Assets/img/payment-declined-img.svg";
const PaymentDeclined = () => {
  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link to="/checkout">Checkout</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link to="/checkout/orderconfirmations">Confirmation</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/paymentdeclined">Payment Declined</Link>
            </li>
          </ul>
        </div>
        <div className="thankyou-widget-area payment-declined-widget">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="thankyou-widget">
                  <img src={DeclinedIcon} alt="#" />
                  <div className="thankyou-content">
                    <h4 className="thankyou-content-title">
                      Your payment was declined!
                    </h4>
                    <p className="thankyou-content-text">
                      Your payment has been declined due to a technical issue.
                      Please try again to pay your payment.
                    </p>
                    <div className="thankyou-content-btn">
                      <Link to="/checkout/paymentinfo" className="theme-btn">
                        Please, pay again
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDeclined;

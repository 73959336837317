import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ThankYouIcon from "../Assets/img/thankyou-icon.svg";
import UserContext from "../Context/UserContext";
const ThankYou = () => {
  let navigate = useNavigate();

  const { paymentOrderInfo, setOrderId } = useContext(UserContext);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link to="/checkout">Checkout</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link to="/checkout/orderconfirmations">Confirmation</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/checkout/orderconfirmations/ThankYou">Thank you</Link>
            </li>
          </ul>
        </div>
        <div className="thankyou-widget-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="thankyou-widget">
                  <img src={ThankYouIcon} alt="#" />
                  <div className="thankyou-content">
                    <h4 className="thankyou-content-title">
                      Thanks for your purchase!
                    </h4>
                    <p className="thankyou-content-text">
                      Your order has been received and is now being processed.
                      It will be delivery within{" "}
                      {formatDate(paymentOrderInfo?.estimated_dd)}. Your order
                      id <b>#{paymentOrderInfo?.order_no}.</b>
                    </p>
                    <div className="thankyou-content-btn">
                      <Button
                        variant="link"
                        onClick={() => {
                          navigate("/checkout/OrderTracking");
                          setOrderId(paymentOrderInfo?.order_no);
                          localStorage.setItem(
                            "order_id",
                            JSON.stringify(paymentOrderInfo?.order_no)
                          );
                        }}
                        className="theme-btn"
                      >
                        Track your order
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;

import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CashOn from '../Assets/img/pay-with-options/cashon.svg'
import SSL from '../Assets/img/pay-with-options/ssl.png'
import Token from '../Config/Token'
import UserContext from '../Context/UserContext'
import { removeFromDb } from '../utilities/fakedb'

const PaymentInfo = ({ cart, setCart }) => {
	let navigate = useNavigate()
	const { shoppingOrderInfo, setPaymentOrderInfo } = useContext(UserContext)

	const { token } = Token()
	const [orderInfo, setOrderInfo] = useState(shoppingOrderInfo)

	const [activeItem, setActiveItem] = useState(0)

	const OrderSummeryMemo = [
		{
			id: 'ordermemo1',
			itemtitle: 'Subtotal:',
			itemprice: orderInfo.sub_total
		},
		{
			id: 'ordermemo2',
			itemtitle: 'Discount:',
			itemprice: orderInfo?.discount
		},
		{
			id: 'ordermemo3',
			itemtitle: 'Delivery cost:',
			itemprice: orderInfo?.delivery_fee
		},
		{
			id: 'ordermemo4',
			itemtitle: 'VAT/TAX',
			itemprice: orderInfo?.vat
		},
		{
			id: 'ordermemo5',
			itemtitle: 'Total amount:',
			itemprice: orderInfo?.total
		}
	]
	let newCart = []
	const handleDeleteItem = cartProduct => {
		const exists = cart?.find(product => product?.id === cartProduct?.id)
		if (exists) {
			const rest = cart?.filter(product => product?.id !== cartProduct?.id)
			newCart = [...rest]
			setCart(newCart)
			removeFromDb(cartProduct?.id)
		}
	}
	const cashOnDelivery = async () => {
		let formData = new FormData()
		formData.append('order_id', shoppingOrderInfo?.id)
		await axios
			.post(`${process.env.React_APP_API_URL}/order/payment/cod`, formData, {
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			})
			.then(res => {
				const success = res?.data?.success
				const message = res?.data?.message
				const completeOrderInfo = res?.data?.data
				localStorage.setItem('complete_order_info', JSON.stringify(completeOrderInfo))
				if (success) {
					toast.success(message)
					setPaymentOrderInfo(completeOrderInfo)

					navigate('/checkout/orderconfirmations')
				} else {
				}
			})
			.catch(err => {
				const errorMessage = err?.response?.data?.message
				// toast.error(errorMessage);
			})
	}
	const sslPayment = async () => {
		const orderId = shoppingOrderInfo?.id
		window.location.href = `${process.env.React_APP_BASE_URL}/sslcommerz/order/payment?order_id=${orderId}`
		localStorage.setItem('complete_order_info', JSON.stringify(shoppingOrderInfo))
	}
	const completeOrder = paymentMethodId => {
		if (paymentMethodId === 1) {
			sslPayment()
			localStorage.removeItem('all_cart_data')
		} else if (paymentMethodId === 2) {
			localStorage.removeItem('all_cart_data')
			cashOnDelivery()
		}
	}
	return (
		<div className='container'>
			<div className='pages'>
				<div className='payment-info-area' style={{ overflow: 'hidden' }}>
					<div className='row'>
						<div className='col-lg-10 offset-lg-1 col-12'>
							<div className='payment-info-inner'>
								<div className='payment-info-inner-main'>
									<h5 className='payment-info-inner-title'>Payment info</h5>
									<div className='row'>
										<div className='col-lg-12 col-xl-6 col-12'>
											<div className='p-info-inner-wrapper'>
												<h6 className='p-info-inner-wrapper-title'>
													Select payment method
												</h6>
												{/* <div className="form-input-wrapper">
                          <div className="form-input-wrapper-icon add-payment-card">
                            <Form.Control
                              type="text"
                              placeholder="Pay with card"
                              disabled
                            />
                            <div className="add-payment-card-list">
                              <img src={Paypal} alt="#" />
                              <img src={Visa} alt="#" />
                              <img src={Mastercard} alt="#" />
                              <img src={Amex} alt="#" />
                            </div>
                          </div>
                        </div>
                        <div className="form-input-wrapper">
                          <Form.Label className="form-label-text">
                            Card name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter full name"
                          />
                        </div>
                        <div className="form-input-wrapper">
                          <Form.Label className="form-label-text">
                            Card number
                          </Form.Label>
                          <div className="form-input-wrapper-icon">
                            <Form.Control
                              type="text"
                              placeholder="0000  0000  0000  0000"
                            />
                            <i className="fi-sr-credit-card"></i>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="form-input-wrapper">
                              <Form.Label className="form-label-text">
                                Expired date
                              </Form.Label>
                              <Form.Control type="text" placeholder="01/2025" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="form-input-wrapper">
                              <Form.Label className="form-label-text">
                                CVC code
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="e.x. 3500"
                              />
                            </div>
                          </div>
                        </div> */}
												{/* <PayWithList /> */}
												<div className='pay-with-options'>
													<div
														className={activeItem === 1 ? 'one' : 'two'}
														onClick={() => setActiveItem(1)}>
														<div className='pay-with-options-wrapper'>
															<img src={SSL} alt='#' />
															<p className='pay-with-options-title'>SSL Commerz</p>
														</div>
													</div>
													<div
														className={activeItem === 2 ? 'one' : 'two'}
														onClick={() => setActiveItem(2)}>
														<div className='pay-with-options-wrapper'>
															<img src={CashOn} alt='#' />
															<p className='pay-with-options-title'>
																Cash on Delivery
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-12 col-xl-5 offset-xl-1 col-12'>
											<div className='p-info-inner-wrapper'>
												<h6 className='p-info-inner-wrapper-title border-add'>
													Order Summary
													<span>
														({orderInfo?.ordered_items?.length} items in cart)
													</span>
												</h6>
												<div className='p-order-summery'>
													<div className='p-order-summery-list'>
														{orderInfo?.ordered_items?.map(OrderSumList => (
															<div key={OrderSumList?.id}>
																<div className='p-order-summery-wrapper'>
																	<div className='p-order-summery-main'>
																		{/* <button
                                      className="btn  border-0 "
                                      onClick={() =>
                                        handleDeleteItem(OrderSumList)
                                      }
                                    >
                                      <i
                                        className="fi-br-cross-small"
                                        style={{ color: "red" }}
                                      ></i>
                                    </button> */}
																		<div className='p-order-summery-details'>
																			<h6 className='p-order-summery-main-title'>
																				{OrderSumList?.product_name}
																			</h6>
																			<p className='p-order-summery-qty'>
																				Qty:
																				<span className='p-order-summery-qty-weight'>
																					{' ' + OrderSumList?.qty}
																				</span>
																			</p>
																		</div>
																	</div>
																	<div className='p-order-summery-price'>
																		<h5 className='p-order-summery-qty-price'>
																			{OrderSumList?.total_price}
																		</h5>
																	</div>
																</div>
															</div>
														))}
													</div>
													{/* <div className="order-summery-form">
                            <div className="form-group">
                              <label>Have a special code?</label>
                              <div className="order-summery-form-input">
                                <input type="apply" placeholder="NEWYR123" />
                                <button
                                  type="submit"
                                  className="button theme-btn btn btn-primary"
                                >
                                  Apply coupon
                                </button>
                              </div>
                              <p className="order-summery-form-text">
                                “NEWYR123” (-20% Off) coupon applied.
                              </p>
                            </div>
                          </div> */}
													<div className='order-summery-memo'>
														{OrderSummeryMemo.map(OrderMemoList => (
															<div key={OrderMemoList?.id}>
																<ul className='order-summery-memo-list'>
																	<li>
																		{OrderMemoList?.itemtitle}{' '}
																		<span className='order-s-memo-main'>
																			৳{OrderMemoList?.itemprice}
																		</span>
																	</li>
																</ul>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='shopping-info-accordian-bottom'>
									{/* <Link to="/" className="return-shop-btn">
                    <i className="fi-rr-arrow-left"></i>Return to shop
                  </Link> */}
									<button
										className='theme-btn'
										onClick={() => completeOrder(activeItem)}
										disabled={activeItem === 0 ? true : false}>
										Complete order<i className='fi-rr-arrow-right'></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PaymentInfo

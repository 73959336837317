import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import { addToDb } from "../utilities/fakedb";
import ModalGallerySlider from "./ModalGallerySlider";

const ProductDetailsModal = ({
    modalProduct,
    handleModal,
    show,
    location,
    change,
    quantity,
    increment,
    decrement,
    cart,
    setCart,
}) => {
    let navigate = useNavigate();
    const { id, name, img, subImgs, price, discount, weight, promo } = modalProduct;

    const handleClose = () => {
        change(location?.pathname);
    };
    const handleAddToCart = (selectedProduct) => {
        let newCart = [];

        const exists = cart?.find((product) => product?.id === selectedProduct?.id);
        //const rest = cart?.find((product) => product.id !== selectedProduct.id);

        if (!exists) {
            selectedProduct.quantity = quantity;
            newCart = [...cart, selectedProduct];
        } else {
            const rest = cart?.filter((product) => product?.id !== selectedProduct?.id);
            exists.quantity = quantity;
            newCart = [...rest, exists];
        }

        setCart(newCart);
        localStorage.setItem("all_cart_data", JSON.stringify(newCart));
        // addToDb(selectedProduct.id);
    };

    const handelBuyBtn = () => {
        handleAddToCart(modalProduct);
        navigate("checkout/shoppinginfo");
    };
    return (
        <>
            <Modal show={show} onHide={handleModal} className="p-product-details-modal">
                <Modal.Header closeButton onClick={handleClose}></Modal.Header>
                <Modal.Body>
                    <div className="p-details-modal-main">
                        <div className="p-details-modal-main-top">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="p-details-modal-gallery">
                                        <ModalGallerySlider subImgs={subImgs} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="p-details-modal-g-details">
                                        <h3 className="p-details-modal-d-title">{name}</h3>
                                        <span className="p-details-modal-d-weight">
                                            <b>Weight:</b> {weight}
                                        </span>
                                        <div className="p-details-modal-price">
                                            {discount <= 0 ? "" : <del> ৳{price}</del>}
                                            <span>
                                                ৳{Math.round(price * (100 - discount)) / 100}
                                                Taka
                                            </span>
                                            <p className="p-details-modal-price-disnt">({discount}% off)</p>
                                        </div>

                                        <div className="p-details-modal-buttons">
                                            <div className="p-details-modal-qty-btn">
                                                <button
                                                    className="qty-count qty-count--minus"
                                                    data-action="minus"
                                                    type="button"
                                                    onClick={decrement}
                                                >
                                                    <i className="fi-rr-minus-small"></i>
                                                </button>
                                                <input
                                                    className="product-qty"
                                                    type="number"
                                                    name="product-qty"
                                                    min="0"
                                                    max="10"
                                                    value={quantity}
                                                    disabled
                                                />
                                                <button
                                                    className="qty-count qty-count--add"
                                                    data-action="add"
                                                    type="button"
                                                    onClick={() => increment(modalProduct)}
                                                >
                                                    <i className="fi-rr-plus-small"></i>
                                                </button>
                                            </div>
                                            <div className="add-cart-btn">
                                                <Link to="/" className="theme-btn">
                                                    <i className="fi-rr-plus"></i>Add to cart
                                                </Link>
                                            </div>
                                            <div className="buy-cart-btn">
                                                <Button variant="link" className="theme-btn" onClick={handelBuyBtn}>
                                                    <i className="fi-rr-shopping-cart"></i>Buy now
                                                </Button>
                                            </div>
                                            <div className="add-wishlist">
                                                <Link to="/" className="theme-btn">
                                                    <i className="fi-rr-heart"></i>Add to wishlist
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-details-modal-bottom">
                            <div className="p-details-modal-des">
                                <h3 className="p-details-modal-des-title">Description</h3>
                                <p className="p-details-modal-des-text">
                                    Interdum accumsan nulla vitae scelerisque ut odio leo et. Vitae neque ullamcorper
                                    mauris viverra est. Egestas diam arcu, ornare arcu. Nulla at neque suscipit bibendum
                                    malesuada venenatis nibh. Congue varius duis rhoncus, non erat sed. Mi eu pharetra
                                    lacus felis, id. Ut sed at enim, at. Velit fermentum condimentum auctor integer nam.
                                    Consequat, risus imperdiet aliquam et et ornare. Tellus pretium lorem dictum turpis.
                                    Mauris, non pharetra ut ipsum vitae. Augue maecenas sit in sit tortor posuere velit
                                    faucibus.
                                </p>
                                <ul className="p-details-modal-des-list">
                                    <li>Magnis pulvinar quis ut nunc sed dictum sit.</li>
                                    <li>Pulvinar massa at orci, amet orci id velit luctus elementum.</li>
                                    <li>At sem sollicitudin laoreet sit elementum eget.</li>
                                    <li>Pulvinar massa at orci, amet orci id velit luctus elementum.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProductDetailsModal;

import axios from "axios";
import { useEffect, useState } from "react";

const useCategory = (categoryName, selectCategoryId, selectCategoryStage) => {
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [nextPageURL, setNextPageURL] = useState(null);
  const [prevPageURL, setPrevPageURL] = useState(null);

  const getCategoryProducts = async (type, id, pathURL) => {
    let formData = new FormData();
    formData.append(type, id);
    axios
      .post(pathURL, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const data = res?.data?.data?.data;
        if (success) {
          setCategoryProducts(data);
          setNextPageURL(res?.data?.data?.next_page_url);
          setPrevPageURL(res?.data?.data?.prev_page_url);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  const getNextProducts = async () => {
    let formData = new FormData();
    if (parseInt(selectCategoryStage) === 1) {
      formData.append("category_id", selectCategoryId);
    } else if (parseInt(selectCategoryStage) === 2) {
      formData.append("subcategory_id", selectCategoryId);
    } else if (parseInt(selectCategoryStage) === 3) {
      formData.append("childcategory_id", selectCategoryId);
    }

    axios
      .post(nextPageURL, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const data = res?.data?.data?.data;
        if (success) {
          const allProducts = [...categoryProducts, ...data];
          setCategoryProducts(allProducts);
          setNextPageURL(res?.data?.data?.next_page_url);
          setPrevPageURL(res?.data?.data?.prev_page_url);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  useEffect(() => {
    if (parseInt(selectCategoryStage) === 1) {
      getCategoryProducts(
        "category_id",
        selectCategoryId,
        `${process.env.React_APP_API_URL}/category/wise/products`
      );
    } else if (parseInt(selectCategoryStage) === 2) {
      getCategoryProducts(
        "subcategory_id",
        selectCategoryId,
        `${process.env.React_APP_API_URL}/subcategory/wise/products`
      );
    } else if (parseInt(selectCategoryStage) === 3) {
      getCategoryProducts(
        "childcategory_id",
        selectCategoryId,
        `${process.env.React_APP_API_URL}/childcategory/wise/products`
      );
    }
  }, [selectCategoryStage, selectCategoryId]);
  return {
    categoryProducts,
    setCategoryProducts,
    nextPageURL,
    getNextProducts,
  };
};

export default useCategory;

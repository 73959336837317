// import { Button, Offcanvas } from "bootstrap";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink } from "react-router-dom";
import ProfileImg from "../Assets/img/profile-img.svg";
const UserProfileSidebarResponsive = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  return (
    <>
      <button
        className="user-profile-sedebar-responsive-menu"
        type="button"
        onClick={handleShow}
      >
        <i className="fi fi-rr-apps"></i>
      </button>

      <Offcanvas
        // className="offcanvas offcanvas-end user-profile-sedebar-responsive"
        // tabindex="-1"
        show={showOffcanvas}
        onHide={handleClose}
        backdrop="static"
      >
        <Offcanvas.Body>
          <div className="user-profile-sedebar-responsive-header">
            <button type="button" onClick={handleClose}>
              <i className="fi fi-rr-cross"></i>
            </button>
          </div>
          <div className="user-profile-sedebar-responsive-body">
            <div className="user-profile">
              <div className="user-profile-top">
                <Link
                  to="/user/manageprofile"
                  onClick={() => setShowOffcanvas(!showOffcanvas)}
                  state={{
                    title: "Manage profile",
                    path: "/user/manageprofile",
                  }}
                  className="user-profile-edit"
                >
                  <i className="fi-rr-pencil"></i>Edit
                </Link>
                <div className="user-profile-img">
                  <img src={ProfileImg} alt="#" />
                </div>
                <div className="user-profile-content">
                  <h6 className="user-profile-title">Jahedul Islam</h6>
                  <p className="user-profile-text">+88012 3456 7890</p>
                </div>
              </div>
              <ul className="user-profile-menu">
                <li>
                  <NavLink
                    to="/user/dashboard"
                    state={{ title: "Dashboard", path: "/user/dashboard" }}
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                  >
                    <i className="fi-rr-home"></i>Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/purchasehistory"
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                    state={{
                      title: "Puchase history",
                      path: "/user/purchasehistory",
                    }}
                  >
                    <i className="fi-rr-document"></i>Purchase history
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/wishlist"
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                    state={{ title: "Wishlist", path: "/user/wishlist" }}
                  >
                    <i className="fi-rr-heart"></i>Wishlist
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/paymenthistroy"
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                    state={{
                      title: "Payment history",
                      path: "/user/paymenthistroy",
                    }}
                  >
                    <i className="fi fi-rr-time-past"></i>Payment history
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/supportticket"
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                    state={{
                      title: "Support ticket",
                      path: "/user/supportticket",
                    }}
                  >
                    <i className="fi-rr-headset"></i>Support ticket
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/user/manageprofile"
                    onClick={() => setShowOffcanvas(!showOffcanvas)}
                    state={{
                      title: "Manage profile",
                      path: "/user/manageprofile",
                    }}
                  >
                    <i className="fi-rr-settings"></i>Manage profile
                  </NavLink>
                </li>
              </ul>
              <div className="user-profile-signouy-btn">
                <Link to="/" className="theme-btn">
                  <i className="fi-rr-sign-out-alt"></i>Sign out
                </Link>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UserProfileSidebarResponsive;

import { useState } from "react";
import { Button } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import Token from "../Config/Token";

const Header = ({
  sidebar,
  setSidebar,
  cart,
  cartShow,
  setCartShow,
  generalInfo,
}) => {
  let navigate = useNavigate();
  const { user, clearUser } = Token();

  const showSidebar = () => setSidebar(!sidebar);
  const [searchText, setSearchText] = useState("");
  const applySearchText = (e) => {
    setSearchText(e.target.value);
    navigate(`/search/${e.target.value.toLowerCase()}`);
  };
  const toggleShow = () => setCartShow((s) => !s);
  const handelSearch = (e) => {
    e.preventDefault();
    navigate(`/search/${searchText}`);
    setSearchText("");
  };
  const handleSignOut = () => {
    clearUser();
    window.location.reload();
    // toast.success('Logout Successfully!');
    // localStorage.removeItem("accessToken");
  };
  return (
    <>
      <header className="header-area sticky">
        <div className="container">
          <div className="header-inner">
            <div className="row align-items-center g-0">
              <div className="col-12">
                <div className="header-inner-main">
                  <div className="header-logo-main">
                    <div
                      name="menu-outline"
                      className="sidebar-icon"
                      onClick={showSidebar}
                      id="hamburger-icon"
                    >
                      {sidebar ? (
                        <i className="fi-rr-menu-burger"></i>
                      ) : (
                        <i className="fi fi-rr-cross"></i>
                      )}
                    </div>
                    <div className="header-logo">
                      <Link to="/">
                        {generalInfo?.logo && (
                          <img
                            src={
                              process.env.React_APP_IMAGES_URL +
                              generalInfo?.logo
                            }
                            alt="#"
                          />
                        )}
                      </Link>
                    </div>
                  </div>
                  <div className="header-searchbar">
                    <form onSubmit={handelSearch}>
                      <input
                        type="text"
                        onChange={applySearchText}
                        value={searchText}
                        placeholder="Search Products"
                        required
                      />{" "}
                      <button className="search-btn" type="submit">
                        <i className="fi-rr-search"></i>
                      </button>
                    </form>
                  </div>
                  <div className="header-searchbar-top-right">
                    <div className="header-right-main">
                      <Button
                        variant="link"
                        onClick={toggleShow}
                        className="h-right-cart"
                      >
                        <span className="h-right-cart-item">{cart?.length}</span>
                        <i className="fi-rr-shopping-bag"></i>
                      </Button>
                      {user ? (
                        <div className="h-right-main-accounts">
                          <NavDropdown
                            title="My account"
                            id="basic-nav-dropdown"
                          >
                            <NavDropdown.Item
                              as={Link}
                              to="/user/dashboard"
                              state={{ title: "Dashboard" }}
                            >
                              <i className="fi-rr-user"></i>Your Profile
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/user/purchasehistory"
                              state={{ title: "Puchase history" }}
                            >
                              <i className="fi-rr-shopping-cart"></i>Your Orders
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/user/paymenthistroy"
                              state={{ title: "Payment history" }}
                            >
                              <i className="fi-rr-credit-card"></i>Payment
                              History
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/user/wishlist"
                              state={{ title: "Wishlist" }}
                            >
                              <i className="fi-rr-heart"></i>Wishlist
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="/user/manageprofile"
                            >
                              <i className="fi-rr-lock"></i>Change Password
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={handleSignOut}>
                              <i className="fi-rr-sign-out-alt"></i>Log Out
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            navigate("/login");
                          }}
                          className="login-btn"
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

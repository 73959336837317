import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Token from "../Config/Token";
import UserContext from "../Context/UserContext";

const LogInWithEmail = ({ cart }) => {
  const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const callbackURL = queryParams.get('callbackUrl')
  console.log("callbackURL",callbackURL)
  let navigate = useNavigate();
  const { setUser } = Token();
  const [passwordType, setPasswordType] = useState("password");
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  const [spinnerBtn, setSpinnerBtn] = useState(false);
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is mendatory")
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm(formOptions);
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("username", data?.email);
    formData.append("password", data?.password);
    await axios
      .post(`${process.env.React_APP_API_URL}/user/login`, formData, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const user = res?.data?.data;
        // console.log("error", res);
        if (success) {
          setSpinnerBtn(true);
          setUser(user);
          navigate(callbackURL || '/')
          window.location.reload();
          toast.success(message);
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/">Login</Link>
            </li>
          </ul>
        </div>
        <div className="login-page-area">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="login-card add-margin">
                  <div className="login-card-top">
                    <h4 className="login-card-top-title">Login</h4>
                    <p className="login-card-top-text">
                      Please login to your account
                    </p>
                  </div>
                  {/* <p className="form-label-text">Login with email or id</p> */}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="login-form-email"
                  >
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Email or id</p>
                      <input
                        type="email"
                        placeholder="Enter email"
                        className="input input-bordered w-full max-w-xs"
                        {...register("email")}
                      />
                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.email?.message}
                        </span>
                      </label>
                    </div>
                    <div className="form-control w-full max-w-xs">
                      <p className="form-title-label">Password</p>
                      <div className="pass-input-main">
                        <input
                          type={passwordType}
                          placeholder="Password"
                          className="input input-bordered w-full max-w-xs"
                          {...register("password")}
                        />
                        {passwordType === "password" ? (
                          <i
                            className="fi-sr-eye-crossed"
                            onClick={togglePassword}
                          ></i>
                        ) : (
                          <i className="fi-sr-eye" onClick={togglePassword}></i>
                        )}
                      </div>

                      <label className="label">
                        <span className="label-text-alt text-red-500">
                          {errors.password?.message}
                        </span>
                      </label>
                    </div>
                    {spinnerBtn ? (
                      <button
                        class="btn btn-accent w-full max-w-xs text-white"
                        type="button"
                      >
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    ) : (
                      <input
                        className="btn btn-accent w-full max-w-xs text-white"
                        type="submit"
                        value="Login"
                      />
                    )}
                  </form>
                  <p className="forgot-pass-text">
                    Forgotten password?{" "}
                    <Link
                      to="/resetpassword"
                      onClick={() =>
                        setSingUpInfo({ ...signUpInfo, type: "email" })
                      }
                    >
                      Reset password
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInWithEmail;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import profileImg from "../Assets/img/profile-img.svg";

const UserProfileSidebar = ({ personalInfo }) => {
  return (
    <>
      <div className="user-profile">
        <div className="user-profile-top">
          <Link
            to="manageprofile"
            state={{ title: "Manage profile", path: "/user/manageprofile" }}
            className="user-profile-edit"
          >
            <i className="fi-rr-pencil"></i>Edit
          </Link>
          <div className="user-profile-img">
            <img
              src={
                !personalInfo?.image 
                  ? profileImg
                  : process.env.React_APP_IMAGES_URL + personalInfo?.image
              }
              alt="#"
            />
          </div>
          <div className="user-profile-content">
            <h6 className="user-profile-title">{personalInfo?.name}</h6>
            <p className="user-profile-text">{personalInfo?.phone}</p>
          </div>
        </div>
        <ul className="user-profile-menu">
          <li>
            <NavLink
              to="/user/dashboard"
              state={{ title: "Dashboard", path: "/user/dashboard" }}
            >
              <i className="fi-rr-home"></i>Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/user/purchasehistory"
              state={{
                title: "Puchase history",
                path: "/user/purchasehistory",
              }}
            >
              <i className="fi-rr-document"></i>Purchase history
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/user/wishlist"
              state={{ title: "Wishlist", path: "/user/wishlist" }}
            >
              <i className="fi-rr-heart"></i>Wishlist
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/user/paymenthistroy"
              state={{
                title: "Payment history",
                path: "/user/paymenthistroy",
              }}
            >
              <i className="fi fi-rr-time-past"></i>Payment history
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/user/supportticket"
              state={{ title: "Support ticket", path: "/user/supportticket" }}
            >
              <i className="fi-rr-headset"></i>Support ticket
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/user/manageprofile"
              state={{ title: "Manage profile", path: "/user/manageprofile" }}
            >
              <i className="fi-rr-settings"></i>Manage profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/account/delete"
              state={{ title: "Delete Account", path: "/account/delete" }}
            >
              <i className="fi-sr-trash"></i>Delete Account
            </NavLink>
          </li>
        </ul>
        <div className="user-profile-signouy-btn">
          <Link to="/" className="theme-btn">
            <i className="fi-rr-sign-out-alt"></i>Sign out
          </Link>
        </div>
      </div>
    </>
  );
};

export default UserProfileSidebar;

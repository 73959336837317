import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Icon1 from "../Assets/img/order-cofirmation-icon/icon-1.svg";
import Icon2 from "../Assets/img/order-cofirmation-icon/icon-2.svg";
import Icon3 from "../Assets/img/order-cofirmation-icon/icon-3.svg";
import Icon4 from "../Assets/img/order-cofirmation-icon/icon-4.svg";
import CashOn from "../Assets/img/pay-with-options/cashon.svg";
import SSL from "../Assets/img/pay-with-options/ssl.png";
import Token from "../Config/Token";
import UserContext from "../Context/UserContext";

const OrderConfirmations = ({ loadOrders, setLoadOrders }) => {
  const { setPaymentOrderInfo } = useContext(UserContext);
  const [checkbox, setCheckbox] = useState(false);
  const { token } = Token();
  let orderFinalInfo = localStorage.getItem("complete_order_info") || null;
  orderFinalInfo = JSON.parse(orderFinalInfo);

  let navigate = useNavigate();
  const [completeOrderInfo, setCompleteOrderInfo] = useState({});
  const getSingleOrder = async () => {
    let formData = new FormData();
    formData.append("order_id", orderFinalInfo?.id);
    await axios
      .post(`${process.env.React_APP_API_URL}/order/preview`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const allOrdersData = res?.data?.data;
        let products = [];

        if (success) {
          // toast.success(message);
          setCompleteOrderInfo(allOrdersData);
          setPaymentOrderInfo(allOrdersData);
        } else {
          // toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  useEffect(() => {
    token && getSingleOrder();
  }, [token]);

  return (
    <div className="container">
      <div className="pages">
        <div className="order-confirmation-area" style={{ overflow: "hidden" }}>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="order-confirmation-main">
                <div className="order-confirmation-inner">
                  <div className="ord-confirmation-inner-top">
                    <h5 className="ord-confirmation-inner-top-title">
                      Order Confirmation{" "}
                      <span>#{completeOrderInfo?.order_no}</span>
                    </h5>
                    <h5 className="ord-confirmation-inner-top-title">
                      ৳{completeOrderInfo?.total} BDT
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-4 col-12">
                      <div className="order-confirm-wrapper">
                        <div className="order-confirm-wrapper-top">
                          <div className="order-confirm-top-left">
                            <img src={Icon1} alt="#" />
                            <h5 className="order-confirm-widget-title">
                              Your information
                            </h5>
                          </div>
                          {/* <EditOptionsModal3 /> */}
                        </div>
                        <ul className="order-confirm-details-list">
                          <li>{completeOrderInfo?.shipping_info?.full_name}</li>
                          <li>{completeOrderInfo?.shipping_info?.email}</li>
                          <li>{completeOrderInfo?.shipping_info?.phone}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-4 offset-xl-4 col-12">
                      <div className="order-confirm-wrapper">
                        <div className="order-confirm-wrapper-top">
                          <div className="order-confirm-top-left">
                            <img src={Icon2} alt="#" />
                            <h5 className="order-confirm-widget-title">
                              Shipping address
                            </h5>
                          </div>
                          {/* <EditOptionsModal /> */}
                        </div>
                        <ul className="order-confirm-details-list">
                          <li>{completeOrderInfo?.shipping_info?.address}</li>
                          <li>{completeOrderInfo?.shipping_info?.city}</li>
                          <li>{completeOrderInfo?.shipping_info?.country}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-4  col-12">
                      <div className="order-confirm-wrapper order-confirm-visacard mg-top-40">
                        <div className="order-confirm-wrapper-top">
                          <div className="order-confirm-top-left">
                            <img src={Icon3} alt="#" />
                            <h5 className="order-confirm-widget-title">
                              Payment method
                            </h5>
                          </div>
                          {/* <Link to="/checkout/paymentinfo">
                            <i className="fi-rr-pencil"></i>
                          </Link> */}
                        </div>
                        <ul className="order-confirm-details-list">
                          <li>
                            {completeOrderInfo?.payment_method !== null && (
                              <img
                                src={
                                  completeOrderInfo?.payment_method_text ===
                                  "COD"
                                    ? CashOn
                                    : SSL
                                }
                                alt="#"
                              />
                            )}
                          </li>
                          {completeOrderInfo?.payment_method !== null && (
                            <li>
                              {completeOrderInfo?.payment_method_text === "COD"
                                ? "Cash on Delivery"
                                : "SSL Commerz"}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-4 offset-xl-4  col-12">
                      <div className="order-confirm-wrapper mg-top-40">
                        <div className="order-confirm-wrapper-top">
                          <div className="order-confirm-top-left">
                            <img src={Icon4} alt="#" />
                            <h5 className="order-confirm-widget-title">
                              Billing address
                            </h5>
                          </div>
                          {/* <EditOptionsModal2 /> */}
                        </div>
                        <ul className="order-confirm-details-list">
                          <li>{completeOrderInfo?.billing_address?.address}</li>
                          <li>{completeOrderInfo?.billing_address?.city}</li>
                          <li>{completeOrderInfo?.billing_address?.country}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-confirm-main-bottom">
                  <div className="row">
                    <div className="col-lg-8 col-xl-6 col-md-8 col-12">
                      <div className="order-confirm-main-bottom-text">
                        <div className="form-input-checkbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div
                                key={`default-${type}`}
                                onClick={() => setCheckbox(!checkbox)}
                              >
                                <Form.Check
                                  type={type}
                                  id={`default-${type}`}
                                  style={{ height: 0, background: "red" }}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                        <p className="order-confirm-terms-text">
                          By completing the order you agree to our{" "}
                          <Link to="/terms&conditions" target="_blank">
                            Terms and Conditions,
                          </Link>{" "}
                          <Link to="/return&refund-policy" target="_blank">
                          Return and Refund Policy,
                          </Link>{" "}
                          &{" "}
                          <Link to="/privacy-policy" target="_blank">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-6 col-md-4 col-12">
                      <div className="place-order-btn">
                        <Button
                          variant="link"
                          onClick={() => {
                            setLoadOrders(!loadOrders);
                            localStorage.removeItem("complete_order_info");
                            navigate("/checkout/orderconfirmations/ThankYou");
                          }}
                          disabled={checkbox ? false : true}
                          className="theme-btn"
                        >
                          Place order<i className="fi-rr-arrow-right"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmations;

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Token from "../Config/Token";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user, clearUser, token } = Token();
  
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
//   if (!token) {
//     clearUser();
//   }
  return children;
};

export default RequireAuth;

import { useContext, useState } from "react";
import UserContext from "../Context/UserContext";
import SidebarItem from "./SidebarItem";

const SideBar = ({ sidebar, setSidebar }) => {
  const {sideBarData} = useContext(UserContext);
  const [activeItem, setActiveItem] = useState("");


  return (
    <>
      <div id="active-sticky-2" className="sodainagar-sidemenu">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {" "}
              <div
                className={sidebar ? "sidebar toggle" : "sidebar"}
                id="sidebars"
              >
                {sideBarData.map((item, index) => (
                  <SidebarItem
                    key={index}
                    index={index}
                    item={item}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    setSidebar={setSidebar}
                    sidebar={sidebar}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Token from "../Config/Token";
import UserContext from "../Context/UserContext";

const ShoppingInfo = ({ personalInfo }) => {
    const initialShippingValue = {
        order_id: "",
        full_name: "",
        phone: "",
        email: "",
        gender: "",
        address: "",
        post_code: "",
        city: "",
        country: "",
        billing_address: "",
        billing_post_code: "",
        billing_city: "",
        billing_country: "",
    };
    let navigate = useNavigate();
    const { checkoutOrderInfo, setCheckoutOrderInfo, setShoppingOrderInfo } = useContext(UserContext);
    const { user, token } = Token();
    const [sameAddress, setSameAddress] = useState(false);
    const [billingAddress, setBillingAddress] = useState();
    const [addressInfo, setAddressInfo] = useState(initialShippingValue);
    const [errors, setErrors] = useState(initialShippingValue);
    useEffect(() => {
        let orderInfo = localStorage.getItem("order_detail") || null;
        orderInfo = JSON.parse(orderInfo);
        setCheckoutOrderInfo(checkoutOrderInfo !== null ? checkoutOrderInfo : orderInfo);
    }, []);
    const getAddress = async () => {
        axios
            .get(`${process.env.React_APP_API_URL}/get/all/address`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            // .then((res) => setAllAddress(res?.data?.date))
            .then((res) => {
                let data = res?.data?.date;
                if (data?.length) {
                    let shipping_address;
                    let billing_address;
                    data?.map((each) => {
                        if (each?.address_type && each?.address_type === "Shipping Address") {
                            shipping_address = each;
                        } else {
                            billing_address = each;
                        }
                        return null; // Add a return statement to satisfy the eslint rule
                    });
                    setBillingAddress(billing_address);
                    setAddressInfo({
                        ...addressInfo,
                        order_id: checkoutOrderInfo?.id,
                        email: user?.email,
                        full_name: shipping_address?.name,
                        phone: shipping_address?.phone,
                        address: shipping_address?.address,
                        country: shipping_address?.country,
                        city: shipping_address?.city,
                        post_code: shipping_address?.post_code,
                        billing_address: billing_address?.address,
                        billing_country: billing_address?.country,
                        billing_city: billing_address?.city,
                        billing_post_code: billing_address?.post_code,
                    });
                } else {
                    console.log("personalInfo", personalInfo);
                    setAddressInfo({
                        ...addressInfo,
                        email: personalInfo?.email,
                        full_name: personalInfo?.name,
                        phone: personalInfo?.phone,
                        address: personalInfo?.address,
                        country: "Bangladesh",
                    });
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                // toast.error(errorMessage);
            });
    };
    useEffect(() => {
        token && getAddress();
    }, [token, personalInfo]);
    useEffect(() => {
        setAddressInfo({
            ...addressInfo,
            billing_address: sameAddress ? addressInfo?.address : billingAddress?.address,
            billing_country: sameAddress ? addressInfo?.country : billingAddress?.country,
            billing_city: sameAddress ? addressInfo?.city : billingAddress?.city,
            billing_post_code: sameAddress ? addressInfo?.post_code : billingAddress?.post_code,
        });
    }, [sameAddress]);

    const submit = async (e) => {
        e.preventDefault();

        if (!addressInfo?.full_name) {
            setErrors({ ...errors, full_name: "Name is required" });
        } else if (!addressInfo?.address) {
            setErrors({ ...errors, address: "Address is required" });
        } else if (!addressInfo?.city) {
            setErrors({ ...errors, city: "City is required" });
        } else if (!addressInfo?.billing_address) {
            setErrors({ ...errors, billing_address: "Billing Address is required" });
        } else if (!addressInfo?.billing_country) {
            setErrors({ ...errors, billing_country: "Billing Country is required" });
        } else if (!addressInfo?.billing_city) {
            setErrors({ ...errors, billing_city: "Billing City is required" });
        } else {
            let route = "";
            // editID ? (route = "update_address") : (route = "add_address");
            // add_address(route);
            setErrors(initialShippingValue);
            updateOrderInfo();
        }

        // add customer data layer when customer submit data
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "checkout",
            ecommerce: {
                checkout: {
                    actionField: { step: 1, option: "Shipping info" },
                    products: [
                        {
                            name: "Tribit FlyBuds NC TWS Earbuds - Black",
                            id: "tribit-flybuds-nc-tws-earbuds-black",
                            price: "69.99",
                            brand: "Tribit",
                            category: "Electronics",
                            variant: "Black",
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    };
    const updateOrderInfo = async () => {
        // onSubmit();
        // navigate("/checkout/paymentinfo");
        let formData = new FormData();
        formData.append("order_id", checkoutOrderInfo?.id);
        formData.append("full_name", addressInfo?.full_name);
        formData.append("phone", addressInfo?.phone);
        formData.append("email", addressInfo?.email);
        formData.append("gender", addressInfo?.gender);
        formData.append("address", addressInfo?.address);
        formData.append("post_code", addressInfo?.post_code);
        formData.append("city", addressInfo?.city);
        formData.append("country", addressInfo?.country);
        formData.append("billing_address", addressInfo?.billing_address);
        formData.append("billing_post_code", addressInfo?.billing_post_code);
        formData.append("billing_city", addressInfo?.billing_city);
        formData.append("billing_country", addressInfo?.billing_country);

        await axios
            .post(`${process.env.React_APP_API_URL}/submit/shipping/billing/info`, formData, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const success = res?.data?.success;
                const message = res?.data?.message;
                const orderInfoData = res?.data?.data;
                if (success) {
                    // toast.success(message);
                    setShoppingOrderInfo(orderInfoData);
                    navigate("/checkout/paymentinfo", {
                        state: {
                            orderInfo: orderInfoData,
                        },
                    });
                } else {
                    // toast.error(message);
                    toast.error("Request failed");
                }
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message;
                toast.error(errorMessage);
                if (errorMessage === "Unauthenticated.") {
                    navigate("/login");
                }
            });
    };

    // console.log(location?.state?.orderInfo?.id, checkoutOrderInfo);
    // console.log("checkoutOrderInfo", checkoutOrderInfo);
    // console.log("addressInfo", addressInfo);

    return (
        <div className="container">
            <div className="pages">
                <div className="shopping-info-area" style={{ overflow: "hidden" }}>
                    <div className="row">
                        <div className="col-lg-12 col-xl-10 offset-xl-1 col-12">
                            <Form onSubmit={submit} className="shopping-info-main-form">
                                <div className="shopping-info-accordian">
                                    <div>
                                        <div className="shopping-info-widget">
                                            <h3 className="shopping-info-widget-title ">Shipping info</h3>
                                            <div className="shopping-info-widget-body">
                                                <div className="shopping-info-accordian-form">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Full name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter full name"
                                                                    value={
                                                                        addressInfo?.full_name
                                                                            ? addressInfo?.full_name
                                                                            : personalInfo?.name
                                                                    }
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            full_name: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.full_name && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.full_name}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <div className="form-control w-full max-w-xs">
                                                                    <Form.Label className="form-label-text">
                                                                        Email address
                                                                    </Form.Label>
                                                                    <input
                                                                        type="email"
                                                                        placeholder="Enter email or id"
                                                                        value={
                                                                            addressInfo?.email
                                                                                ? addressInfo?.email
                                                                                : personalInfo?.email
                                                                        }
                                                                        className="input input-bordered w-full max-w-xs"
                                                                        onChange={(e) =>
                                                                            setAddressInfo({
                                                                                ...addressInfo,
                                                                                email: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Address
                                                                </Form.Label>
                                                                <div className="form-input-wrapper-icon">
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={
                                                                            addressInfo?.address
                                                                                ? addressInfo?.address
                                                                                : personalInfo?.address
                                                                        }
                                                                        placeholder="Cumilla Town Hall, Cumilla"
                                                                        onChange={(e) =>
                                                                            setAddressInfo({
                                                                                ...addressInfo,
                                                                                address: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                    <i className="fi-sr-marker"></i>
                                                                </div>
                                                                {errors?.address && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.address?.message}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    City
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter City name"
                                                                    value={addressInfo?.city}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            city: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.city && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.city}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                                <p style={{ fontSize: "14px" }}>
                                                                    Delivery Charge [City: "Homna" (30tk); City: Others
                                                                    (60tk)]
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Phone number
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="+880123 4567 890"
                                                                    value={
                                                                        addressInfo?.phone
                                                                            ? addressInfo?.phone
                                                                            : personalInfo?.phone
                                                                    }
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            phone: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.phone && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.phone}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Gender
                                                                </Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    value={addressInfo?.gender}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            gender: e.target.value,
                                                                        })
                                                                    }
                                                                >
                                                                    <option>Select Gender</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                </Form.Select>
                                                                {errors?.gender && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.gender}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Country
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter country name"
                                                                    value={
                                                                        addressInfo?.country
                                                                            ? addressInfo?.country
                                                                            : "Bangladesh"
                                                                    }
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            country: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.country && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.country}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Postal code
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="e.x. 3500"
                                                                    value={addressInfo?.post_code}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            post_code: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.postCode && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.postCode}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shopping-info-widget">
                                            <h3 className="shopping-info-widget-title border-none">Billing address</h3>
                                            <div className="shopping-info-widget-body">
                                                <div className="shopping-info-accordian-form">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div className="form-input-checkbox">
                                                                <Form>
                                                                    {["checkbox"].map((type) => (
                                                                        <div key={`default-${type}`}>
                                                                            <Form.Check
                                                                                type={type}
                                                                                onClick={() =>
                                                                                    setSameAddress(!sameAddress)
                                                                                }
                                                                                id={`default-${type}`}
                                                                                label={`Same as shipping address`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Address line
                                                                </Form.Label>
                                                                <div className="form-input-wrapper-icon">
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Address here"
                                                                        value={
                                                                            sameAddress
                                                                                ? addressInfo?.address
                                                                                : addressInfo?.billing_address
                                                                        }
                                                                        onChange={(e) =>
                                                                            setAddressInfo({
                                                                                ...addressInfo,
                                                                                billing_address: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                    <i className="fi-sr-marker"></i>
                                                                </div>
                                                                {errors?.billing_address && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.billing_address}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    City
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter City name"
                                                                    value={addressInfo?.billing_city}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            billing_city: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.billing_city && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.billing_city}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div
                                                                className="form-input-wrapper "
                                                                style={{ marginTop: "30px" }}
                                                            >
                                                                <Form.Label className="form-label-text">
                                                                    Country
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Country name"
                                                                    value={addressInfo?.billing_country}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            billing_country: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.billing_country && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.billing_country}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                            <div className="form-input-wrapper">
                                                                <Form.Label className="form-label-text">
                                                                    Postal code
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="e.x. 3500"
                                                                    value={addressInfo?.billing_post_code}
                                                                    onChange={(e) =>
                                                                        setAddressInfo({
                                                                            ...addressInfo,
                                                                            billing_post_code: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {errors?.billing_post_code && (
                                                                    <label className="label">
                                                                        <span className="label-text-alt text-red-500">
                                                                            {errors?.billing_post_code}
                                                                        </span>
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shopping-info-accordian-bottom">
                                    {/* <Link to="/" className="return-shop-btn">
                    <i className="fi-rr-arrow-left"></i>Return to shop
                  </Link> */}
                                    <div className="continue-payment-btn" onClick={submit}>
                                        <input type="submit" value="Continue to payment" />
                                        <i className="fi-rr-arrow-right"></i>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShoppingInfo;

import axios from 'axios'
import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Token from '../Config/Token'
import UserContext from '../Context/UserContext'
import useCoupon from '../hooks/useCoupon'
import CartSidebarItems from './CartSidebarItems'

const options = [
	{
		name: '12 items',
		scroll: true,
		backdrop: true
	}
]

function CartSidebar({ cart, setCart, setOrderInfo, name, cartShow, setCartShow, ...props }) {
	let navigate = useNavigate()
	const { user, token } = Token()
	const { setCheckoutOrderInfo } = useContext(UserContext)
	const handleClose = () => setCartShow(false)
	const toggleShow = () => setCartShow(s => !s)
	const [
		subTotal,
		totalAmount,
		couponText,
		// couponBtn,
		couponMess,
		discount,
		showDiscount,
		couponMessText,
		applyCoupon,
		handleDiscount,
		incrementHandle,
		setIncrementHandle,
		productIds,
		productQTYs,
		productUnitIds,
		productUnitPrice,
		percentage
	] = useCoupon({ cart })
	// console.log(productIds);

	const handleOrder = async () => {
		let formData = new FormData()
		formData.append('product_id[]', productIds)
		formData.append('qty[]', productQTYs)
		formData.append('unit_id[]', productUnitIds)
		formData.append('unit_price[]', productUnitPrice)
		formData.append('coupon_code', couponText)
		// console.log(formData.entries())
		// Display the key/value pairs
		// for (var pair of formData.entries()) {
		//   console.log(pair[0] + ", " + pair[1]);
		// }
		const cartInfo = {
			'product_id[]': productIds,
			'qty[]': productQTYs,
			'unit_id[]': productUnitIds,
			'unit_price[]': productUnitPrice,
			'coupon_code': couponText
		}
		// console.log(cartInfo)
		await axios
			.post(`${process.env.React_APP_API_URL}/order/checkout`, formData, {
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			})
			.then(res => {
				const success = res?.data?.success
				const message = res?.data?.message
				// console.log(typeof (JSON.parse(res?.data?.data[0])))
				// console.log(res?.data?.data[0]);
				if (success) {
					// toast.success(message);
					setCart([])
					localStorage.removeItem('all_cart_data')
					setCartShow(false)
					setCheckoutOrderInfo(res?.data?.data)
					localStorage.setItem('order_detail', JSON.stringify(res?.data?.data))
					navigate('/checkout/shoppinginfo', {
						state: {
							orderInfo: res?.data?.data
						}
					})
				} else {
					toast.error(message)
				}
			})
			.catch(err => {
				const errorMessage = err?.response?.data?.message
				toast.error(errorMessage)
				if (errorMessage === 'Unauthenticated.') {
					setCartShow(false)
					navigate('/login?callbackUrl=/checkout/shoppinginfo')
				}
			})
		// const order = {
		//   coupon: couponText,
		//   subTotal: subTotal,
		//   discount: discount,
		// };
		// setOrderInfo(order);
		// setCartShow(false);
		// navigate("/checkout/shoppinginfo");
	}

	return (
		<>
			<Button className='cart-active' variant='primary' onClick={toggleShow}>
				<i className='fi-rr-shopping-bag'></i>
				<p className='cart-active-text'>{cart?.length} items</p>
			</Button>
			<Offcanvas show={cartShow} onHide={handleClose} {...props} placement='end'>
				<Offcanvas.Header>
					<Offcanvas.Title>
						<div className='cart-modal-header'>
							<h5 className='cart-modal-header-title'>
								Cart <span>( {cart?.length} items)</span>
							</h5>
							<Button variant='secondary' onClick={handleClose}>
								Close
							</Button>
						</div>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className='cart-sidebar-item-main'>
						<CartSidebarItems
							carts={cart}
							setCart={setCart}
							setCartShow={setCartShow}
							incrementHandle={incrementHandle}
							setIncrementHandle={setIncrementHandle}
						/>
					</div>
					<div className='cart-sidebar-bottom'>
						<div className='s-cart-bottom-content'>
							<div className='cart-coupon-form'>
								<div className='form-group'>
									<label>Have a special code?</label>
									<div className='cart-coupon-form-input'>
										<input
											type='apply'
											onChange={applyCoupon}
											placeholder='Apply coupon'
										/>
										<Button
											// disabled={couponBtn ? false : true}
											className='button theme-btn'
											type='submit'
											onClick={handleDiscount}>
											Apply coupon
										</Button>
									</div>
								</div>
								<p className={couponMess ? 'coupon-applied-text' : 'coupon-applied-text2'}>
									“{couponText}” {couponMessText}
								</p>
								<div className='coupon-applied-price'>
									<h4 className='coupon-applied-price-title'>
										৳{totalAmount} BDT{' '}
										<del style={{ display: showDiscount ? '' : 'none' }}>
											৳{subTotal}(-{percentage}%)
										</del>
									</h4>
								</div>

								<div className='sidebar-cart-b-btn' onClick={() => handleOrder()}>
									<Button className='theme-btn' type='submit'>
										Go To Checkout
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}

function Example({ cart, setCart, setOrderInfo, cartShow, setCartShow }) {
	return (
		<>
			{options.map((props, idx) => (
				<CartSidebar
					key={idx}
					{...props}
					cart={cart}
					setCart={setCart}
					setOrderInfo={setOrderInfo}
					cartShow={cartShow}
					setCartShow={setCartShow}
				/>
			))}
		</>
	)
}

export default Example

import React from "react";
import { Link } from "react-router-dom";
import CheckIcon from "../Assets/img/login-with-icon/check-icon.svg";

const SetPasswordSuccess = () => {
  return (
    <>
      <div className="p-category-top-menu mt-12">
        <ul className="p-category-top-menu-list m-0">
          <li>
            <Link to="/">Home</Link>
            <i className="fi-rr-angle-small-right"></i>
          </li>
          <li>
            <Link to="/loginwithemail">Login</Link>
            <i className="fi-rr-angle-small-right"></i>
          </li>
          <li className="active">
            <Link to="/login">Reset password</Link>
          </li>
        </ul>
      </div>
      <div className="reset-password-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="reset-password-card">
                <div className="reset-password-top">
                  <img src={CheckIcon} alt="#" />
                  <h4 className="reset-password-top-title">Success!</h4>
                  <p className="reset-password-top-text">
                    Your account password has been reset. Please login to
                    account.
                  </p>
                </div>
                <div className="back-login-btn">
                  <Link to="/" className="theme-btn">
                    <i className="fi-rr-sign-in"></i>Login to account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPasswordSuccess;

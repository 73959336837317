// import React from "react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import aboutUs from "../Assets/img/about_us.png";
import BreadcrumbsBg from "../Assets/img/about_us_breadcrumbs.png";

const About = () => {
  const [aboutInfo, setAboutInfo] = useState({});
  const getGeneraDetails = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/about/us`, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => setAboutInfo(res?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  useEffect(() => {
    getGeneraDetails();
  }, []);
  return (
    <>
      <section
        className="sodainagar-breadcrumbs"
        style={{ backgroundImage: `url(${BreadcrumbsBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
              <div className="sodainagar-breadcrumbs-content">
                <h3 className="sodainagar-bread-cont-title">About Us</h3>
                <ul className="sodainagar-bread-menu">
                  <li>
                    <Link to="/">Home</Link>
                    <i className="fi fi-br-angle-right"></i>
                  </li>
                  <li className="active">
                    <Link to="/about">About</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-content sf-space offwhite-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="about-content__single">
                <h3 className="about-content__title">Sodai Nagar</h3>
                <p>
                  <div
                    dangerouslySetInnerHTML={{ __html: aboutInfo?.about_us }}
                  ></div>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content__img">
                <img src={aboutUs} alt="#" />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6 col-12">
              <div className="about-content__single sf-mtop-30">
                <h3 className="about-content__title">Mission</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi
                  quidem optio, expedita, provident recusandae veniam natus
                  laudantium dicta sed adipisci cumque itaque quo corrupti eum
                  labore asperiores odio dolor eaque dolorum, enim hic ipsam?
                  Amet saepe repellendus consectetur facilis doloremque sunt
                  sapiente id eveniet, quod tempore placeat. Quia neque pariatur
                  ab earum, repellendus iusto aperiam doloribus dolorem?
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content__single sf-mtop-30">
                <h3 className="about-content__title">Vision</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi
                  quidem optio, expedita, provident recusandae veniam natus
                  laudantium dicta sed adipisci cumque itaque quo corrupti eum
                  labore asperiores odio dolor eaque dolorum, enim hic ipsam?
                  Amet saepe repellendus consectetur facilis doloremque sunt
                  sapiente id eveniet, quod tempore placeat. Quia neque pariatur
                  ab earum, repellendus iusto aperiam doloribus dolorem?
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default About;

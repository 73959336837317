import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AppleStoreImg from '../Assets/img/download-app/apple-store.svg'
import GooglePlayImg from '../Assets/img/download-app/google-play.svg'
import Amex from '../Assets/img/payment-currency/amex.svg'
import Bkash from '../Assets/img/payment-currency/bkash.svg'
import Mastercard from '../Assets/img/payment-currency/mastercard.svg'
import Nagad from '../Assets/img/payment-currency/nagad.svg'
import Payoneer from '../Assets/img/payment-currency/payoneer.svg'
import Paypal from '../Assets/img/payment-currency/paypal.svg'
import Rocket from '../Assets/img/payment-currency/rocket.svg'
import Upay from '../Assets/img/payment-currency/upay.svg'
import Visa from '../Assets/img/payment-currency/visa.svg'
import FaceBook from '../Assets/img/social-icon/facebook.svg'
import Instagram from '../Assets/img/social-icon/instagram.svg'
import twitter from '../Assets/img/social-icon/twitter.svg'
import YouTube from '../Assets/img/social-icon/youtube.svg'
import { toast } from 'react-toastify'
import SSLImg from '../Assets/img/SSLCommerz-footer.png'

const Footer = ({ generalInfo }) => {
	const [subscribeEmail, setSubscribeEmail] = useState('')
	const [linkMobileNumber, setLinkMobileNumber] = useState('')

	const handleSubscribe = async e => {
		e.preventDefault()
		let formData = new FormData()
		formData.append('email', subscribeEmail)
		await axios
			.post(`${process.env.React_APP_API_URL}/subscribe/for/updates`, formData, {
				headers: {
					'Content-type': 'application/json',
					'Authorization': process.env.React_APP_DEFAULT_AUTHORIZATION
				}
			})
			.then(res => {
				const success = res?.data?.success
				const message = res?.data?.message
				if (success === true) {
					setSubscribeEmail('')
					toast.success(message)
				} else {
				}
			})
			.catch(err => {
				const errorMessage = err?.response?.data?.message
				toast.error(errorMessage)
			})
	}
	const handleLink = async e => {
		e.preventDefault()
		let formData = new FormData()
		formData.append('phone', linkMobileNumber)
		await axios
			.post(`${process.env.React_APP_API_URL}/get/link/via/sms`, formData, {
				headers: {
					'Content-type': 'application/json',
					'Authorization': process.env.React_APP_DEFAULT_AUTHORIZATION
				}
			})
			.then(res => {
				const success = res?.data?.success
				const message = res?.data?.message
				if (success === true) {
					setLinkMobileNumber('')
					toast.success(message)
				} else {
				}
			})
			.catch(err => {
				const errorMessage = err?.response?.data?.message
				toast.error(errorMessage)
			})
	}
	return (
		<>
			<footer className='footer-area'>
				<div className='footer-top'>
					<div className='container'>
						<div className='row g-0'>
							<div className='col-lg-6 col-xl-4 col-12'>
								<div className='footer-widget footer-about'>
									<div className='footer-logo'>
										<Link to='index.html'>
											{generalInfo?.logo && (
												<img
													src={process.env.React_APP_IMAGES_URL + generalInfo?.logo}
													alt='#'
												/>
											)}
										</Link>
									</div>
									<p className='f-about-para'>
										<div
											dangerouslySetInnerHTML={{
												__html: generalInfo?.about_us
											}}></div>{' '}
									</p>
									<Link to='/about' className='f-about-para-a'>
										Read More
									</Link>
								</div>
								<ul className='mt-3'>
									<li>
										TIN : <b>673266555252</b>
									</li>
									<li>
										Trade License no : <b>01288</b>
									</li>
								</ul>
							</div>
							<div className='col-lg-6 col-xl-4 col-12'>
								<div className='footer-widget f-download-app'>
									<h4 className='footer-widget-title'>Download the mobile app</h4>
									<div className='f-download-btn'>
										<a
											href='https://play.google.com/store/apps/details?id=com.onatcipli.com.example.sodainagar_app'
											target='_blank'
											className='google-play-btn'
											rel='noreferrer'>
											<img src={GooglePlayImg} alt='#' />
										</a>

										<a
											href='#'
											target='_blank'
											className='apple-store-btn'
											rel='noreferrer'>
											<img src={AppleStoreImg} alt='#' />
										</a>
									</div>
									<div className='footer-download-form'>
										<p className='f-subs-download-content'>
											Receive a download link via SMS
										</p>
										<form onSubmit={handleLink} className='f-download-form-main'>
											<input
												type='text'
												value={linkMobileNumber}
												placeholder='Enter Mobile Number'
												onChange={e => setLinkMobileNumber(e.target.value)}
												required
											/>
											<button className='button' type='submit'>
												Get link
											</button>
										</form>
									</div>
								</div>
							</div>
							<div className='col-lg-4 col-12'>
								<div className='footer-widget footer-stay-update'>
									<h4 className='footer-widget-title'>Stay up to date!</h4>
									<div className='footer-stay-update-form'>
										<p className='f-stay-update-content'>
											Don't worry, we won't spam you.
										</p>
										<form onSubmit={handleSubscribe} className='f-update-form-main'>
											<input
												type='email'
												placeholder='Enter Email Address'
												value={subscribeEmail}
												onChange={e => setSubscribeEmail(e.target.value)}
												required
											/>
											<button className='button' type='submit'>
												Subscribe
											</button>
										</form>
									</div>
									<h4 className='footer-widget-title mt-3 mb-1'>Delivery Time</h4>
									<ul>
										<li>
											Inside Dhaka - <b>5 days</b>
										</li>
										<li>
											Outside Dhaka - <b>10 days</b>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className='row g-0'>
							<div className='col-12'>
								<div className='footer-address-main'>
									<div className='row'>
										<div className='col-lg-6 col-xl-3 col-md-6 col-12'>
											<div className='footer-address-wrapper'>
												<div className='f-about-social'>
													<ul className='f-about-social-list'>
														<li>
															<a
																href={generalInfo?.facebook}
																target='_blank'
																rel='noreferrer'>
																<img src={FaceBook} alt='#' />
															</a>
														</li>
														<li>
															<a
																href={generalInfo?.twitter}
																target='_blank'
																rel='noreferrer'>
																<img src={twitter} alt='#' />
															</a>
														</li>
														<li>
															<a
																href={generalInfo?.instagram}
																target='_blank'
																rel='noreferrer'>
																<img src={Instagram} alt='#' />
															</a>
														</li>
														<li>
															<a
																href={generalInfo?.youtube}
																target='_blank'
																rel='noreferrer'>
																<img src={YouTube} alt='#' />
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-xl-2 col-md-6  col-12'>
											<div className='footer-address-wrapper'>
												<div className='footer-address-icon'>
													<i className='fi-rr-phone-call'></i>
												</div>
												<div className='footer-address-content'>
													<h5 className='f-address-cont-title'>Mobile phone</h5>
													<ul className='f-address-cont-list'>
														<li>
															<a
															// href={`tel:${
															//   generalInfo?.contact?.length &&
															//   generalInfo?.contact
															// }`}
															>
																{' '}
																{generalInfo?.contact}
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-xl-3 col-md-6 col-12'>
											<div className='footer-address-wrapper'>
												<div className='footer-address-icon'>
													<i className='fi-rr-envelope'></i>
												</div>
												<div className='footer-address-content'>
													<h5 className='f-address-cont-title'>Mail</h5>
													<ul className='f-address-cont-list'>
														<li>
															<a
																href={`mailto:${
																	generalInfo?.email?.length && generalInfo?.email
																}`}>
																{generalInfo?.email}
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className='col-lg-6 col-xl-4 col-md-6 col-12'>
											<div className='footer-address-wrapper'>
												<div className='footer-address-icon'>
													<i className='fi-rr-map-marker'></i>
												</div>
												<div className='footer-address-content'>
													<h5 className='f-address-cont-title'>Address</h5>
													<ul className='f-address-cont-list'>
														<li>{generalInfo?.address}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='footer-bottom'>
					<div className='container'>
						<div className='row g-0 align-items-center'>
							<div className='col-lg-12 col-xl-6 col-12'>
								<div className='footer-left-content'>
									<ul className='footer-left-content-list'>
										<li>
											<Link to='/about'>About us</Link>
										</li>
										<li>
											<Link to='/contactus'>Contact</Link>
										</li>
										{/* <li>
                      <Link to="/" target="_blank">
                        Privacy policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/" target="_blank">
                        Sitemap
                      </Link>
                    </li>
                    <li className="arrow-none">
                      <Link to="/">News/ Blogs</Link>
                    </li> */}
									</ul>
									<p className='f-copyright-main-text m-0'>
										© 2022, All Rights Reserved | Design & Developed by{' '}
										<a href='https://getup.com.bd' target='_blank' rel='noreferrer'>
											GetUp
										</a>{' '}
										| Powered by{' '}
										<a href='#' target='_blank'>
											SodaiNagar
										</a>
									</p>
								</div>
							</div>
							<div className='col-lg-12 col-xl-6 col-12'>
								<div className='footer-right-content'>
									<ul className='payment-currency-img'>
										<li>
											<a href='#' target='_blank' rel='noreferrer'>
												<img src={SSLImg} alt='#' />
											</a>
										</li>
									</ul>
									<ul className='footer-bottom-notice'>
										<li>
											<Link to='/privacy-policy'>Privacy policy</Link>
										</li>
										<li>
											<Link to='/shipping-policy'>Shipping Policy</Link>
										</li>
										<li>
											<Link to='/return&refund-policy'>Return Refund Policy</Link>
										</li>
										<li className='arrow-none'>
											<Link to='/terms&conditions'>Terms Conditions</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer

import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import OrderDetailsItem from "../Components/OrderDetailsItem";
import UserContext from "../Context/UserContext";
import Token from "../Config/Token";
import axios from "axios";

const ViewOrderDetails = ({ allOrders }) => {
  let navigate = useNavigate();
  const { user, token } = Token();
  let { orderId } = useParams();
  const [singleOrder, setSingleOrder] = useState();
  const { setOrderId } = useContext(UserContext);
  const [orderProgressInfo, setOrderProgressInfo] = useState([]);
  const getSingleOrder = () => {
    let orderInfo;
    allOrders?.map((each) => {
      if (each.id == orderId) {
        orderInfo = each;
      }
    });
    setSingleOrder(orderInfo);
  };
  useEffect(() => {
    getSingleOrder();
  }, [orderId, allOrders?.length]);
  // console.log(singleOrder?.order_no);
  useEffect(() => {
    token && orderProgress();
  }, [token, singleOrder]);
  const orderProgress = async () => {
    let formData = new FormData();
    formData.append("order_no", singleOrder?.order_no);
    await axios
      .post(`${process.env.React_APP_API_URL}/order/progress`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const progressData = res?.data?.date;
        if (success) {
          // toast.success(message);
          setOrderProgressInfo(progressData);
        } else {
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  return (
    <>
      <div className="user-dashboard-main">
        <div className="order-details">
          <div className="order-details-top">
            <h4 className="order-details-top-title">Order details</h4>
            <div className="order-details-top-btn">
              <div className="order-d-print-invoice">
                {/* <button onClick={()=>window.print()} className="order-d-print-btn">
                  <i className="fi-rr-print"></i>Print
                </button> */}
                {/* <button className="download-invoice-btn">
                  <i className="fi-rr-download"></i>Download invoice
                </button> */}
              </div>
            </div>
          </div>
          <div className="order-details-informations">
            <div className="row g-0">
              <div className="col-lg-4 col-12">
                <div className="order-d-formation-single">
                  <h6 className="order-d-formation-title">Order & Payment</h6>
                  <ul className="order-payment-list">
                    <li>
                      Order id<span>#{singleOrder?.order_no}</span>
                    </li>
                    <li>
                      Order date<span>{singleOrder?.created_at}</span>
                    </li>
                    <li>
                      Delivery date
                      <span>
                        {singleOrder?.delivery_date === null
                          ? singleOrder?.estimated_dd
                          : singleOrder?.delivery_date}
                      </span>
                    </li>
                  </ul>
                  <div className="payment-method-bottom">
                    <span className="payment-method-title">Payment method</span>
                    <p className="payment-method-text">
                      {singleOrder?.payment_method === null
                        ? "Pending"
                        : singleOrder?.payment_method_text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="order-d-formation-single order-d-address">
                  <h6 className="order-d-formation-title">Delivery address</h6>
                  <div className="order-delivery-address">
                    <div className="order-d-address-single">
                      <span className="order-d-address-title">
                        Billing address
                      </span>
                      <p className="order-d-address-text">
                        {singleOrder?.billing_address?.address},
                        {singleOrder?.billing_address?.city}-{" "}
                        {singleOrder?.billing_address?.post_code},<br></br>
                        {singleOrder?.billing_address?.country}
                      </p>
                    </div>
                    <div className="order-d-address-single">
                      <span className="order-d-address-title">
                        Shipping address
                      </span>
                      <p className="order-d-address-text">
                        {singleOrder?.shipping_info?.address},
                        {singleOrder?.shipping_info?.city}-{" "}
                        {singleOrder?.shipping_info?.post_code},<br></br>
                        {singleOrder?.shipping_info?.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="order-d-formation-single order-d-amount">
                  <h6 className="order-d-formation-title">Amount</h6>
                  <ul className="order-details-amount">
                    <li>
                      Sub total<span>{singleOrder?.sub_total} BDT</span>
                    </li>
                    <li>
                      Discount
                      <span>
                        (-{singleOrder?.discount / singleOrder?.total / 100}%){" "}
                        {singleOrder?.discount} BDT
                      </span>
                    </li>
                    <li>
                      Delivery fee<span>{singleOrder?.delivery_fee} BDT</span>
                    </li>
                    <li>
                      VAT/TAX<span>{singleOrder?.vat} BDT</span>
                    </li>
                    <li>
                      Total<span>{singleOrder?.total} BDT</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="order-status-main">
            <h5 className="order-status-title">Order Status</h5>
            <div className="order-status-inner">
              <div className="row view-order-detail-tracking">
                {orderProgressInfo?.map((singleOrderProgress, index) => (
                  <div className="col-xl-auto col-lg-3 col-md-3 col-3">
                    <div
                      className={
                        orderProgressInfo?.length !== index + 1
                          ? "order-status-wrapper"
                          : "order-status-wrapper order-deliverd-confirmd"
                      }
                    >
                      {singleOrderProgress?.order_status === 4 ? (
                        <div className="order-status-icon close-icon">
                          <i className="fi-rr-cross "></i>
                        </div>
                      ) : (
                        <div className="order-status-icon">
                          <i className="fi-rr-check "></i>
                        </div>
                      )}

                      <div className="order-status-cont">
                        {singleOrderProgress?.order_status === 0 ? (
                          <p className="order-status-cont-text">Processing</p>
                        ) : singleOrderProgress?.order_status === 1 ? (
                          <p className="order-status-cont-text">Confirmed</p>
                        ) : singleOrderProgress?.order_status === 2 ? (
                          <p className="order-status-cont-text">Intransit</p>
                        ) : singleOrderProgress?.order_status === 3 ? (
                          <p className="order-status-cont-text">Processing</p>
                        ) : singleOrderProgress?.order_status === 4 ? (
                          <p className="order-status-cont-text">Cancelled</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-xl-auto col-lg-3 col-md-3 col-3">
                  <div className="order-status-wrapper">
                    {singleOrder?.order_status >= 0 ? (
                      <div className="order-status-icon">
                        <i className="fi-rr-check "></i>
                      </div>
                    ) : (
                      <div className="order-status-icon close-icon">
                        <i className="fi-rr-cross "></i>
                      </div>
                    )}

                    <div className="order-status-cont">
                      <p className="order-status-cont-text">Processing</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-auto col-lg-3 col-md-3 col-3">
                  <div className="order-status-wrapper">
                    {singleOrder?.order_status >= 1 ? (
                      <div className="order-status-icon">
                        <i className="fi-rr-check "></i>
                      </div>
                    ) : (
                      <div className="order-status-icon close-icon">
                        <i className="fi-rr-cross "></i>
                      </div>
                    )}
                    <div className="order-status-cont">
                      <p className="order-status-cont-text">Confirmed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-auto col-lg-3 col-md-3 col-3">
                  <div className="order-status-wrapper">
                    {singleOrder?.order_status >= 2 ? (
                      <div className="order-status-icon">
                        <i className="fi-rr-check "></i>
                      </div>
                    ) : (
                      <div className="order-status-icon close-icon">
                        <i className="fi-rr-cross "></i>
                      </div>
                    )}
                    <div className="order-status-cont">
                      <p className="order-status-cont-text">Intransit</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-auto col-lg-3 col-md-3 col-3">
                  <div className="order-status-wrapper order-deliverd-confirmd">
                    {singleOrder?.order_status == 3 ? (
                      <div className="order-status-icon">
                        <i className="fi-rr-check "></i>
                      </div>
                    ) : (
                      <div className="order-status-icon close-icon">
                        <i className="fi-rr-cross "></i>
                      </div>
                    )}
                    <div className="order-status-cont">
                      <p className="order-status-cont-text">Delivered</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="order-details-item-main">
            <OrderDetailsItem OrderDetailsItems={singleOrder?.ordered_items} />
            <div className="order-details-item-btn">
              <Button
                variant="link"
                onClick={() => {
                  navigate("/checkout/OrderTracking");
                  setOrderId(singleOrder?.order_no);
                  localStorage.setItem(
                    "order_id",
                    JSON.stringify(singleOrder?.order_no)
                  );
                }}
                className="theme-btn"
              >
                Track your order
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrderDetails;

import axios from "axios";
import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeliveryBAKE from "../Assets/img/Delivery_BAKE.json";
import ClockIcon from "../Assets/img/order-tracking-icon/clock-icon.svg";
import ContactBook from "../Assets/img/order-tracking-icon/contact-book-icon.svg";
import LocationIcon from "../Assets/img/order-tracking-icon/location-icon.svg";
import Token from "../Config/Token";
import UserContext from "../Context/UserContext";
const OrderTracking = () => {
  const { user, token } = Token();
  const { orderId, setOrderId } = useContext(UserContext);
  const [orderProgressInfo, setOrderProgressInfo] = useState([]);
  useEffect(() => {
    let localStorageOrderId = localStorage.getItem("order_id") || 0;
    localStorageOrderId = JSON.parse(localStorageOrderId);
    setOrderId(orderId !== 0 ? orderId : localStorageOrderId);
  }, []);
  useEffect(() => {
    token && orderProgress();
  }, [token]);
  const orderProgress = async () => {
    let formData = new FormData();
    formData.append("order_no", orderId);
    await axios
      .post(`${process.env.React_APP_API_URL}/order/progress`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const progressData = res?.data?.date;
        if (success) {
          // toast.success(message);
          setOrderProgressInfo(progressData);
        } else {
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li>
              <Link to="/">Order</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/checkout/OrderTracking">Order tracking</Link>
            </li>
          </ul>
        </div>
        <div className="order-tracking-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-12">
                <div className="order-tracking-box">
                  <div className="order-tracking-box-top">
                    <h4 className="order-t-box-top-title">Order Tracking</h4>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="order-tracking-widget">
                          <div className="order-tracking-widget-icon">
                            <img src={ClockIcon} alt="#" />
                            <p className="order-t-widget-title">
                              Estimate time
                            </p>
                          </div>
                          <div className="order-t-widget-content">
                            <h5 className="order-t-widget-cont-title">
                              {formatDate(orderProgressInfo[0]?.estimated_dd)}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="order-tracking-widget">
                          <div className="order-tracking-widget-icon">
                            <img src={LocationIcon} alt="#" />
                            <p className="order-t-widget-title">
                              Delivery Location
                            </p>
                          </div>
                          <div className="order-t-widget-content">
                            <p>{orderProgressInfo[0]?.shipping_address}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="order-tracking-widget">
                          <div className="order-tracking-widget-icon">
                            <img src={ContactBook} alt="#" />
                            <p className="order-t-widget-title">
                              Order number/id
                            </p>
                          </div>
                          <div className="order-t-widget-content">
                            <h5 className="order-t-widget-cont-title">
                              #{orderId}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-tracking-maps">
                      <div className="gmap_canvas">
                        <Lottie animationData={DeliveryBAKE} loop={true} />
                      </div>
                    </div>
                    <div className="order-confirm-steps-bottom">
                      {orderProgressInfo?.map((singleOrderProgress, index) => (
                        <div
                          className={
                            orderProgressInfo?.length !== index + 1
                              ? "order-status-wrapper-two"
                              : "order-status-wrapper-two order-deliverd-confirmd"
                          }
                        >
                          {singleOrderProgress?.order_status === 4 ? (
                            <div className="confirm-steps-icon close-icon">
                              <i className="fi-rr-cross"></i>
                            </div>
                          ) : (
                            <div className="confirm-steps-icon">
                              <i className="fi-rr-check"></i>
                            </div>
                          )}

                          <div className="confirm-steps-content">
                            {singleOrderProgress?.order_status === 0 ? (
                              <>
                                <h6 className="confirm-steps-cont-title">
                                  Order Processing
                                </h6>
                                <p className="confirm-steps-cont-text">
                                  We have received your order
                                </p>
                              </>
                            ) : singleOrderProgress?.order_status === 1 ? (
                              <>
                                <h6 className="confirm-steps-cont-title">
                                  Order Confirmed
                                </h6>
                                <p className="confirm-steps-cont-text">
                                  Your order has been confirmed
                                </p>
                              </>
                            ) : singleOrderProgress?.order_status === 2 ? (
                              <>
                                <h6 className="confirm-steps-cont-title">
                                  Order Intransit
                                </h6>
                                <p className="confirm-steps-cont-text">
                                  We are preparing your order
                                </p>
                              </>
                            ) : singleOrderProgress?.order_status === 3 ? (
                              <>
                                <h6 className="confirm-steps-cont-title">
                                  Delivered
                                </h6>
                                <p className="confirm-steps-cont-text">
                                  Your order is delivered
                                </p>
                              </>
                            ) : singleOrderProgress?.order_status === 4 ? (
                              <>
                                <h6 className="confirm-steps-cont-title">
                                  Order Cancelled
                                </h6>
                                <p className="confirm-steps-cont-text">
                                  Your order has been Cancelled
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                      {/* <div className="order-status-wrapper-two">
                        {orderProgressInfo?.length >= 2 ? (
                          <div className="confirm-steps-icon">
                            <i className="fi-rr-check"></i>
                          </div>
                        ) : (
                          <div className="confirm-steps-icon close-icon">
                            <i className="fi-rr-cross"></i>
                          </div>
                        )}
                        <div className="confirm-steps-content">
                          <h6 className="confirm-steps-cont-title">
                            Order Confirmed
                          </h6>
                          <p className="confirm-steps-cont-text">
                            Your order has been confirmed
                          </p>
                        </div>
                      </div>
                      <div className="order-status-wrapper-two">
                        {orderProgressInfo?.length >= 3 ? (
                          <div className="confirm-steps-icon">
                            <i className="fi-rr-check"></i>
                          </div>
                        ) : (
                          <div className="confirm-steps-icon close-icon">
                            <i className="fi-rr-cross"></i>
                          </div>
                        )}
                        <div className="confirm-steps-content">
                          <h6 className="confirm-steps-cont-title">
                            Order Intransit
                          </h6>
                          <p className="confirm-steps-cont-text">
                            We are preparing your order
                          </p>
                        </div>
                      </div>

                      <div className="order-status-wrapper-two order-deliverd-confirmd">
                        {orderProgressInfo?.length >= 4 ? (
                          <div className="confirm-steps-icon">
                            <i className="fi-rr-check"></i>
                          </div>
                        ) : (
                          <div className="confirm-steps-icon close-icon">
                            <i className="fi-rr-cross"></i>
                          </div>
                        )}
                        <div className="confirm-steps-content">
                          <h6 className="confirm-steps-cont-title">
                            Delivered
                          </h6>
                          <p className="confirm-steps-cont-text">
                            Your order is delivered
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;

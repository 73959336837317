import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BFlag from "../Assets/img/login-with-icon/bangladeshi-flag.svg";
import Icon1 from "../Assets/img/login-with-icon/icon-1.svg";
// import Icon2 from "../Assets/img/login-with-icon/icon-2.svg";
// import Icon3 from "../Assets/img/login-with-icon/icon-3.svg";
import UserContext from "../Context/UserContext";

const SignUp = () => {
  let navigate = useNavigate();
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  const applyPhone = (e) => {
    if (e.target.value.length >= 11) {
      setVerifyBtn(true);
    } else {
      setVerifyBtn(false);
    }

    setPhoneNumber(e.target.value);
  };
  useEffect(() => {
    const phoneWithCode = "+88" + phoneNumber;
    const randomCode = 100000 + Math.floor(Math.random() * 900000);
    setSingUpInfo({
      ...signUpInfo,
      type: "phone",
      phone: phoneWithCode,
      code: randomCode,
    });
  }, [phoneNumber]);

  const signupWithNumber = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("phone", signUpInfo?.phone);
    formData.append("code", signUpInfo?.code);

    // const instance = axios.create({
    //   xsrfCookieName: "csrftoken",
    //   xsrfHeaderName: "X-CSRFToken",
    // });
    await axios
      .post(
        `${process.env.React_APP_API_URL}/send/verification/sms`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success === true) {
          toast.success(message);
          navigate("/phoneverification");
        }else{
          toast.error(message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
        </div>
        <div className="login-page-area">
          <div className="container-fluid">
            <div className="row g-0">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="login-card signup-card">
                  <div className="login-card-top">
                    <h4 className="login-card-top-title">Sign up</h4>
                    <p className="login-card-top-text">
                      Please sign up to access your account
                    </p>
                  </div>
                  <Form onSubmit={signupWithNumber}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="form-label-text">
                        Sign up with phone
                      </Form.Label>
                      <div className="form-input-main">
                        <div className="form-country-number">
                          <img src={BFlag} alt="#" />
                          <p className="form-country-number-text">+88</p>
                        </div>
                        <Form.Control
                          type="text"
                          onChange={applyPhone}
                          value={phoneNumber}
                        />
                        <Button
                          className="number-theme-btn"
                          disabled={verifyBtn ? false : true}
                          type="submit"
                        >
                          Verify
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                  <div className="login-card-options">
                    <h5 className="login-card-options-title">
                      Or Sign up with
                    </h5>
                    <Link
                      to="/signupwithemail"
                      className="login-card-options-wrapper"
                    >
                      <div className="l-card-options-wrapper-icon">
                        <img src={Icon1} alt="#" />
                      </div>
                      <div className="l-card-options-content">
                        <p className="l-card-options-content-text">
                          SignUp with email
                        </p>
                      </div>
                    </Link>
                    {/* <a
                      href="https://www.google.com/"
                      target="_blank"
                      className="login-card-options-wrapper"
                      rel="noreferrer"
                    >
                      <div className="l-card-options-wrapper-icon">
                        <img src={Icon2} alt="#" />
                      </div>
                      <div className="l-card-options-content">
                        <p className="l-card-options-content-text">
                          SignUp with google
                        </p>
                      </div>
                    </a>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="login-card-options-wrapper"
                      rel="noreferrer"
                    >
                      <div className="l-card-options-wrapper-icon">
                        <img src={Icon3} alt="#" />
                      </div>
                      <div className="l-card-options-content">
                        <p className="l-card-options-content-text">
                          SignUp with facebook
                        </p>
                      </div>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import axios from "axios";
import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailIcon from "../Assets/img/login-with-icon/email-icon.svg";
import UserContext from "../Context/UserContext";

const EmailVerification = () => {
  let navigate = useNavigate();
  const [OTPBtn, setOTPBtn] = useState(false);
  const [OTPNumber, setOTpNumber] = useState("");
  const { signUpInfo, setSingUpInfo } = useContext(UserContext);
  const applyPhone = (e) => {
    if (e.target.value.length >= 6) {
      setOTPBtn(true);
    } else {
      setOTPBtn(false);
    }
    setOTpNumber(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(OTPNumber) === signUpInfo.code) {
      navigate("/updateinformation");
    } else {
      toast.error("OTP DOES NOT MATCH");
    }
  };
  const sendAgainOTP = async (data) => {
    // console.log(emailInfo);
    const againOTP = 100000 + Math.floor(Math.random() * 900000);
    let formData = new FormData();
    formData.append("email", signUpInfo.email);
    formData.append("code", againOTP);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/send/verification/email`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        // console.log(message);
        if (success) {
          toast.success(message);
          setSingUpInfo({...signUpInfo,code: againOTP})
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        // toast.error(errorMessage);
      });

    // navigate("/emailverification");
  };
  return (
    <div className="container">
      <div className="pages">
        <div className="p-category-top-menu mt-12">
          <ul className="p-category-top-menu-list m-0">
            <li>
              <Link to="/">Home</Link>
              <i className="fi-rr-angle-small-right"></i>
            </li>
            <li className="active">
              <Link to="/signup">Sign up</Link>
            </li>
          </ul>
        </div>
        <div className="reset-password-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="reset-password-card">
                  <div className="phone-verification-top">
                    <img src={EmailIcon} alt="#" />
                    <p className="phone-v-top-text">
                      A 6-digit verification code was just sent to "
                      {signUpInfo.email}". Enter it to verify your email.{" "}
                    </p>
                    <span className="phone-v-top-didnot-received">
                      Didn’t receive an Email?{" "}
                      <Button variant="link" onClick={sendAgainOTP}>
                        Send again
                      </Button>
                    </span>
                  </div>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      controlId="formBasicEmail"
                      className="phone-verification-form"
                    >
                      <Form.Label className="form-label-text">
                        Enter code
                      </Form.Label>
                      <div className="form-input-main">
                        <Form.Control
                          type="text"
                          placeholder="e.g. 123456"
                          onChange={applyPhone}
                          required
                        />
                        <Button
                          className="theme-btn"
                          disabled={OTPBtn ? false : true}
                          type="submit"
                        >
                          Verify
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;

import axios from "axios";
import { useEffect, useState } from "react";
import Token from "../Config/Token";

const useCoupon = ({ cart }) => {
  const { user, token } = Token();
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [couponText, setCouponText] = useState("");
  // const [couponBtn, setCouponBtn] = useState(false);
  const [couponMess, setCouponMess] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [couponMessText, setCouponMessText] = useState("");
  const [discount, setDiscount] = useState(0);
  const [incrementHandle, setIncrementHandle] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [productQTYs, setProductQTYs] = useState([]);
  const [productUnitIds, setProductUnitIds] = useState([]);
  const [productUnitPrice, setProductUnitPrice] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const applyCoupon = (e) => {
    // if (e.target.value.length >= 5) {
    //   setCouponBtn(true);
    // } else {
    //   setCouponBtn(false);
    // }
    setCouponText(e.target.value);
  };

  useEffect(() => {
    let totalPrice = 0;
    let Ids = [];
    let QTYs = [];
    let UnitIds = [];
    let UnitPrice = [];
    for (const cartItem of cart) {
      Ids.push(parseInt(cartItem?.id));
      QTYs.push(parseInt(cartItem?.quantity));
      UnitIds.push(parseInt(cartItem?.unit_id));
      UnitPrice.push(
        parseInt(
          cartItem?.discount_price > 0
            ? cartItem?.discount_price
            : cartItem?.price
        )
      );
      const totalItemPrice =
        (cartItem?.discount_price > 0
          ? cartItem?.discount_price
          : cartItem?.price) * cartItem?.quantity;
      totalPrice = totalPrice + totalItemPrice;
    }
    setSubTotal(totalPrice);
    setTotalAmount(totalPrice);
    setProductIds(Ids);
    setProductQTYs(QTYs);
    setProductUnitIds(UnitIds);
    setProductUnitPrice(UnitPrice);
  }, [cart, incrementHandle]);

  const handleDiscount = async () => {
    let formData = new FormData();
    formData.append("coupon_code", couponText);
    await axios
      .post(`${process.env.React_APP_API_URL}/apply/coupon`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;
        const couponInfo = res?.data?.data;
        console.log(couponInfo);
        if (success === true) {
          // toast.success(message);
          if (couponInfo?.type == 1) {
            const total = subTotal - parseInt(couponInfo?.value);
            const discountPercent = Math.round(
              (parseInt(couponInfo?.value) / subTotal) * 100
            );

            setTotalAmount(total);
            setPercentage(discountPercent);
            setDiscount(parseInt(couponInfo?.value));
            const text = `(${discountPercent}%) coupon applied.`;
            setCouponMessText(text);
            setCouponMess(true);
            setShowDiscount(true);
          } else if (couponInfo?.type == 2) {
            const total =
              subTotal - (subTotal * parseInt(couponInfo?.value)) / 100;
            // const discountPercent = Math.round(
            //   (parseInt(couponInfo?.value) / subTotal) * 100
            // );
            const discountPercent = parseInt(couponInfo?.value);
            setTotalAmount(total);
            setPercentage(discountPercent);
            setDiscount(parseInt(couponInfo?.value));
            const text = `(${discountPercent}%) coupon applied.`;
            setCouponMessText(text);
            setCouponMess(true);
            setShowDiscount(true);
          }

          // setCouponBtn(false);
        } else {
          setCouponMess(true);
          setCouponMessText(res?.data?.message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
    // if (discount === 0) {
    //   if (couponText === "NEWYR123") {
    //     const discountAmount = (subTotal * 20) / 100;
    //     const total = subTotal - discountAmount;

    //     setTotalAmount(total);
    //     setDiscount(discountAmount);
    //     setCouponBtn(false);
    //     const text = "(-20% Off) coupon applied.";
    //     setCouponMessText(text);
    //     setCouponMess(true);
    //     setShowDiscount(true);
    //   } else if (couponText !== "NEWYR123") {
    //     const text = "coupon not allowed.";
    //     setCouponMessText(text);
    //     setCouponMess(true);
    //     setShowDiscount(false);
    //   }
    // } else {
    //   setCouponBtn(false);
    //   setCouponMess(false);
    // }
  };

  //console.log(orderInfo)
  return [
    subTotal,
    totalAmount,
    couponText,
    // couponBtn,
    couponMess,
    discount,
    showDiscount,
    couponMessText,
    applyCoupon,
    handleDiscount,
    incrementHandle,
    setIncrementHandle,
    productIds,
    productQTYs,
    productUnitIds,
    productUnitPrice,
    percentage,
  ];
};

export default useCoupon;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const OrderHistoryTableBody = ({ orderHistory, size, sidebar }) => {
  const [textLength, setTextLength] = useState(30);
  const [toggleTextLength, setToggleTextLength] = useState(40);


  // console.log(orderHistory);
  useEffect(() => {
    if (size[0] <= 767) {
      setTextLength(8);
      setToggleTextLength(11);
    } else if (768 <= size[0] && size[0] <= 991) {
      setTextLength(15);
      setToggleTextLength(20);
    } else if (992 <= size[0] && size[0] <= 1199) {
      setTextLength(20);
      setToggleTextLength(25);
    } else if (1200 <= size[0] && size[0] <= 1560) {
      setTextLength(30);
      setToggleTextLength(40);
    } else if (1560 <= size[0]) {
      setTextLength(60);
      setToggleTextLength(80);
    }
  }, [size[0]]);
  let orderProducts = [];
  orderHistory?.ordered_items?.map((word) => {
    orderProducts?.push(word.product_name + "-" + word.qty);
  });

  let orderProductsText = orderProducts?.join(", ");
  let button;
  if (orderHistory?.order_status_text === "Paid") {
    button = (
      <span className="paid-status">{orderHistory?.order_status_text}</span>
    );
  } else if (orderHistory?.order_status_text === "Pending") {
    button = (
      <span className="pending-status">{orderHistory?.order_status_text}</span>
    );
  } else {
    button = (
      <span className="cancel-status">{orderHistory?.order_status_text}</span>
    );
  }
  return (
    <>
      <tbody key={orderHistory?.id}>
        <tr>
          <td>{orderHistory?.order_date}</td>
          <td>
            {orderProductsText?.length >
            (sidebar ? textLength : toggleTextLength)
              ? orderProductsText?.substring(
                  0,
                  sidebar ? textLength : toggleTextLength
                ) + "..."
              : orderProductsText}
          </td>
          <td>{orderHistory?.total}</td>
          <td>{button}</td>
          <td>
            <Link
              to={`/user/purchasehistory/${orderHistory?.id}`}
              className="table-view-btn"
            >
              <i className="fi-rr-eye"></i>View
            </Link>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default OrderHistoryTableBody;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImg from "../Assets/img/profile-img.svg";
import Token from "../Config/Token";
const SupportTicket = ({ personalInfo, size }) => {
  const { user, token, clearUser } = Token();
  const [allTickets, setAllTickets] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [messageText, setMessageText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [textLength, setTextLength] = useState(30);
  const [toggleTextLength, setToggleTextLength] = useState(40);
  //console.log(size[0]);
  useEffect(() => {
    if (size[0] <= 767) {
      setTextLength(8);
    } else if (768 <= size[0] && size[0] <= 991) {
      setTextLength(10);
    } else if (992 <= size[0] && size[0] <= 1199) {
      setTextLength(10);
    } else if (1200 <= size[0] && size[0] <= 1560) {
      setTextLength(20);
    } else if (1560 <= size[0]) {
      setTextLength(50);
    }
  }, [size[0]]);
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const getAllTickets = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/all/support/tickets`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setAllTickets(res?.data?.data?.reverse()))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  useEffect(() => {
    token && getAllTickets();
  }, [token]);

  const sendMessage = async (e, supportTicketId) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("support_ticket_id", supportTicketId);
    formData.append("message", messageText);
    formData.append("attachment", selectedImage);
    await axios
      .post(
        `${process.env.React_APP_API_URL}/send/support/ticket/message`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const success = res?.data?.success;
        const message = res?.data?.message;

        if (success) {
          toast.success(message);
          getAllTickets();
          selectedImage();
          messageText("");
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };

  // console.log(showModal, selectedImage, allTickets);
  return (
    <>
      <div className="user-dashboard-main">
        <h4 className="dashboard-main-title">Support ticket</h4>
        <span className="dashboard-main-total">Total 20 items</span>
        <div className="support-ticket-main">
          <div className="support-ticket-top">
            <div className="support-ticket-top-left">
              <div className="support-searchbar">
                <form action="#">
                  <input type="text" placeholder="Search ticket" required />
                  <button className="search-btn" type="submit">
                    <i className="fi-rr-search"></i>
                  </button>
                </form>
              </div>
              <div className="product-category-select">
                <label>Sort by:</label>
                <select
                  aria-label="Default select example"
                  className="form-select"
                >
                  <option>All items</option>
                  <option value="1">Descending</option>
                  <option value="2">New products</option>
                  <option value="3">Price high to low</option>
                  <option value="4">Price low to high</option>
                </select>
              </div>
            </div>
            <Link to="/user/createticket" className="theme-btn">
              <i className="fi-rr-plus"></i>Create ticket
            </Link>
          </div>
          <div className="support-ticket-accordian">
            <div className="s-ticket-accordian-top">
              <ul className="s-ticket-accordian-list-title">
                <li>Ticket id</li>
                <li>Subject</li>
                <li>Status</li>
                <li>Last updates</li>
                <li>Support</li>
              </ul>
            </div>

            <div className="s-ticket-accordian-form">
              <Accordion defaultActiveKey="0" flush>
                {allTickets?.map((singleTickets, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <div className="s-ticket-accordian-head">
                        <ul className="s-ticket-accordian-head-list">
                          <li>#{singleTickets?.ticket_no}</li>
                          {/* <li>{singleTickets?.subject}</li> */}
                          <li>
                            {singleTickets?.subject > textLength
                              ? singleTickets?.subject?.substring(
                                  0,
                                  textLength
                                ) + "..."
                              : singleTickets?.subject}
                          </li>
                          <li>
                            <span
                              className={
                                singleTickets?.status_text === "Pending"
                                  ? "on-hold-box"
                                  : "progress-box"
                              }
                            >
                              {singleTickets?.status_text}
                            </span>
                          </li>
                          <li>{singleTickets?.created_at_formatted}</li>
                          <li>
                            {singleTickets?.support_taken_by_name > 10
                              ? singleTickets?.support_taken_by_name?.substring(
                                  0,
                                  10
                                ) + "..."
                              : singleTickets?.support_taken_by_name}
                          </li>
                        </ul>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="s-ticket-accordian-body">
                        <div className="accordian-comments-user">
                          {singleTickets?.support_taken_by_id == 1 ? (
                            <div className="a-comments-user-single">
                              <div className="a-comments-user-img">
                                <img
                                  src={
                                    singleTickets?.support_taken_by_id == 1
                                      ? ProfileImg
                                      : personalInfo?.image === null
                                      ? ProfileImg
                                      : process.env.React_APP_IMAGES_URL +
                                        personalInfo?.image
                                  }
                                  alt="#"
                                />
                              </div>
                              <div className="a-comments-user-content">
                                <div className="a-c-user-content-top">
                                  <h6>
                                    {singleTickets?.support_taken_by_name}(
                                    {singleTickets?.support_taken_by_id == 1
                                      ? "Admin"
                                      : "Customer"}
                                    )
                                  </h6>
                                  <span>
                                    {singleTickets?.created_at_formatted}
                                  </span>
                                </div>
                                <p className="text-border">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: singleTickets?.message,
                                    }}
                                  ></div>
                                </p>
                                <img
                                  src={
                                    process.env.React_APP_IMAGES_URL +
                                    singleTickets?.attachment
                                  }
                                  onClick={() => setShowModal(true)}
                                  className="support-ticket-img"
                                  key={index}
                                  style={{ margin: "2px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="a-comments-user-single">
                              <div className="a-comments-user-img-customer">
                                <img
                                  src={
                                    singleTickets?.support_taken_by_id == 1
                                      ? ProfileImg
                                      : personalInfo?.image === null
                                      ? ProfileImg
                                      : process.env.React_APP_IMAGES_URL +
                                        personalInfo?.image
                                  }
                                  alt="#"
                                />
                              </div>
                              <div className="a-comments-user-content-customer">
                                <div className="a-c-user-content-top">
                                  <span>
                                    {singleTickets?.created_at_formatted}
                                  </span>
                                  <h6>
                                    {singleTickets?.support_taken_by_name}(
                                    {singleTickets?.support_taken_by_id == 1
                                      ? "Admin"
                                      : "Customer"}
                                    )
                                  </h6>
                                </div>
                                <img
                                  src={
                                    process.env.React_APP_IMAGES_URL +
                                    singleTickets?.attachment
                                  }
                                  onClick={() => setShowModal(true)}
                                  className="support-ticket-img"
                                  key={index}
                                  style={{ margin: "2px" }}
                                  alt=""
                                />

                                <p className="text-border">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: singleTickets?.message,
                                    }}
                                  ></div>
                                </p>
                              </div>
                            </div>
                          )}

                          {singleTickets?.chats?.map((singleChat) =>
                            singleChat?.sender_type == 1 ? (
                              <div
                                className="a-comments-user-single"
                                key={singleChat?.id}
                              >
                                <div className="a-comments-user-img">
                                  <img
                                    src={
                                      singleTickets?.support_taken_by_id == 1
                                        ? ProfileImg
                                        : personalInfo?.image === null
                                        ? ProfileImg
                                        : process.env.React_APP_IMAGES_URL +
                                          personalInfo?.image
                                    }
                                    alt="#"
                                  />
                                </div>
                                <div className="a-comments-user-content">
                                  <div className="a-c-user-content-top">
                                    <h6>
                                      {singleChat?.sender_name}(
                                      {singleChat?.sender_type_text}admin)
                                    </h6>
                                    <span>
                                      {singleChat?.created_at_formatted}
                                    </span>
                                  </div>
                                  <img
                                    src={
                                      process.env.React_APP_IMAGES_URL +
                                      singleChat?.attachment
                                    }
                                    onClick={() => setShowModal(true)}
                                    className="support-ticket-img"
                                    key={index}
                                    style={{ margin: "2px" }}
                                    alt=""
                                  />
                                  <p className="text-border">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: singleChat?.message,
                                      }}
                                    ></div>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="a-comments-user-single"
                                key={singleChat?.id}
                              >
                                <div className="a-comments-user-content-customer">
                                  <div className="a-c-user-content-top">
                                    <span>
                                      {singleChat?.created_at_formatted}
                                    </span>
                                    <h6>
                                      {singleChat?.sender_name}(
                                      {singleChat?.sender_type_text})
                                    </h6>
                                  </div>
                                  <img
                                    src={
                                      process.env.React_APP_IMAGES_URL +
                                      singleChat?.attachment
                                    }
                                    onClick={() => setShowModal(true)}
                                    className="support-ticket-img"
                                    key={index}
                                    style={{ margin: "2px" }}
                                    alt=""
                                  />
                                  <p className="text-border">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: singleChat?.message,
                                      }}
                                    ></div>
                                  </p>
                                </div>
                                <div className="a-comments-user-img-customer">
                                  <img
                                    src={
                                      singleTickets?.support_taken_by_id == 1
                                        ? ProfileImg
                                        : personalInfo?.image === null
                                        ? ProfileImg
                                        : process.env.React_APP_IMAGES_URL +
                                          personalInfo?.image
                                    }
                                    alt="#"
                                  />
                                </div>
                              </div>
                            )
                          )}

                          {/* <div className="a-comments-user-single">
                            <div className="a-comments-user-img">
                              <img src={UserImg2} alt="#" />
                            </div>
                            <div className="a-comments-user-content">
                              <div className="a-c-user-content-top">
                                <h6>Imtiaj Ahmed</h6>
                                <span>4:09 PM, 23 Mar, 2022</span>
                              </div>
                              <p>
                                Quis eget risus in egestas. Quis etiam risus
                                eget cras risus. Suspendisse diam, volutpat, sem
                                tellus ridiculus ante. Eu augue sapien, vitae
                                nunc lacus
                              </p>
                            </div>
                          </div> */}
                        </div>
                        <div className="s-ticket-accordian-comments">
                          <div className="accordian-comments-profile">
                            <img
                              src={
                                personalInfo?.image === null
                                  ? ProfileImg
                                  : process.env.React_APP_IMAGES_URL +
                                    personalInfo?.image
                              }
                              alt="#"
                            />
                          </div>
                          <div className="accordian-comments-box">
                            <form
                              onSubmit={(e) =>
                                sendMessage(e, singleTickets?.id)
                              }
                            >
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  placeholder="You can ask anything you like"
                                  onChange={(e) =>
                                    setMessageText(e.target.value)
                                  }
                                  required
                                ></textarea>
                              </div>
                              <div className="accordian-comments-box-btm">
                                <div className="a-comments-attach-file">
                                  <div className="box">
                                    <input
                                      type="file"
                                      name="file-1[]"
                                      id="file-1"
                                      onChange={imageChange}
                                      className="inputfile inputfile-1"
                                    />
                                    <label for="file-1">
                                      <i className="fi-rr-clip"></i>
                                      <span>
                                        Attach file (.jpg, .png, .gif)
                                      </span>
                                      {selectedImage && (
                                        <span>
                                          {" " + " "} {selectedImage?.name}
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                </div>
                                <div className="accordian-comments-box-btns">
                                  <button
                                    className="btn-post button theme-btn"
                                    type="submit"
                                  >
                                    Post here
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportTicket;

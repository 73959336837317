import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardProductItems from "../Components/DashboardProductItems";
import OrderHistoryTableBody from "../Components/OrderHistoryTableBody";
import Product from "../Components/Product";
// import { addToDb } from "../utilities/fakedb";

const UserDashboard = ({
  cart,
  setCart,
  sidebar,
  size,
  wishlistProducts,
  allOrdersData,
}) => {
  const [totalOrderProducts, setTotalOrderProducts] = useState(0);
  const [orderHistorys, setOrderHistorys] = useState([]);
  const getSingleOrder = () => {
    let total = 0;
    allOrdersData?.map((each) => {
      const eachOrderItemlength = each?.ordered_items?.length;
      total = total + eachOrderItemlength;
    });
    setTotalOrderProducts(total);
    setOrderHistorys(allOrdersData);
  };
  useEffect(() => {
    getSingleOrder();
  }, [allOrdersData]);

  const [wishlistproductData, setWishlistProductData] = useState([]);
  const [WishlistProductsLength, setWishlistProductsLength] = useState(30);
  const [toggleWishlistProductsLength, setToggleWishlistProductsLength] =
    useState(40);

  useEffect(() => {
    setWishlistProductData(wishlistProducts);
    if (size[0] <= 767) {
      setWishlistProductsLength(1);
      setToggleWishlistProductsLength(1);
    } else if (768 <= size[0] && size[0] <= 991) {
      setWishlistProductsLength(2);
      setToggleWishlistProductsLength(3);
    } else if (992 <= size[0] && size[0] <= 1199) {
      setWishlistProductsLength(2);
      setToggleWishlistProductsLength(3);
    } else if (1200 <= size[0] && size[0] <= 1560) {
      setWishlistProductsLength(3);
      setToggleWishlistProductsLength(4);
    } else if (1560 <= size[0]) {
      setWishlistProductsLength(4);
      setToggleWishlistProductsLength(5);
    }
  }, [size[0]]);

  const handleAddToCart = (selectedProduct) => {
    let newCart = [];

    const exists = cart?.find((product) => product?.id === selectedProduct?.id);

    if (!exists) {
      selectedProduct.quantity = 1;
      newCart = [...cart, selectedProduct];
    } else {
      const rest = cart?.filter(
        (product) => product?.id !== selectedProduct?.id
      );
      exists.quantity = exists.quantity + 1;
      newCart = [...rest, exists];
    }

    setCart(newCart);
    localStorage.setItem("all_cart_data", JSON.stringify(newCart));
    // addToDb(selectedProduct.id);
  };

  return (
    <>
      <div className="user-dashboard-main">
        <h4 className="dashboard-main-title">Dashboard</h4>
        <DashboardProductItems
          totalOrderProducts={totalOrderProducts}
          cart={cart}
          wishlistProducts={wishlistProducts}
        />
        {/* <div className="live-order-tracking-maps">
          <div className="gmap_canvas">
            <iframe
              width="434"
              height="500"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=dhaka&t=&z=5&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div> */}
        <div className="order-history-table">
          <div className="order-history-top">
            <h6 className="order-history-title">order-history</h6>
            <Link
              to="/user/purchasehistory"
              state={{ title: "Puchase history" }}
              className="order-h-top-btn"
            >
              View all<i className="fi-rr-arrow-right"></i>
            </Link>
          </div>
          <Table className="order-history-table-main">
            <thead className="dash-table-head">
              <tr>
                <th>Date</th>
                <th>Products list</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {orderHistorys?.slice(0, 5).map((orderHistory) => (
              <OrderHistoryTableBody
                key={orderHistory.id}
                orderHistory={orderHistory}
                size={size}
                sidebar={sidebar}
              />
            ))}
          </Table>
        </div>

        <div>
          <div className="wishlist-items-top">
            <h6 className="order-history-title">Wishlist items</h6>
            <Link
              to="/user/wishlist"
              state={{ title: "Wishlist", path: "/user/wishlist" }}
              className="order-h-top-btn"
            >
              View all<i className="fi-rr-arrow-right"></i>
            </Link>
          </div>
          <div className="wishlist-products-inner">
            {wishlistproductData
              .slice(
                0,
                sidebar ? WishlistProductsLength : toggleWishlistProductsLength
              )
              .map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  handleAddToCart={handleAddToCart}
                  cart={cart}
                  setCart={setCart}
                ></Product>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;

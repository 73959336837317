import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Icon1 from "../Assets/img/payment-history-icon/icon-1.svg";
import Icon2 from "../Assets/img/payment-history-icon/icon-2.svg";
import PaymentHistroyCard from "../Components/PaymentHistroyCard";

const PaymentHistroy = ({ sidebar, size,allOrders }) => {
  const [startMap, setStartMap] = useState(0);
  const [endMap, setEndMap] = useState(10);
  const [handleLoad, setHandleLoad] = useState(false);
  const [handlePrevious, setHandlePrevious] = useState(false);
  let paidedOrders = [];
  let totalPaid = 0;
  for (let orderHistory of allOrders) {
    if (orderHistory?.payment_method != null) {
      paidedOrders?.push(orderHistory)
      totalPaid = totalPaid + parseInt(orderHistory?.total);
    }
  }
  useEffect(() => {
    if (paidedOrders?.length > endMap) {
      setHandleLoad(true);
    }
    if (startMap === 0) {
      setStartMap(0);
      setEndMap(10);
      setHandlePrevious(false);
    }
  }, [paidedOrders, startMap, endMap]);
  const handleLoadBtn = () => {
    if (
      startMap + 10 <= allOrders?.length &&
      paidedOrders?.length <= endMap + 10
    ) {
      setStartMap(startMap + 10);
      setEndMap(paidedOrders?.length);
      setHandleLoad(false);
      setHandlePrevious(true);
    } else if (paidedOrders?.length > endMap) {
      setStartMap(startMap + 10);
      setEndMap(endMap + 10);
      setHandleLoad(true);
      setHandlePrevious(true);
    }
  };
  const handlePreviousBtn = () => {
    if (paidedOrders?.length === endMap) {
      setStartMap(startMap - 10);
      setEndMap(endMap - (endMap - startMap));
    } else {
      setStartMap(startMap - 10);
      setEndMap(endMap - 10);
      setHandleLoad(true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="user-dashboard-main">
          <h4 className="dashboard-main-title">Payment history</h4>

          <div className="dashboard-payment-history">
            <div className="d-payment-history-options">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="d-payment-h-option-single">
                    <div className="d-payment-h-option-icon">
                      <img src={Icon1} alt="#" />
                    </div>
                    <p className="d-payment-option-title">Total order</p>
                    <h2 className="d-payment-option-total">
                      {allOrders?.length} Items
                    </h2>
                    <span className="d-payment-option-text">(all time)</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="d-payment-h-option-single">
                    <div className="d-payment-h-option-icon">
                      <img src={Icon2} alt="#" />
                    </div>
                    <p className="d-payment-option-title">Total paid</p>
                    <h2 className="d-payment-option-total">{totalPaid} BDT</h2>
                    <span className="d-payment-option-text">(all time)</span>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-12">
                  <div className="d-payment-h-option-single">
                    <div className="d-payment-h-option-icon">
                      <img src={Icon3} alt="#" />
                    </div>
                    <p className="d-payment-option-title">Account balance</p>
                    <h2 className="d-payment-option-total">500 BDT</h2>
                    <a href="#" className="d-payment-option-btn">
                      Add Balance
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="payment-method-options">
              <div className="payment-m-options-top">
                <h5 className="payment-m-options-top-title">Payments</h5>
                <div className="payment-m-options-select">
                  <div className="product-category-select">
                    <label>Select month:</label>
                    <select
                      aria-label="Default select example"
                      className="form-select"
                    >
                      <option>All time</option>
                      <option value="1">One Month Ago</option>
                      <option value="2">Two Month Ago</option>
                      <option value="3">Three Month Ago</option>
                      <option value="4">Four Month Ago</option>
                    </select>
                  </div>
                  <div className="product-category-select">
                    <label>Sort by:</label>
                    <select
                      aria-label="Default select example"
                      className="form-select"
                    >
                      <option>Paid items</option>
                      <option value="1">Paid items 2</option>
                      <option value="2">Paid items 3</option>
                      <option value="3">Paid items 4</option>
                      <option value="4">Paid items 5</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="payment-method-inner">
                {paidedOrders?.slice(startMap, endMap).map((orderHistory) => (
                  <PaymentHistroyCard
                    key={orderHistory.id}
                    orderHistory={orderHistory}
                    size={size}
                    sidebar={sidebar}
                  />
                ))}
              </div>
              <div className="payment-method-bottom-btn">
                {handlePrevious ? (
                  <Button
                    variant="link"
                    onClick={handlePreviousBtn}
                    className="Previous-more-btn"
                  >
                    Previous
                  </Button>
                ) : (
                  ""
                )}

                {handleLoad ? (
                  <Button
                    variant="link"
                    onClick={handleLoadBtn}
                    className="load-more-btn"
                  >
                    Load more
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentHistroy;

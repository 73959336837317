import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Token from "../Config/Token";
const initialAdressValue = {
  type: "",
  name: "",
  mobile: "",
  address: "",
  country: "Bangladesh",
  city: "",
  area: "",
  postcode: "",
};

const AddNewAddressModal = ({
  editID,
  setEditID,
  Address,
  adressId,
  getAddress,
  addressModalShow,
  setAddressModalShow,
}) => {
  let navigate = useNavigate();
  const { user, token } = Token();
  const [addressInfo, setAddressInfo] = useState(initialAdressValue);

  const handleClose = () => setAddressModalShow(false);
  const handleShow = () => setAddressModalShow(true);

  const [postCode, setPostCode] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [upazila, setUpazila] = useState([]);
  const [selectedUpazila, setSelectedUpazila] = useState([]);
  // console.log(country);
  useEffect(() => {
    const getdistrict = async () => {
      const rescountry = await fetch("../districts.json");
      const rescon = await rescountry.json();
      setDistricts(await rescon);
    };
    getdistrict();
  }, []);

  const handleDistrict = (event) => {
    const getDistrictName = event.target.value;
    const selectedDistrict = districts.filter(
      (product) => product.name === getDistrictName
    );

    setDistrictId(selectedDistrict[0]?.id);
  };

  useEffect(() => {
    const getstate = async () => {
      const resstate = await fetch("../upazilas.json");
      const resst = await resstate.json();

      setUpazila(
        await resst.filter(
          (product) => product.district_id == districtId.toString()
        )
      );
    };
    getstate();
  }, [districtId]);
  const handleUpazila = (event) => {
    const getUpazilaName = event.target.value;
    setSelectedUpazila(getUpazilaName);
  };

  useEffect(() => {
    const getPostCode = async () => {
      const resstate = await fetch("../postCodes.json");
      const resst = await resstate.json();

      setPostCode(
        await resst.filter(
          (product) =>
            product?.district_id == districtId?.toString() &&
            product?.upazila === selectedUpazila
        )
      );
    };
    getPostCode();
  }, [selectedUpazila]);
  const submit = async (e) => {
    e.preventDefault();
    if (!addressInfo?.name) {
      toast.error("name is required");
    } else if (!addressInfo?.mobile) {
      toast.error("Mobile is required");
    } else if (!addressInfo?.type) {
      toast.error("type is required");
    } else if (!addressInfo?.address) {
      toast.error("address is required");
    } else if (!addressInfo?.city) {
      toast.error("City is required");
    } else if (!addressInfo?.area) {
      toast.error("Area is required");
    } else {
      let route = "";
      editID ? (route = "update_address") : (route = "add_address");
      add_address(route);
    }
  };

  const add_address = async (route) => {
    // console.log(addressInfo)
    let formData = new FormData();
    // formData.append('user_id', user?.id);
    editID && formData.append("address_id", editID);
    formData.append("address_type", addressInfo?.type);
    formData.append("name", addressInfo?.name);
    formData.append("phone", addressInfo?.mobile);
    formData.append("address", addressInfo?.address);
    formData.append("country", addressInfo?.country);
    formData.append("city", addressInfo?.city);
    formData.append("state", addressInfo?.area);
    formData.append("post_code", addressInfo?.postcode);
    if (route === "add_address") {
      await axios
        .post(`${process.env.React_APP_API_URL}/add/new/address`, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const success = res?.data?.success;
          const message = res?.data?.message;
          if (success) {
            toast.success(message);
            handleClose();
            setAddressInfo(initialAdressValue);
            getAddress();
          }

          // if (adressId) {
          //   toast.success("address updated successfully!");
          //   window.location.reload();
          // } else {
          //   editID
          //     ? toast.success("address updated successfully!")
          //     : toast.success("new address added successfully!");
          // }
        })
        .catch((err) => console.log(err));
    } else if (route === "update_address") {
      await axios
        .post(`${process.env.React_APP_API_URL}/update/my/address`, formData, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const success = res?.data?.success;
          const message = res?.data?.message;
          if (success) {
            toast.success(message);
            setEditID(null)
            handleClose();
            setAddressInfo(initialAdressValue);
            getAddress();
          }

          // if (adressId) {
          //   toast.success("address updated successfully!");
          //   window.location.reload();
          // } else {
          //   editID
          //     ? toast.success("address updated successfully!")
          //     : toast.success("new address added successfully!");
          // }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (editID) {
      // console.log(editID)
      // console.log(Address)
      const edit = Address.filter((each) => {
        return each?.id === editID;
      });
      console.log(edit);
      if (!!edit?.length) {
        setAddressInfo({
          name: edit[0]?.name,
          mobile: edit[0]?.phone,
          type: edit[0]?.address_type,
          address: edit[0]?.address,
          country: edit[0]?.country,
          city: edit[0]?.city,
          area: edit[0]?.state,
          postcode: edit[0]?.post_code,
        });
      }
    } else {
      setAddressInfo(initialAdressValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editID]);
  return (
    <>
      <div className="add-new-address-modal-main">
        <div className="add-new-address-btn">
          <Button className="theme-btn" onClick={handleShow}>
            <i className="fi-rr-plus"></i>Add new address
          </Button>
        </div>
        <Modal
          show={addressModalShow}
          onHide={handleClose}
          className="add-new-address-modal-form"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-new-modal-main">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-12">
                    {" "}
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Address Type
                      </label>
                      <select
                        aria-label="Default select example"
                        className="form-select"
                        value={addressInfo?.type}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            type: e.target.value,
                          });
                        }}
                      >
                        <option>Address Type</option>
                        <option value="Billing Address">Billing Address</option>
                        <option value="Shipping Address">
                          Shipping Address
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Full name
                      </label>
                      <input
                        placeholder="Enter full name"
                        type="text"
                        className="form-control"
                        value={addressInfo?.name}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Address line
                      </label>
                      <input
                        placeholder="Your address"
                        type="text"
                        className="form-control"
                        value={addressInfo?.address}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            address: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Country
                      </label>

                      <select
                        name="func"
                        className="form-select"
                        value={addressInfo?.country}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            country: e.target.value,
                          });
                        }}
                      >
                        <option value="bangladesh">Bangladesh</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        District/ City
                      </label>
                      <select
                        aria-label="Default select example"
                        className="form-select"
                        value={addressInfo?.city}
                        onChange={(e) => {
                          handleDistrict(e);
                          setAddressInfo({
                            ...addressInfo,
                            city: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select City</option>
                        {districts.map((getcon, index) => (
                          <option key={index} value={getcon.name}>
                            {getcon.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Upazila
                      </label>
                      <select
                        aria-label="Default select example"
                        className="form-select"
                        value={addressInfo?.area}
                        onChange={(e) => {
                          handleUpazila(e);
                          setAddressInfo({
                            ...addressInfo,
                            area: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select State</option>
                        {upazila.map((getcon, index) => (
                          <option key={index} value={getcon.name}>
                            {getcon.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Postal code
                      </label>
                      <input
                        placeholder="ex: 12345"
                        type="text"
                        className="form-control"
                        value={addressInfo?.postcode}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            postcode: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-input-wrapper">
                      <label className="form-label-text form-label">
                        Mobile number
                      </label>
                      <input
                        placeholder="Your phone number"
                        type="text"
                        className="form-control"
                        value={addressInfo?.mobile}
                        onChange={(e) => {
                          setAddressInfo({
                            ...addressInfo,
                            mobile: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <button className="theme-btn" type="submit">
                  {editID ? "Update address" : "Save address"}
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddNewAddressModal;

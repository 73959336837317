// import React from "react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbsBg from "../Assets/img/privacy-policy.png";

const ShippingPolicy = () => {
  const [details, setDetails] = useState([]);

  const getDetails = async () => {
    axios
      .get(`${process.env.React_APP_API_URL}/get/shipping/policy`, {
        headers: {
          "Content-type": "application/json",
          Authorization: process.env.React_APP_DEFAULT_AUTHORIZATION,
        },
      })
      .then((res) => setDetails(res?.data?.data))
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        // toast.error(errorMessage);
      });
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <section
        className="sodainagar-breadcrumbs"
        style={{ backgroundImage: `url(${BreadcrumbsBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
              <div className="sodainagar-breadcrumbs-content">
                <h3 className="sodainagar-bread-cont-title">Privacy Policy</h3>
                <ul className="sodainagar-bread-menu">
                  <li>
                    <Link to="/">Home</Link>
                    <i className="fi fi-br-angle-right"></i>
                  </li>
                  <li className="active">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="privacy_policy_body container ">
        <div className="privacy_policy_text">
          <p>
            <div
              dangerouslySetInnerHTML={{ __html: details?.shipping_policy }}
            ></div>
          </p>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
